import { useCallback, useEffect, useState } from 'react';
import { useColor } from 'react-color-palette';
import _ from 'lodash';
import {
  useUpdatedColors,
  useUpdatedCustomizationInfo,
} from '../../../api/Brand';
import { useCustomTheme } from '../../../contexts/useCustomTheme';
import THEME from '../../../config/theme';
import { firestore } from '../../../services/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export const useEditMain = () => {
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  //use state para mostrar ou não o modal de cores
  const [show, setShow] = useState({
    showBackgroundColor: false,
    showTextApp: false,
    showTextTitleMain: false,
    showBackgroundButtonColor: false,
    showTextButtonColor: false,
    showIconLock: false,
    showIconColor: false,
    showTabBackgroundColor: false,
    showTabIcondColor: false,
    showTabHighlightColor: false,
    showSplashColor: false,
    showStatusBarColor: false,
  });
  const handleShowPicker = useCallback((field, value) => {
    setShow((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  });

  const {
    customColor: colorInfo,
    customInfo: customizationInfo,
    isLoadingCustomInfo: isLoadingCustomizationInfo,
    isLoadingColorInfo: isLoadingThemeInfo,
  } = useCustomTheme();

  const { mutateAsync: updatedColor } = useUpdatedColors();

  //instanciar as cores default
  const [backgroundColor, setBackgroundColor] = useColor(THEME.COLORS.BACKGROUND_MAIN);
  const [textTitleMain, setTextTitleMain] = useColor(THEME.COLORS.TITLE_MAIN);
  const [textApp, setTextApp] = useColor(THEME.COLORS.TEXT_MAIN);
  const [backgroundButtonColor, setBackgroundButtonColor] = useColor(THEME.COLORS.PRIMARY_900);
  const [textButtonColor, setTextButtonColor] = useColor(THEME.COLORS.TEXT_BUTTON);
  const [iconLockColor, setIconLockColor] = useColor(THEME.COLORS.ICON);
  const [iconColor, setIconColor] = useColor(THEME.COLORS.ICON_HEADER_MAIN);

  const [tabBackgroundColor, setTabBackgroundColor] = useColor(THEME.COLORS.BACKGROUND_MAIN);
  const [tabIcondColor, setTabIcondColor] = useColor(THEME.COLORS.PRIMARY_700);
  const [tabHighlightColor, setTabHighlightColor] = useColor(THEME.COLORS.TEXT_MAIN);
  const [splashColor, setSplashColor] = useColor(THEME.COLORS.BACKGROUND_MAIN);
  const [statusBarColor, setStatusBarColor] = useColor('#FFFFFF');

  const [switchCustomizeApp, setSwitchCustomizeApp] = useState(false);
  const [loadingColorsApp, setLoadingColorsApp] = useState(false);
  const [isSavedColorsApp, setIsSavedColorsApp] = useState(false);

  //get das cores que estao no banco, se não tiver ficará na default
  useEffect(() => {
    if (colorInfo && Object.keys(colorInfo).length > 1) {
      setBackgroundColor({
        ...backgroundColor,
        hex:
          colorInfo.background && colorInfo.background.app
            ? colorInfo.background.app
            : THEME.COLORS.BACKGROUND_MAIN,
      });
      setTextApp({
        ...textApp,
        hex:
          colorInfo.text && colorInfo.text.app
            ? colorInfo.text.app
            : THEME.COLORS.TEXT_MAIN,
      });
      setTextTitleMain({
        ...textTitleMain,
        hex:
          colorInfo.text && colorInfo.text.titleMain
            ? colorInfo.text.titleMain
            : THEME.COLORS.TITLE_MAIN,
      });
      setBackgroundButtonColor({
        ...backgroundButtonColor,
        hex:
          colorInfo.button && colorInfo.button.backgroundApp
            ? colorInfo.button.backgroundApp
            : THEME.COLORS.PRIMARY_900,
      });
      setTextButtonColor({
        ...textButtonColor,
        hex:
          colorInfo.button && colorInfo.button.textApp
            ? colorInfo.button.textApp
            : THEME.COLORS.TEXT_MAIN,
      });
      setIconLockColor({
        ...iconLockColor,
        hex:
          colorInfo.iconsMain && colorInfo.iconsMain.iconLock
            ? colorInfo.iconsMain.iconLock
            : THEME.COLORS.ICON,
      });
      setIconColor({
        ...iconColor,
        hex:
          colorInfo.iconsMain && colorInfo.iconsMain.icons
            ? colorInfo.iconsMain.icons
            : THEME.COLORS.ICON_HEADER_MAIN,
      });
      setTabBackgroundColor({
        ...tabBackgroundColor,
        hex:
          colorInfo.tabNavigator && colorInfo.tabNavigator.background
            ? colorInfo.tabNavigator.background
            : '',
      });
      setTabIcondColor({
        ...tabIcondColor,
        hex:
          colorInfo.tabNavigator && colorInfo.tabNavigator.icon
            ? colorInfo.tabNavigator.icon
            : '',
      });
      setTabHighlightColor({
        ...tabHighlightColor,
        hex:
          colorInfo.tabNavigator && colorInfo.tabNavigator.highlight
            ? colorInfo.tabNavigator.highlight
            : '',
      });
      setSplashColor({
        ...splashColor,
        hex:
          colorInfo.background && colorInfo.background.splash
            ? colorInfo.background.splash
            : '',
      });
      setStatusBarColor({
        ...statusBarColor,
        hex:
          colorInfo.background && colorInfo.background.statusBar
            ? colorInfo.background.statusBar
            : '',
      });
    }
  }, [colorInfo]);

  const debouncedUpdateColor = useCallback(
    _.debounce((newColors) => {
      updatedColor(newColors);
    }, 500),
    []
  );
  //post para atualizar as cores
  useEffect(() => {
    const newColors = {
      background: {
        app: backgroundColor.hex,
      },
      text: {
        app: textApp.hex,
        titleMain: textTitleMain.hex,
      },
      button: {
        backgroundApp: backgroundButtonColor.hex,
        textApp: textButtonColor.hex,
      },
      iconsMain: {
        iconLock: iconLockColor.hex,
        icons: iconColor.hex,
      },
    };
    debouncedUpdateColor(newColors);
  }, [
    backgroundColor,
    textApp,
    textTitleMain,
    backgroundButtonColor,
    textButtonColor,
    iconColor,
    iconLockColor,
    tabBackgroundColor,
    tabIcondColor,
    tabHighlightColor,
    splashColor,
    statusBarColor,
  ]);

  const updateAppColors = async () => {
    try {
      setLoadingColorsApp(true);
      const docRef = doc(firestore, 'customization', 'colors');
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const currentData = docSnapshot.data();
        const updatedData = {
          tabNavigator: {
            background: tabBackgroundColor.hex,
            icon: tabIcondColor.hex,
            highlight: tabHighlightColor.hex,
          },
          background: {
            ...currentData.background,
            splash: splashColor.hex,
            statusBar: statusBarColor.hex,
          },
        };

        await updateDoc(docRef, updatedData);

        setLoadingColorsApp(false);
        setIsSavedColorsApp(true);
      } else {
        console.log('Documento não encontrado!');
        setLoadingColorsApp(false);
      }
    } catch (error) {
      console.error('Erro ao atualizar as cores:', error);
      setLoadingColorsApp(false);
    }
  };

  //TITULO E SUBTITULO
  const { mutateAsync: updatedCustomInfo } = useUpdatedCustomizationInfo();

  const debounceText = useCallback(
    _.debounce((text) => {
      updatedCustomInfo(text);
    }, 500),
    []
  );
  //get no titulo e subtitulo
  useEffect(() => {
    if (customizationInfo) {
      setTitle(customizationInfo.title);
      setSubTitle(customizationInfo.subTitle);
    }
  }, [customizationInfo]);

  //post para atualizar titulo e subtitulo
  useEffect(() => {
    const infos = { title, subTitle };
    if (Object.values(infos).some((value) => value !== undefined)) {
      debounceText(infos);
    }
  }, [title, subTitle]);

  return {
    backgroundColor,
    setBackgroundColor,
    textTitleMain,
    setTextTitleMain,
    textApp,
    setTextApp,
    backgroundButtonColor,
    setBackgroundButtonColor,
    textButtonColor,
    setTextButtonColor,
    iconLockColor,
    setIconLockColor,
    iconColor,
    setIconColor,
    tabBackgroundColor,
    setTabBackgroundColor,
    tabIcondColor,
    setTabIcondColor,
    tabHighlightColor,
    setTabHighlightColor,
    splashColor,
    setSplashColor,
    statusBarColor,
    setStatusBarColor,
    show,
    handleShowPicker,
    title,
    setTitle,
    subTitle,
    setSubTitle,
    isLoadingThemeInfo,
    isLoadingCustomizationInfo,
    switchCustomizeApp,
    setSwitchCustomizeApp,
    updateAppColors,
    loadingColorsApp,
    isSavedColorsApp,
    setIsSavedColorsApp
  };
};
