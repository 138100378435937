import React, { useState } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import { FontAwesome, Ionicons, Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import THEME from '../../../config/theme';
import { getAuth } from 'firebase/auth';
import * as S from './styles';
import { AlertBox } from '../../../components/AlertBox';
import {
  StandardText,
  SmallText,
  FooterText,
} from '../../../config/theme/globalStyles';
import Button from '../../../components/Button';
import { useCustomTheme } from '../../../contexts/useCustomTheme';

const CardCommunity = ({
  posts,
  handleLike,
  handleDeletePost,
  user,
  setPosts,
  isRoute,
}) => {
  const auth = getAuth();
  const navigation = useNavigation();

  const isAdminOrIsUserCurrent = (id) => {
    if (user?.id === id || user?.isAdmin === true) {
      return true;
    }
    return false;
  };

  const [isAlert, setIsAlert] = useState(false);
  const [idItem, setIdItem] = useState('');

  const deletePost = (item) => {
    setIsAlert(true);
    setIdItem(item);
  };

  const { customColor, customFont } = useCustomTheme();

  return (
    <FlatList
      keyExtractor={(item, index) => index}
      data={posts}
      renderItem={({ item }) => (
        <S.Card
          style={{
            backgroundColor:
              customColor && customColor.background?.app
                ? customColor.background.app
                : THEME.COLORS.BACKGROUND_MAIN,
          }}
        >
          <S.ContentContainer>
            <S.ContentProfile>
              <S.BoxProfile>
                {item?.photo ? (
                  <S.ImageProfile source={{ uri: item.photo }} />
                ) : (
                  <Feather
                    name="user"
                    size={24}
                    color={
                      customColor && customColor.text && customColor.text.app
                        ? customColor.text.app
                        : THEME.COLORS.TEXT_MAIN
                    }
                  />
                )}
                <StandardText
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilySubtitle
                        ? customFont.fontFamilySubtitle
                        : THEME.FONTFAMILY.MEDIUM,
                    fontSize:
                      customFont && customFont.fontMD
                        ? customFont.fontMD
                        : THEME.FONTSIZE.MEDIUM,

                  }}
                  color={
                    customColor && customColor.text && customColor.text.app
                      ? customColor.text.app
                      : THEME.COLORS.TEXT_MAIN
                  }
                >
                  {item?.nameUser}
                </StandardText>
              </S.BoxProfile>

              {isAdminOrIsUserCurrent(item?.authorId) && (
                <TouchableOpacity onPress={() => deletePost(item.id)}>
                  <FontAwesome
                    name="trash-o"
                    size={22}
                    color={
                      customColor && customColor.text && customColor.text.app
                        ? customColor.text.app
                        : THEME.COLORS.TEXT_MAIN
                    }
                  />
                </TouchableOpacity>
              )}

              {isAlert && (
                <AlertBox
                  title={'Você deseja excluir esse post'}
                  visible={true}
                  leftButton={'Sim'}
                  rightButton={'Não'}
                  leftButtonFunction={() => {
                    handleDeletePost(idItem);

                    if (isRoute) {
                      navigation.navigate('Community');
                    } else {
                      setPosts((prevPosts) =>
                        prevPosts.filter((post) => post.id !== idItem)
                      );
                    }
                  }}
                  rightButtonFunction={() => setIsAlert(false)}
                  onClose={() => setIsAlert(false)}
                />
              )}
            </S.ContentProfile>
            <SmallText
              style={{
                fontFamily:
                  customFont && customFont.fontFamilyText
                    ? customFont.fontFamilyText
                    : THEME.FONTFAMILY.REGULAR,
              }}
              color={
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : THEME.COLORS.TEXT_MAIN
              }
              margin="8px 0 0 0"
            >
              {item.contentText}
            </SmallText>
          </S.ContentContainer>

          {item?.contentImage && (
            <S.ImagePost source={{ uri: item.contentImage }} />
          )}

          <S.ActionsContainer>
            <S.LeftActionsContainer>
              <TouchableOpacity onPress={() => handleLike(item, auth)}>
                <Ionicons
                  name={
                    item.dataLikes?.some(
                      (i) => i.userId === auth.currentUser.uid
                    )
                      ? 'heart'
                      : 'heart-outline'
                  }
                  size={24}
                  style={{
                    color: item.dataLikes?.some(
                      (i) => i.userId === auth.currentUser.uid
                    )
                      ? 'red'
                      : customColor && customColor.text && customColor.text.app
                        ? customColor.text.app
                        : THEME.COLORS.TEXT_MAIN,
                  }}
                />
              </TouchableOpacity>

              <FooterText
                color={
                  customColor && customColor.text && customColor.text.app
                    ? customColor.text.app
                    : THEME.COLORS.TEXT_MAIN
                }
                style={{
                  fontFamily:
                    customFont && customFont.fontFamilyText
                      ? customFont.fontFamilyText
                      : THEME.FONTFAMILY.LIGHT,
                }}
                margin="0 8px 0 4px"
              >
                {item.dataLikes?.length}
              </FooterText>

              <TouchableOpacity
                onPress={() =>
                  navigation.navigate('CommunityComments', { id: item.id })
                }
              >
                <FontAwesome
                  name="comment-o"
                  size={22}
                  color={
                    customColor && customColor.text && customColor.text.app
                      ? customColor.text.app
                      : THEME.COLORS.TEXT_MAIN
                  }
                />
              </TouchableOpacity>

              <FooterText
                color={
                  customColor && customColor.text && customColor.text.app
                    ? customColor.text.app
                    : THEME.COLORS.TEXT_MAIN
                }
                style={{
                  fontFamily:
                    customFont && customFont.fontFamilyText
                      ? customFont.fontFamilyText
                      : THEME.FONTFAMILY.LIGHT,
                }}
                margin="0 8px 0 4px"
              >
                {item.dataComments?.length}
              </FooterText>
            </S.LeftActionsContainer>

            {!isRoute && (
              <TouchableOpacity>
                <Button
                  title={'Visualizar'}
                  onPress={() =>
                    navigation.navigate('CommunityComments', { id: item.id })
                  }
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilyText
                        ? customFont.fontFamilyText
                        : THEME.FONTFAMILY.LIGHT,
                  }}
                  padding="0.5rem 1rem"

                ></Button>
              </TouchableOpacity>
            )}
          </S.ActionsContainer>
        </S.Card>
      )}
    />
  );
};

export default CardCommunity;
