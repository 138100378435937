import styled from 'styled-components/native';
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;

export const VerticalScroll = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    flex: 2,
    paddingHorizontal: "2%"
  },
})`  
`;

export const VerticalScrollDesktop = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    alignSelf: "center",
    width: "100%",
    height: ((windowWidth * 0.7) - 32) / 1.78,
    paddingHorizontal: "1rem",
  },
})`  
`;

export const Image = styled.ImageBackground`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const ImageBlocked = styled.View`
  background-color: "rgba(0,0,0,0.7)";
  width: 100%;
  height: 100%;
  justify-content: center;
`;