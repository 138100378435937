import { ColorPicker } from "react-color-palette";
import { IconButton, Modal, Portal, TextInput } from "react-native-paper";
import { View } from "react-native";
import { BoxCurrentColor, ContainerPickerColor, TextColorHex } from "./style";
import "react-color-palette/css";

const PickerColorHex = ({
  setShow,
  colorName,
  props,
  isShowPicker,
  setChange,
}) => {
  return (
    <View>
      <TextColorHex>
        <BoxCurrentColor
          style={{
            backgroundColor: colorName.hex,
          }}
          onClick={setShow}
        />

        <TextInput
          value={colorName.hex}
          onChangeText={(text) => setChange({ ...colorName, hex: text })}
          style={{
            backgroundColor: "#fff",
            width: 80,
            height: 24,
            zIndex: 0,
            marginLeft: "0.2rem",
            borderBottomWidth: "1px",
            borderBottomColor: "#B1B1B1",
            textAlignVertical: "top",
          }}
          contentStyle={{ paddingHorizontal: "0px", border: "none" }}
          theme={{
            colors: {
              outline: "#fff",
              primary: "#fff",
            },
          }}
          textColor="#000"
          mode="outlined"
        />
      </TextColorHex>

      {isShowPicker && (
        <Portal>
          <Modal
            visible={isShowPicker}
            onDismiss={setShow}
            animationType="slide"
            contentContainerStyle={{
              alignItems: "center",
              boxShadow: "none",
            }}
          >
            <ContainerPickerColor>
              <IconButton
                icon="close"
                selected
                size={20}
                style={{ margin: 0, alignSelf: "end" }}
                iconColor="white"
                onPress={setShow}
              />
              <ColorPicker hideInput={["rgb", "hsv"]} height={100} {...props} />
            </ContainerPickerColor>
          </Modal>
        </Portal>
      )}
    </View>
  );
};

export default PickerColorHex;
