import styled from 'styled-components/native';
import THEME from '../../config/theme';
import { Dimensions } from "react-native";

const windowWidth = Dimensions.get("window").width;

export const ContentDesktop = styled.View`
width: 70%;
padding: 2rem 6rem;
`;

export const ContentMobile = styled.View`
  padding: ${({ padding }) => (padding)};
`;

export const Image = styled.Image`
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  height: ${(windowWidth - 64) / 1.77};
`;

export const ImageAdmin = styled.Image`
  border-radius: 10px;
  width: 100%;
  height: ${(windowWidth * 0.7 - 244) / 1.77};
`;

export const ViewVideo = styled.View`
  width: 100%;
  height: ${(windowWidth - 64) / 1.77};
`;

export const ViewVideoAdmin = styled.View`
  width: 100%;
  height: ${(windowWidth * 0.7 - 244) / 1.77};
`;

export const ViewTextInput = styled.View`
  height: 5rem;
`;

export const ViewDescription = styled.View`
  justify-content: center;
`;

export const ViewVideoDesktop = styled.View`
  width: 100%;
  height: ${(windowWidth * 0.7 - 244) / 1.77};
`;

export const ViewVideoDesktopAdmin = styled.View`
  width: 100%;
  height: ${((windowWidth * 0.7) - 96) / 1.77};
`;

export const ImageDesktop = styled.Image`
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  height: ${(windowWidth * 0.7 - 244) / 1.77};
`;

export const ContentList = styled.View`
  width: 30%;
  padding: 2rem 0rem;
`;

export const ViewText = styled.View`
  width: 100%; 
  align-self: center;
  margin:0rem 0rem 1rem 0rem;
`;

export const ViewSwitch = styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ViewButton = styled.View`
  flex-direction: ${({ flexDirection }) => (flexDirection)};
  padding: ${({ padding }) => (padding)};
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : 'center')};
`;

export const ContainerAdmin = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    flexDirection: "column",
    backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
    flexGrow: 1,
  },
})`  
`;

export const ContentIcon = styled.TouchableOpacity`
  align-self: center;
  align-items: center;
  padding: 0rem 0.5rem;
  margin: 0.5rem 0rem;
  background-color: "rgba(0,0,0,0.1)";
  border-radius: 20px;
  flex-direction: row;
  justify-content: space-between;
`;

export const ViewModal = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    alignItems: "center",
    padding: "2rem",
    backgroundColor: "#FFFFFF",
    flexGrow: 1,
    justifyContent: "flex-start"
  },
})`  
`;