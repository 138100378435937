import React, { useEffect, useState, useCallback } from "react";
import { SafeAreaView, ActivityIndicator } from "react-native";
import { doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { Container } from "./style";
import Header from "../../components/Header";
import { firestore } from "../../services/firebase";
import THEME from "../../config/theme";
import SearchBar from "../../components/SearchBar";
import { useCustomTheme } from '../../contexts/useCustomTheme';

export function ClickSearch({ navigation }) {
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const auth = getAuth();

  const { customColor } = useCustomTheme();

  const getUserInformation = useCallback(async (userId) => {
    const docRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(docRef);
    setUser(docSnap.data());
    setIsLoading(false);
  }, []);


  useEffect(() => {
    const timer = setTimeout(() => setVisible(!visible), 2000);
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        getUserInformation(user.uid);
      } else if(!user) {  
        // Nenhum usuário está logado, realizar login anônimo.
       signInAnonymously(auth)
       .then(() => {
        setIsLoading(false);
       // Login anônimo realizado com sucesso.
       })
       .catch((error) => {
       // Houve um erro ao realizar login anônimo.
       console.error(error);
       });
      }
      else {
        setIsLoading(false);
      }
    });
    return () => clearTimeout(timer);
  }, [getUserInformation]);

  return isLoading ? (
    <ActivityIndicator
      style={{
        flex: 1,
        backgroundColor:
          customColor && customColor.background
            ? customColor.background.auth
            : THEME.COLORS.BACKGROUND_ABOUT,
        justifyContent: 'center',
      }}
      color={
        customColor && customColor.primary
          ? customColor.primary
          : THEME.COLORS.PRIMARY_800
      }
    />
  ) : (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor:
          customColor && customColor.background
            ? customColor.background.app
            : THEME.COLORS.BACKGROUND_MAIN,
      }}
    >
      <Header
        style={{
          backgroundColor:
            customColor && customColor.background
              ? customColor.background.app
              : THEME.COLORS.BACKGROUND_MAIN,
        }}
        onPress={() =>
          navigation.navigate('Conteudo', { screen: 'Aplicativo' })
        }
      />
      <Container>
        <SearchBar visible={visible} user={user} />
      </Container>
    </SafeAreaView>
  );
}
