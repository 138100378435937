import styled from 'styled-components/native'

export const Avatar = styled.Image`
width: ${({ width }) => width};
height: 100%;
margin-right: ${({ marginRight }) => marginRight};
`;

export const HeaderDesktop = styled.View`
    flex-direction: row;
    height: 3rem;
`;

export const Content = styled.View`
    align-items: flex-start;
    flex-direction: row; 
`;