import React, { useState, useEffect } from 'react';
import { Modal, Portal, Provider } from 'react-native-paper';
import { SafeAreaView } from 'react-native';
import {
  Content,
  Image,
  ViewTextInput,
  ViewButton,
  ViewImage,
  ViewText,
  ViewHelper,
  Poster,
} from './style';
import { emailValidator, passwordValidator, removeSansSerif } from '../../utils';
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { ResetPassword } from './ResetPassword';
import TouchableText from '../../components/TouchableText';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { AlertBox } from '../../components/AlertBox';
import THEME from '../../config/theme';
import ViewPortProvider from '../../hooks/ViewPortProvider';
import useViewport from '../../hooks/useViewport';
import { HelperText } from 'react-native-paper';
import { ActivityIndicator } from 'react-native-paper';
import {
  ContainerSideView,
  SideView,
  Container,
} from '../../config/theme/globalStyles';
import { CommonActions } from '@react-navigation/native';
import FotoLogin from '../../../assets/FotoLogin.jpg';
import LogoLogin from './../../../assets/LogoLogin.png';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { useRoute } from '@react-navigation/native';
import { useCustomImages } from '../../api/Brand';
import { useCustomTheme } from '../../contexts/useCustomTheme';

const auth = getAuth();

export function Login({ navigation }) {
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const { customFont, customColor, isLoadingCustomInfo } = useCustomTheme();
  const containerStyle = {
    backgroundColor: 'white',
    padding: '2rem',
    width: Math.round(window.innerWidth * 0.7),
    alignSelf: 'center',
  };
  const [email, setEmail] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [loading, setLoading] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [title, setTitle] = useState(null);
  const [message, setMessege] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data: logo, isLoading: isLoadingLogo } = useCustomImages('logoAuth');
  const { data: imageRight, isLoading: isLoadingImage } = useCustomImages('imageAuth');

  const route = useRoute();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        navigation.navigate('Conteudo', { screen: 'Aplicativo' });
        setLoading(false);
      } else if (!user) {
        // Nenhum usuário está logado, realizar login anônimo.
        signInAnonymously(auth)
          .then(() => {
            setIsLoading(false);
            // Login anônimo realizado com sucesso.
          })
          .catch((error) => {
            // Houve um erro ao realizar login anônimo.
            console.error(error);
          });
      } else {
        setLoading(false);
      }
    });
  }, []);

  const showAlert = (title, message) => {
    setVisibleAlert(true);
    setTitle(title);
    setMessege(message);
  };

  const hideAlert = (status) => {
    setVisibleAlert(status);
  };

  const validation = () => {
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);

    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      setLoading(false);
      return;
    }
  };

  const onLoginPressed = () => {
    validation();
    if (email.value && password.value) {
      setLoading(true);
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          signInWithEmailAndPassword(auth, email.value, password.value)
            .then((userCredential) => {
              const user = userCredential.user;
              user?.isAdmin
                ? navigation.dispatch(
                    CommonActions.reset({
                      index: 1,
                      routes: [{ name: 'Conteudo' }],
                    })
                  )
                : navigation.navigate('Conteudo', { screen: 'Aplicativo' });
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
              switch (error.code) {
                case 'auth/user-not-found':
                  showAlert('Erro:', 'Usuário não cadastrado.');
                  break;
                case 'auth/wrong-password':
                  showAlert('Erro:', 'Senha incorreta.');
                  break;
                case 'auth/invalid-email':
                  showAlert('Erro:', 'E-mail inválido.');
                  break;
                case 'auth/user-disabled':
                  showAlert('Erro:', 'Usuário desabilitado.');
                  break;
                case 'auth/invalid-login-credentials':
                  showAlert('Erro:', 'Email ou senha inválida.');
                  break;
                case 'auth/too-many-requests':
                  showAlert('Erro:', 'Não foi possível realizar o login. Tente novamente mais tarde.');
                  break;
              }
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode + errorMessage);
        });
    }
  };

  
  const MobileOrDesktopComponent = () => {
    const { width } = useViewport();
    const breakpoint = 1080;
    return width < breakpoint ? (
      <SafeAreaView></SafeAreaView>
    ) : (
      <SideView
        style={{
          backgroundColor:
            customColor &&
            customColor?.background &&
            customColor?.background.app
              ? customColor?.background.app
              : THEME.COLORS.BACKGROUND_ABOUT,
        }}
      >
        {isLoadingImage ? (
          <ActivityIndicator
            style={{
              flex: 1,
              justifyContent: 'center',
            }}
            color={
              customColor && customColor.primary
                ? customColor.primary
                : THEME.COLORS.PRIMARY_800
            }
          />
        ) : (
          <Poster source={imageRight ? imageRight?.url : FotoLogin}></Poster>
        )}
      </SideView>
    );
  };

  if (isLoading || isLoadingCustomInfo) {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
          justifyContent: 'center',
        }}
        color={
          customColor && customColor.primary
            ? customColor.primary
            : THEME.COLORS.PRIMARY_800
        }
      />
    );
  }

  return (
    <Provider>
      <ViewPortProvider>
        <ContainerSideView>
          <Container
            style={{
              backgroundColor:
                customColor &&
                customColor.background &&
                customColor.background.auth
                  ? customColor.background.auth
                  : THEME.COLORS.BACKGROUND_ABOUT,
            }}
          >
            {isLoadingLogo ? (
              <ActivityIndicator
                style={{
                  flex: 1,
                  justifyContent: 'center',
                }}
                color={
                  customColor && customColor.primary
                    ? customColor.primary
                    : THEME.COLORS.PRIMARY_800
                }
              />
            ) : (
              <ViewImage>
                <Image
                  resizeMode="contain"
                  source={logo ? logo.url : LogoLogin}
                ></Image>
              </ViewImage>
            )}
            <Content>
              <ViewTextInput>
                <ViewText>
                  <TextInput
                    label="Email"
                    placeholder="Digite seu email"
                    returnKeyType="next"
                    value={email.value}
                    onChangeText={(text) =>
                      setEmail({ value: text, error: '' })
                    }
                    error={!!email.error}
                    autoCapitalize="none"
                    autoCompleteType="email"
                    textContentType="emailAddress"
                    keyboardType="email-address"
                    backgroundColor={
                      customColor &&
                      customColor.background &&
                      customColor.background.auth
                        ? customColor.background.auth
                        : THEME.COLORS.BACKGROUND_ABOUT
                    }
                    colorPrimary={
                      customColor &&
                      customColor.button &&
                      customColor.button.backgroundAuth
                        ? customColor.button.backgroundAuth
                        : THEME.COLORS.PRIMARY_900
                    }
                    colorOutline={
                      customColor &&
                      customColor.primary
                        ? customColor.primary
                        : THEME.COLORS.PRIMARY_900
                    }
                    colorSurface={
                      customColor && customColor.text && customColor.text.auth
                        ? customColor.text.auth
                        : THEME.COLORS.TITLE_ABOUT
                    }
                  />
                </ViewText>
                <ViewHelper>
                  <HelperText type="error" visible={email.error}>
                    {email.error}
                  </HelperText>
                </ViewHelper>
              </ViewTextInput>
              <ViewTextInput>
                <ViewText>
                  <TextInput
                    label="Senha"
                    placeholder="Digite sua senha"
                    returnKeyType="done"
                    value={password.value}
                    onChangeText={(text) =>
                      setPassword({ value: text, error: '' })
                    }
                    error={!!password.error}
                    secureTextEntry
                    autoCorrect={false}
                    backgroundColor={
                      customColor &&
                      customColor.background &&
                      customColor.background.auth
                        ? customColor.background.auth
                        : THEME.COLORS.BACKGROUND_ABOUT
                    }
                    colorPrimary={
                      customColor &&
                      customColor.button &&
                      customColor.button.backgroundAuth
                        ? customColor.button.backgroundAuth
                        : THEME.COLORS.PRIMARY_900
                    }
                    colorOutline={
                      customColor &&
                      customColor.primary
                        ? customColor.primary
                        : THEME.COLORS.PRIMARY_900
                    }
                    colorSurface={
                      customColor && customColor.text && customColor.text.auth
                        ? customColor.text.auth
                        : THEME.COLORS.TITLE_ABOUT
                    }
                  />
                </ViewText>
                <ViewHelper>
                  <HelperText type="error" visible={password.error}>
                    {password.error}
                  </HelperText>
                </ViewHelper>
              </ViewTextInput>
              <ViewButton>
                <Button
                  title={'ENTRAR'}
                  isLoading={loading}
                  onPress={route.name === 'Marca' ? () => {} : onLoginPressed}
                  colorbutton={
                    customColor &&
                    customColor.button &&
                    customColor.button.backgroundAuth
                      ? customColor.button.backgroundAuth
                      : THEME.COLORS.PRIMARY_900
                  }
                  colortitle={
                    customColor &&
                    customColor.button &&
                    customColor.button.textAuth
                      ? customColor.button.textAuth
                      : THEME.COLORS.TEXT_BUTTON
                  }
                ></Button>
              </ViewButton>
              <TouchableText
                onPress={showModal}
                title={'RECUPERAR SENHA'}
                fontSize={
                  customFont && customFont.fontSM
                    ? customFont.fontSM
                    : THEME.FONTSIZE.SMALL
                }
                color={
                  customColor && customColor.text && customColor.text.auth
                    ? customColor.text.auth
                    : THEME.COLORS.TITLE_ABOUT
                }
                fontFamily={
                  customFont && customFont.fontFamilyTitle
                    ? customFont.fontFamilyTitle
                    : THEME.FONTFAMILY.REGULAR
                }
              ></TouchableText>
            </Content>
            <Portal>
              <Modal
                visible={visible}
                onDismiss={hideModal}
                contentContainerStyle={containerStyle}
              >
                <ResetPassword></ResetPassword>
              </Modal>
            </Portal>
            {visibleAlert && (
              <AlertBox
                title={title}
                message={message}
                visible={visibleAlert}
                onClose={hideAlert}
              ></AlertBox>
            )}
          </Container>
          <MobileOrDesktopComponent></MobileOrDesktopComponent>
        </ContainerSideView>
      </ViewPortProvider>
    </Provider>
  );
}
