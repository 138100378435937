import styled from 'styled-components/native';

export const Container = styled.View`
  width: 32rem;
  background: white;
  padding: 0.5rem 1rem;
`;

export const ItemForm = styled.View`
  flex-direction: row;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  padding: 0.5rem 0 0.5rem 0;
  border-color: ${(props) => props.theme.COLORS.PRIMARY_800};
  border-bottom-width: 1px;
  margin-bottom: 0.5rem;
  z-index: 0;
`;


export const ViewButton = styled.View`
  flex-direction: row; 
  justify-content: center; 
  align-items: center; 
  padding: 10px;
`;
