import { useState } from 'react';
import { Text } from 'react-native';
import { Tooltip } from 'react-native-elements';
import { IconButton } from 'react-native-paper';

export const ControlledTooltip = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <Tooltip
      visible={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      withOverlay={false}
      withPointer={true}
      backgroundColor="#666666"
      {...props}
    />
  );
};

export const TooltipCustom = ({ text, height, width }) => {
  return (
    <ControlledTooltip
      popover={<Text style={{ color: '#fff' }}>{text}</Text>}
      containerStyle={{
        width: width ? width : 550,
        height: height ? height : 44,
      }}
    >
      <IconButton
        icon="information-outline"
        selected
        size={16}
        iconColor="black"
      />
    </ControlledTooltip>
  );
};
