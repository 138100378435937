import React from "react";
import { FlatList, View, TouchableOpacity } from "react-native";
import {
  ContentList,
  ContainerPlaylist,
  Content,
  Avatar,
  ViewButton,
  ContentIcon,
  ContentImage,
  ViewCommentNotification,
} from "../style";

import useViewport from "../../../hooks/useViewport";
import ListHeader from "../../../components/ListHeader";
import THEME from "../../../config/theme";
import { StandardText, SmallText } from "../../../config/theme/globalStyles";

import { Provider } from "react-native-paper";
import { MaterialIcons, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { useCustomTheme } from '../../../contexts/useCustomTheme';

const FlatListView = (props) => {
  const {
    videos,
    selectedId,
    onAddVideoClick,
    cardInformation,
    onEditVideoClick,
    onDeleteVideoClick,
    onEditCardInformationClick,
    onEditOrderClick,
    onDeleteCardClick,
    onEditVideoBackgroundClick,
    onVideoListClick,
    user,
  } = props;

  const { width } = useViewport();
  const breakpoint = 1080;
  const smallScreen = width < breakpoint;
  const newVideoButtonSize = smallScreen ? 24 : 28;
  const addAPhotoSize = smallScreen ? 30 : 40;

  const { customColor, customFont } = useCustomTheme();

  const content = (
    <>
      <ViewButton>
        <ContentIcon onPress={() => onAddVideoClick('live')}>
          <MaterialIcons
            name="my-library-add"
            size={newVideoButtonSize}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
          />
          <SmallText
            padding="0.5rem"
            fontSize={
              customFont && customFont.fontSM
                ? customFont.fontSM
                : THEME.FONTSIZE.SMALL
            }
            style={{
              fontFamily:
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.REGULAR,
            }}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }>
            NOVA AULA AO VIVO
          </SmallText>
        </ContentIcon>
        <ContentIcon onPress={() => onAddVideoClick('content')}>
          <MaterialIcons
            name="my-library-add"
            size={newVideoButtonSize}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
          />
          <SmallText
            padding="0.5rem"
            fontSize={
              customFont && customFont.fontSM
                ? customFont.fontSM
                : THEME.FONTSIZE.SMALL
            }
            style={{
              fontFamily:
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.REGULAR,
            }}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }>
            NOVO VÍDEO/ÁUDIO
          </SmallText>
        </ContentIcon>
      </ViewButton>
      <FlatList
        data={videos}
        extraData={selectedId}
        keyExtractor={(item) => item.docId}
        renderItem={({ item, index }) => (
          <Provider>
            <TouchableOpacity
              style={{ marginVertical: '0.8rem' }}
              key={item.key}
              onPress={() => onVideoListClick(item)}
            >
              <ContainerPlaylist>
                <Avatar
                  resizeMode="cover"
                  source={{ uri: item.backgroundImage?.url || item.image }}
                >
                  <View style={{ flexDirection: 'row', flex: 1 }}>
                    <ViewCommentNotification />
                    <ContentImage
                      onPress={() => onEditVideoBackgroundClick(item)}
                    >
                      <MaterialIcons
                        name="add-a-photo"
                        size={addAPhotoSize}
                        color={THEME.COLORS.TEXT_MAIN}
                      />
                    </ContentImage>
                    {item.hasNewComment == true && user?.isAdmin && (
                      <ViewCommentNotification>
                        <MaterialCommunityIcons
                          name="comment-eye"
                          size={18}
                          color={
                            customColor && customColor.primary
                              ? customColor.primary
                              : THEME.COLORS.PRIMARY_900
                          }
                        />
                      </ViewCommentNotification>
                    )}
                  </View>
                </Avatar>
                <Content>
                  <StandardText
                    style={{
                      fontFamily:
                        customFont && customFont.fontFamilySubtitle
                          ? customFont.fontFamilySubtitle
                          : THEME.FONTFAMILY.MEDIUM,
                      fontSize:
                        customFont && customFont.fontMD
                          ? customFont.fontMD
                          : THEME.FONTSIZE.MEDIUM,
                    }}
                    color={
                      customColor && customColor.text && customColor.text.app
                        ? customColor.text.app
                        : THEME.COLORS.TEXT_MAIN
                    }
                    textAlign="flex-start"
                    numberOfLines={2}
                  >
                    {item.title}
                  </StandardText>
                </Content>
                <ContentIcon onPress={() => onEditVideoClick(item)}>
                  <Feather
                    name="edit-3"
                    size={24}
                    color={
                      customColor && customColor.text && customColor.text.app
                        ? customColor.text.app
                        : THEME.COLORS.TEXT_MAIN
                    }
                  />
                </ContentIcon>
                <ContentIcon
                  onPress={() => {
                    onDeleteVideoClick(item);
                  }}
                >
                  <MaterialIcons
                    name="delete-outline"
                    size={24}
                    color={
                      customColor && customColor.text && customColor.text.app
                        ? customColor.text.app
                        : THEME.COLORS.TEXT_MAIN
                    }
                  />
                </ContentIcon>
              </ContainerPlaylist>
            </TouchableOpacity>
          </Provider>
        )}
        style={{ flexGrow: 0 }}
        ListHeaderComponent={
          <ListHeader
            video={cardInformation}
            onEditCardInformationClick={onEditCardInformationClick}
            onEditOrderClick={onEditOrderClick}
            onDeleteCardClick={onDeleteCardClick}
            user={user}
            adminOptions={true}
          />
        }
      />
    </>
  );


  return width < breakpoint ? (
    <View style={{ paddingHorizontal: "1rem" }}>{content}</View>
  ) : (
    <ContentList>{content}</ContentList>
  );

};

export default FlatListView;
