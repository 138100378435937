import React from "react";
import {
  Container,
  Logo,
  FooterLeftSide,
  FooterRightSide,
  ContainerMobile
} from "./style";
import { FooterText } from "../../config/theme/globalStyles";
import THEME from "../../config/theme";
import { footerSocialData, clientNameFooter } from "../../config/data";
import { Icon } from "react-native-elements";
import { Linking, TouchableOpacity } from 'react-native';
import { useNavigation } from "@react-navigation/native";
import TouchableText from "../TouchableText";
import ViewPortProvider from '../../hooks/ViewPortProvider';
import useViewport from '../../hooks/useViewport';
import BlixColorSvg from "../../../assets/blix-color.svg";
import { useCustomTheme } from '../../contexts/useCustomTheme';

const Footer = () => {
   const { customFont } = useCustomTheme();
  const MobileOrDesktopComponent = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <ContainerMobile>
        <FooterLeftSide width="100%" justifyContent="center">
          <FooterText
            textAlign="left"
            style={{
              fontFamily:
                customFont && customFont.fontFamilyTitle
                  ? customFont.fontFamilyTitle
                  : THEME.FONTFAMILY.BOLD,
            }}
            margin="0rem 0.5rem"
          >
            {clientNameFooter}
          </FooterText>
          {footerSocialData.map((social, index) => (
            <Icon
              key={index}
              type={social.type}
              name={social.name}
              size={20}
              onPress={() => Linking.openURL(social.link)}
              iconStyle={{
                color: THEME.COLORS.TEXT_FOOTER,
                marginHorizontal: '0.3rem',
              }}
            ></Icon>
          ))}
          <TouchableText
            onPress={() => navigation.navigate('TermosdeUso')}
            title={'Termos de Uso'}
            textDecoration="underline"
            color={THEME.COLORS.TEXT_FOOTER}
            style={{
              fontFamily:
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.LIGHT,
            }}
            margin="0rem 1rem"
          ></TouchableText>
        </FooterLeftSide>

        <FooterRightSide width="100%" justifyContent="center">
          <FooterText margin="0.3rem 0.2rem">Desenvolvido com</FooterText>
          <Icon
            type="material-community"
            name="heart"
            color={THEME.COLORS.TEXT_FOOTER}
            size="12px"
          />
          <FooterText margin="0.3rem 0.2rem">pela</FooterText>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL('https://www.instagram.com/blix.tecnologia/')
            }
          >
            <Logo source={BlixColorSvg}></Logo>
          </TouchableOpacity>
          <FooterText
            style={{
              fontFamily:
                customFont && customFont.fontFamilyTitle
                  ? customFont.fontFamilyTitle
                  : THEME.FONTFAMILY.BOLD,
            }}
            margin="0.3rem 0.3rem"
            onPress={() =>
              Linking.openURL('https://www.instagram.com/blix.tecnologia/')
            }
          >
            BLIX
          </FooterText>
        </FooterRightSide>
      </ContainerMobile>
    ) : (
      <Container>
        <FooterLeftSide width="50%" justifyContent="flex-start">
          <FooterText
            textAlign="left"
            style={{
              fontFamily:
                customFont && customFont.fontFamilyTitle
                  ? customFont.fontFamilyTitle
                  : THEME.FONTFAMILY.BOLD,
            }}
            margin="0rem 0.5rem"
          >
            {clientNameFooter}
          </FooterText>
          {footerSocialData.map((social, index) => (
            <Icon
              key={index}
              type={social.type}
              name={social.name}
              size="20px"
              onPress={() => Linking.openURL(social.link)}
              iconStyle={{
                color: THEME.COLORS.TEXT_FOOTER,
                marginHorizontal: '0.3rem',
              }}
            ></Icon>
          ))}
          <TouchableText
            onPress={() => navigation.navigate('TermosdeUso')}
            title={'Termos de Uso'}
            textDecoration="underline"
            color={THEME.COLORS.TEXT_FOOTER}
            style={{
              fontFamily:
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.LIGHT,
            }}
            margin="0rem 1rem"
          ></TouchableText>
        </FooterLeftSide>

        <FooterRightSide width="50%" justifyContent="flex-end">
          <FooterText padding="0rem 0.2rem">Desenvolvido com</FooterText>
          <Icon
            type="material-community"
            name="heart"
            color={THEME.COLORS.TEXT_FOOTER}
            size="12px"
          />
          <FooterText padding="0rem 0.2rem">pela</FooterText>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL('https://www.instagram.com/blix.tecnologia/')
            }
          >
            <Logo source={BlixColorSvg}></Logo>
          </TouchableOpacity>
          <FooterText
            style={{
              fontFamily:
                customFont && customFont.fontFamilyTitle
                  ? customFont.fontFamilyTitle
                  : THEME.FONTFAMILY.BOLD,
            }}
            padding="0rem 0.3rem"
            onPress={() =>
              Linking.openURL('https://www.instagram.com/blix.tecnologia/')
            }
          >
            BLIX
          </FooterText>
        </FooterRightSide>
      </Container>
    );
  };

  const navigation = useNavigation();
  return (
    <ViewPortProvider>
      <MobileOrDesktopComponent />
    </ViewPortProvider>

  );
};

export default Footer;
