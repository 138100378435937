import styled from "styled-components/native";
import THEME from "../../config/theme";

export const ViewSectionAdmin = styled.View`
  padding: 1rem 0rem;
  flex-basis: auto;
  background-color: ${THEME.COLORS.BACKGROUND_ABOUT};
`;

export const ViewButton = styled.View`
  padding: 0.5rem 0rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ViewDescription = styled.View`
  justify-content: center;
  padding: 1rem 0rem 0rem 0rem;
`;

export const ViewTextInput = styled.View`
  height: 5rem;
`;

export const ViewTextAdmin = styled.View`
  height: 80%;
`;

export const ViewHelper = styled.View`
  height: 20%;
  justify-content: center;
`;

export const Container = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    flexDirection: "column",
    flexBasis: "auto",
  },
})``;

export const ViewModal = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    alignItems: "center",
    padding: "2rem",
    backgroundColor: "#FFFFFF",
    flexGrow: 1,
    justifyContent: "flex-start"
  },
})`  
`;

export const ContentDesktop = styled.View`
  width: 70%;
  padding: 2rem 6rem;
`;

export const ContentMobile = styled.View`
  padding: 2rem 1rem;
`;

export const ContentList = styled.View`
  width: 30%;
  padding: 2rem 0rem;
`;


export const ViewSwitch = styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ContentIcon = styled.TouchableOpacity`
  align-self: center;
  align-items: center;
  padding: 0rem 0.5rem;
  background-color: "rgba(0,0,0,0.1)";
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
`;

export const ContainerAdmin = styled.View`
  background-color: ${THEME.COLORS.BACKGROUND_ABOUT}
  flex: 1;
`;

export const ViewCookies = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  margin: 1rem 0rem;
  z-index: 99999;
  bottom: 0;
`;