import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteField,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../services/firebase";
import { queryClient } from "../queries/queryClient";



const updatedColors = async (body) => {
  const categoryRef = doc(firestore, "customization", "colors");
  const data = await setDoc(categoryRef, body, { merge: true });
  return data;
};

const updatedFonts = async (body) => {
  const categoryRef = doc(firestore, "customization", "fonts");
  const data = await setDoc(categoryRef, body, { merge: true });

  return data;
};

const theme = async (docId) => {
  const docRef = doc(firestore, "customization", docId);
  const docSnap = await getDoc(docRef);

  const data = { id: docSnap.id, ...docSnap.data() };

  return data;
};

const updatedCustomizationInfo = async (body) => {
  const categoryRef = doc(firestore, "customization", "infos");
  const data = await setDoc(categoryRef, body, { merge: true });

  return data;
};

const customImages = async (fileType) => {
  const docRef = doc(
    firestore,
    "customization",
    fileType.includes("font") ? "fonts" : "images"
  );
  const docSnap = await getDoc(docRef);

  const imageData = { id: docSnap.id, ...docSnap.data() };
  const data = imageData[fileType] ? imageData[fileType] : null;

  return data;
};

const deleteFont = async (fontType) => {
  const docRef = doc(firestore, "customization", "fonts");

  await updateDoc(docRef, { [fontType]: deleteField() });
};

//QUERIES
export const useGetTheme = (docId) => {
  return useQuery({
    queryKey: [docId],
    queryFn: () => theme(docId),
  });
};

export const useCustomImages = (fileType) => {
  return useQuery({
    queryKey: ["IMAGE", fileType],
    queryFn: () => customImages(fileType),
  });
};

//MUTATIONS
export const useUpdatedColors = () => {
  return useMutation({
    mutationFn: (data) => updatedColors(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["colors"],
      });
    },
    onError: (err) => {
      console.log(`Unsuccessful returned error ${err}`);
    },
  });
};

export const useUpdatedFonts = () => {
  return useMutation({
    mutationFn: (data) => updatedFonts(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["fonts"],
      });
    },
    onError: (err) => {
      console.log(`Unsuccessful returned error ${err}`);
    },
  });
};

export const useUpdatedCustomizationInfo = () => {
  return useMutation({
    mutationFn: (data) => updatedCustomizationInfo(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["infos"],
      });
    },
    onError: (err) => {
      console.log(`Unsuccessful returned error ${err}`);
    },
  });
};

export const useDeleteFont = (fontType) => {
  return useMutation({
    mutationFn: () => deleteFont(fontType),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["fonts"],
      });
    },
    onError: (err) => {
      console.log(`Unsuccessful returned error ${err}`);
    },
  });
};
