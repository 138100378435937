import { useMutation, useQuery } from "@tanstack/react-query";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../services/firebase";
import Toast from "react-native-toast-message";
import { queryClient } from "../queries/queryClient";


const userInfo = async (userId) => {
  const userDocRef = doc(firestore, "users", userId);

  // filter by user id
  const userDoc = await getDoc(userDocRef);
  return { ...userDoc.data(), id: userDoc.id };
};

const updatedUserInfo = async (userId, body) => {
  const categoryRef = doc(firestore, "users", userId);
  const data = await setDoc(categoryRef, body, { merge: true });

  return data;
};

//QUERIES
export const useGetUserInfo = (userId) => {
  return useQuery({
    queryKey: ["USER_INFO"],
    queryFn: () => userInfo(userId),
  });
};

//MUTATIONS

export const useUpdatedUserInfo = (userId) => {
  return useMutation({
    mutationFn: (data) => updatedUserInfo(userId, data),
    onSuccess: () => {
      Toast.show({
        text1: "Atualizações salvas com sucesso!",
        type: "success",
        position: "top",
        autoHide: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["USER_INFO"],
      });
    },
    onError: (err) => {
      console.log(`Unsuccessful returned error ${err}`);
    },
  });
};
