import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  View,
  ActivityIndicator,
  Modal,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import {
  MaterialCommunityIcons,
  Octicons,
  MaterialIcons,
  Ionicons,
  AntDesign,
} from '@expo/vector-icons';
import { ContentIcon, Content } from './style';
import THEME from '../../config/theme';
import { FooterText, SmallText, Title } from '../../config/theme/globalStyles';
import { getDoc, setDoc, doc } from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import { useCustomTheme } from '../../contexts/useCustomTheme';

const ListHeader = ({
  cardId,
  categoryId,
  videoId,
  video,
  onEditCardInformationClick,
  onEditOrderClick,
  onDeleteCardClick,
  user,
  login,
  adminOptions,
}) => {

  const { customColor, customFont } = useCustomTheme();

  const [updatedPdf, setUpdatedPdf] = useState([]);

  useEffect(() => {
    if (video?.pdf !== undefined) {
      if (Array.isArray(video?.pdf)) {
        setUpdatedPdf(video?.pdf);
      } else if (typeof video?.pdf === 'string' && video?.pdf.trim() === '') {
        setUpdatedPdf([]);
      } else if (typeof video?.pdf === 'string') {
        setUpdatedPdf([{ url: video?.pdf, name: '', title: video.pdf?.title || 'PDF' }]);
      } else {
        setUpdatedPdf([video.pdf]);
      }
    }
  }, [video?.pdf]);

  const [watched, setWatched] = useState(false);
  const [like, setLike] = useState(false);
  const [loadingWatchedUpdate, setLoadingWatchedUpdate] = useState(true);
  const [loadingLikeUpdate, setLoadingLikeUpdate] = useState(true);
  const [activeProgress, setActiveProgress] = useState(false);

  const intervalIdRef = useRef(null);
  const scriptTagRef = useRef(null);

  const videoUrl =
    video?.videoInfo?.storage == 'panda' ||
    video?.pandaVideoMetadata?.video_player;

  useEffect(() => {
    if (!user?.isAdmin && !scriptTagRef.current && videoUrl) {
      const scriptTag = document.createElement('script');
      scriptTag.src = 'https://player.pandavideo.com.br/api.v2.js';
      scriptTag.async = true;
      document.body.appendChild(scriptTag);
      scriptTagRef.current = scriptTag;

      scriptTag.onload = () => {
        window.pandascripttag = window.pandascripttag || [];
        window.pandascripttag.push(() => {
          const player = new PandaPlayer('panda-1', {
            onReady: () => {
              const intervalId = setInterval(() => {
                const progress =
                  (player.getCurrentTime() * 100) / player.getDuration();

                if (progress >= 95 && !watched) {
                  setWatched(true);
                  setActiveProgress(true);
                  handleUpdateWatched();
                }
              }, 500);

              // Store intervalId in the intervalIdRef
              intervalIdRef.current = intervalId;
            },
          });
        });
      };
    }

    return () => {
      const intervalId = intervalIdRef.current;
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [user?.isAdmin, watched]);

  const handleUpdateLike = useCallback(async () => {
    if (loadingLikeUpdate) {
      return;
    }
    setLoadingLikeUpdate(true);

    let date = new Date();

    try {
      const contentsDocRef = doc(
        firestore,
        'users',
        user?.id,
        'contents',
        videoId
      );
      await setDoc(
        contentsDocRef,
        {
          cardId,
          categoryId,
          markedAsFavoriteAt: date,
          isFavorite: !like,
        },
        { merge: true }
      );
      setLike(!like);
    } catch (error) {
      console.error('Error adding document isFavorite: ', error);
    }
    setLoadingLikeUpdate(false);
  }, [loadingLikeUpdate, user?.id, videoId, like]);

  const handleUpdateWatched = useCallback(async () => {
    if (loadingWatchedUpdate && watched) {
      return;
    } else if (activeProgress) {
      return;
    }
    setLoadingWatchedUpdate(true);

    let date = new Date();

    try {
      const contentsDocRef = doc(
        firestore,
        'users',
        user?.id,
        'contents',
        videoId
      );
      await setDoc(
        contentsDocRef,
        {
          cardId,
          categoryId,
          markedAsWatchedAt: date,
          isWatched: true,
        },
        { merge: true }
      );
      setWatched(true);
    } catch (error) {
      console.error('Error adding document isWatched: ', error);
    }
    setLoadingWatchedUpdate(false);
  }, [loadingWatchedUpdate, user?.id, videoId, watched]);

  const loadDetail = useCallback(async () => {
    try {
      const watchedDocRef = doc(
        firestore,
        'users',
        user?.id,
        'contents',
        videoId
      );
      const docSnap = await getDoc(watchedDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setWatched(data.isWatched);
        setLike(data.isFavorite);
      }
      setLoadingLikeUpdate(false);
      setLoadingWatchedUpdate(false);
    } catch (error) {
      console.log(error);
    }
  }, [user?.id, videoId]);

  useEffect(() => {
    loadDetail();
  }, []);

  const handlePress = (link) => {
    if (login) {
      window.open(link, '_blank');
    }
  };

  const Pdf = ({ pdfs }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const openModal = () => {
      setModalVisible(true);
    };

    const closeModal = () => {
      setModalVisible(false);
    };

    const namePdf = (item) => {
      if (!item.title) {
        return item.name.slice(24);
      }

      if (item.name === item.title) {
        return item.name.slice(24);
      }
      return item.title;
    };

    return (
      <ContentIcon>
        <TouchableOpacity onPress={openModal}>
          <Ionicons
            name="document-text-outline"
            size={24}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
          />
          <FooterText
            fontSize={customFont && customFont.fontXS ? customFont.fontXS : 12}
            style={{
              fontFamily:
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.LIGHT,
            }}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
            margin="0.5rem 0rem"
          >
            Arquivo
          </FooterText>
        </TouchableOpacity>

        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={closeModal}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              paddingTop: '20%',
            }}
          >
            <View
              style={{
                width: '60%',
                backgroundColor: 'white',
                padding: 20,
                borderRadius: 10,
              }}
            >
              <Content justifyContent="space-between" padding="0.5rem">
                <SmallText
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilyText
                        ? customFont.fontFamilyText
                        : THEME.FONTFAMILY.REGULAR,
                  }}
                  color="black"
                >
                  Selecione um PDF:
                </SmallText>
                <TouchableOpacity onPress={closeModal}>
                  <AntDesign name="closecircleo" size={18} color="black" />
                </TouchableOpacity>
              </Content>
              <FlatList
                data={pdfs}
                keyExtractor={(item) => item?.url}
                renderItem={({ item }) => (
                  <View
                    style={{
                      padding: 10,
                      borderBottomWidth: 1,
                      borderBottomColor: '#ccc',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        window.open(item.url, '_blank');
                      }}
                      style={{ paddingRight: '1.5rem' }}
                    >
                      <SmallText
                        style={{
                          fontFamily:
                            customFont && customFont.fontFamilyText
                              ? customFont.fontFamilyText
                              : THEME.FONTFAMILY.REGULAR,
                        }}
                        color="#2e2e2e"
                      >
                        {namePdf(item)}
                      </SmallText>
                    </TouchableOpacity>
                  </View>
                )}
              />
            </View>
          </View>
        </Modal>
      </ContentIcon>
    );
  };

  const Live = () => {
    return (
      <ContentIcon>
        <TouchableOpacity onPress={() => handlePress(video?.url)}>
          <Octicons
            name="link-external"
            size={24}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
          />
          <FooterText
            fontSize={customFont && customFont.fontXS ? customFont.fontXS : 12}
            style={{
              fontFamily:
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.LIGHT,
            }}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
            margin="0.5rem 0rem"
          >
            Link
          </FooterText>
        </TouchableOpacity>
      </ContentIcon>
    );
  };

  const Favorite = () => {
    return (
      <ContentIcon disabled={loadingLikeUpdate} onPress={handleUpdateLike}>
        {loadingLikeUpdate ? (
          <ActivityIndicator
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
            size={24}
          />
        ) : (
          <Ionicons
            name={like ? 'ios-heart-sharp' : 'ios-heart-outline'}
            size={24}
            style={{
              color: customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN,
            }}
          />
        )}
        <FooterText
          fontSize={customFont && customFont.fontXS ? customFont.fontXS : 12}
          style={{
            fontFamily:
              customFont && customFont.fontFamilyText
                ? customFont.fontFamilyText
                : THEME.FONTFAMILY.LIGHT,
          }}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TEXT_MAIN
          }
          margin="0.5rem 0rem"
        >
          {like ? 'Marcado como favorito' : 'Marcar como favorito'}
        </FooterText>
      </ContentIcon>
    );
  };

  const Watched = () => {
    return (
      <ContentIcon
        disabled={loadingWatchedUpdate || watched}
        onPress={handleUpdateWatched}
      >
        {loadingWatchedUpdate ? (
          <ActivityIndicator
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
            size={24}
          />
        ) : (
          <Ionicons
            name={
              watched ? 'md-checkmark-circle' : 'md-checkmark-circle-outline'
            }
            size={24}
            style={{
              color: customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN,
            }}
          />
        )}
        <FooterText
          fontSize={customFont && customFont.fontXS ? customFont.fontXS : 12}
          style={{
            fontFamily:
              customFont && customFont.fontFamilyText
                ? customFont.fontFamilyText
                : THEME.FONTFAMILY.LIGHT,
          }}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TEXT_MAIN
          }
          margin="0.5rem 0rem"
        >
          {watched ? 'Conteúdo assistido' : 'Marcar como assistido'}
        </FooterText>
      </ContentIcon>
    );
  };

  const Edit = () => {
    return (
      <ContentIcon
        onPress={onEditCardInformationClick}
        padding="0.2rem"
        width="30%"
        backgroundColor="rgba(255,255,255,0.1)"
        borderRadius="10px"
      >
        <MaterialCommunityIcons
          name="playlist-edit"
          size={24}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TEXT_MAIN
          }
        />
        <FooterText
          fontSize={customFont && customFont.fontXS ? customFont.fontXS : 12}
          style={{
            fontFamily:
              customFont && customFont.fontFamilyText
                ? customFont.fontFamilyText
                : THEME.FONTFAMILY.LIGHT,
          }}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TEXT_MAIN
          }
          margin="0.5rem 0rem"
        >
          Editar Informações
        </FooterText>
      </ContentIcon>
    );
  };

  const DeleteCard = () => {
    return (
      <ContentIcon
        onPress={onDeleteCardClick}
        padding="0.2rem"
        width="30%"
        backgroundColor="rgba(255,255,255,0.1)"
        borderRadius="10px"
      >
        <MaterialIcons
          name="delete-outline"
          size={24}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TEXT_MAIN
          }
        />
        <FooterText
          fontSize={customFont && customFont.fontXS ? customFont.fontXS : 12}
          style={{
            fontFamily:
              customFont && customFont.fontFamilyText
                ? customFont.fontFamilyText
                : THEME.FONTFAMILY.LIGHT,
          }}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TEXT_MAIN
          }
          margin="0.5rem 0rem"
        >
          Excluir Card
        </FooterText>
      </ContentIcon>
    );
  };

  const EditOrderVideos = () => {
    return (
      <ContentIcon
        onPress={onEditOrderClick}
        padding="0.2rem"
        width="30%"
        backgroundColor="rgba(255,255,255,0.1)"
        borderRadius="10px"
      >
        <Octicons
          name="list-ordered"
          size={24}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TEXT_MAIN
          }
        />
        <FooterText
          fontSize={customFont && customFont.fontXS ? customFont.fontXS : 12}
          style={{
            fontFamily:
              customFont && customFont.fontFamilyText
                ? customFont.fontFamilyText
                : THEME.FONTFAMILY.LIGHT,
          }}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TEXT_MAIN
          }
          margin="0.5rem 0rem"
        >
          Alterar Ordem
        </FooterText>
      </ContentIcon>
    );
  };

  return (
    <View>
      <Title
        fontSize={
          customFont && customFont.fontLX
            ? customFont.fontLX
            : THEME.FONTSIZE.BIG
        }
        style={{
          fontFamily:
            customFont && customFont.fontFamilyTitle
              ? customFont.fontFamilyTitle
              : THEME.FONTFAMILY.BOLD,
        }}
        color={
          customColor && customColor.text && customColor.text.app
            ? customColor.text.app
            : THEME.COLORS.TITLE_MAIN
        }
        textAlign="left"
      >
        {video?.title}
      </Title>
      {user?.isAdmin && adminOptions === true ? (
        <Content justifyContent="space-around" margin="1rem 0rem">
          <Edit />
          <EditOrderVideos />
          <DeleteCard />
        </Content>
      ) : (
        <Content margin="1rem 0rem">
          {updatedPdf && updatedPdf.length > 0 && (
            <View style={{ width: '25%' }}>
              <Pdf pdfs={updatedPdf} />
            </View>
          )}
          {video?.url && (
            <View style={{ width: '25%' }}>
              <Live />
            </View>
          )}
          {user?.id && (
            <>
              <View style={{ width: '25%' }}>
                <Favorite />
              </View>
              <View style={{ width: '25%' }}>
                <Watched />
              </View>
            </>
          )}
        </Content>
      )}
      {video?.description?.length > 0 &&
        Array.isArray(video?.description) &&
        video?.description.map((i, index) => (
          <SmallText
            key={index}
            fontSize={
              customFont && customFont.fontSM
                ? customFont.fontSM
                : THEME.FONTSIZE.SMALL
            }
            style={{
              fontFamily:
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.REGULAR,
            }}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
            margin="0rem 0rem 1rem 0rem"
            textAlign="left"
          >
            {i}
          </SmallText>
        ))}
      {video?.description?.length > 0 && !Array.isArray(video?.description) && (
        <SmallText
          fontSize={
            customFont && customFont.fontSM
              ? customFont.fontSM
              : THEME.FONTSIZE.SMALL
          }
          style={{
            fontFamily:
              customFont && customFont.fontFamilyText
                ? customFont.fontFamilyText
                : THEME.FONTFAMILY.REGULAR,
          }}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TEXT_MAIN
          }
          margin="0rem 0rem 1rem 0rem"
          textAlign="left"
        >
          {video?.description}
        </SmallText>
      )}
    </View>
  );
};

export default ListHeader;
