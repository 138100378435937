import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import Slider from '../../Slider';
import { PricingCard } from "react-native-elements";
import { AlertBox } from '../../AlertBox';
import THEME from '../../../config/theme';
import useViewport from '../../../hooks/useViewport';
import { useNavigation, useIsFocused } from "@react-navigation/native";
import MultipleTexts from './MultipleTexts';
import { Provider } from "react-native-paper";
import { StandardText } from '../../../config/theme/globalStyles';

const PlansCards = ({ user, firstPlanPurchase, plansInfo, component, planFrequency, isAdmin, plan }) => {
  
  const { width } = useViewport();
  const breakpoint = 1080;

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [title, setTitle] = useState(null);
  const [message, setMessege] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [selectedCard, setSelectedCard] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigation();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (planFrequency) {
      setSelectedId(planFrequency[0]?.id)
    }
  }, [planFrequency]);


  useEffect(() => {
    return navigation.addListener("beforeRemove", () => {
      setIsLoading(false);
    }), navigation.addListener("focus", () => {
      setIsLoading(false);
    });
  }, [navigation, isFocused]);

  const showAlert = (title, message) => {
    setVisibleAlert(true);
    setTitle(title);
    setMessege(message);
  };

  const hideAlert = (status) => {
    setVisibleAlert(status);
  };

  const onSubscriptionButtonPress = async (productId, billing_address_collection) => {
    if (user?.plan) {
      showAlert("Ops!", "Você já possui um plano de assinatura! Para adquirir um novo plano, você precisa cancelar o que já possui ou criar um novo login.");
      setIsLoading(false);
    } else if (plan) {
      showAlert("Ops!", "Você já possui um plano de assinatura! Para adquirir um novo plano, você precisa cancelar o que já possui ou criar um novo login.");
      setIsLoading(false)
    } else {
      const purchaseType = "PLAN";
      navigation.navigate("Cadastro", { purchaseType, productId, mode: "subscription", billing_address_collection });
    }
  };

  const Item = ({ item, onPress, backgroundColor, color }) => (
    <View style={{
      borderRadius: "5px",
      paddingHorizontal: "1rem",
      paddingVertical: "0.4rem",
      margin: "0.3rem",
      justifyContent: "space-around",
      backgroundColor: backgroundColor
    }}>
      <TouchableOpacity onPress={onPress}>
        <Text style={{
          fontSize: width < breakpoint ? 15 : 20,
          fontFamily: THEME.FONTFAMILY.TEXTS,
          textAlign: 'center',
          maxWidth: 'auto',
          color
        }}>{item.frequency}</Text>
      </TouchableOpacity>
    </View>
  );

  const renderItem = ({ item }) => {
    const backgroundColor =
      item.id === selectedId
        ? THEME.COLORS.S5_BACKGROUND_SELECTED
        : THEME.COLORS.S5_BACKGROUND_NO_SELECTED;
    const color =
      item.id === selectedId
        ? THEME.COLORS.S5_TEXT_SELECTED
        : THEME.COLORS.S5_TEXT_NO_SELECTED;
    return (
      <View style={{ paddingBottom: "1rem" }}>
        <Item
          item={item}
          onPress={() => setSelectedId(item.id)}
          backgroundColor={backgroundColor}
          color={color}
        />
      </View>
    );
  };

  const PlanCard = ({ item, index }) => {

    const content = [
      {
        text: selectedId == 1 ? item.monthlyPrice : selectedId == 2 ? item.quarterlyPrice : selectedId == 3 ? item.semiannualPrice : item.yearlyPrice,
        style: (width) => ({ ...component.pricingStyle(width, index) })
      },
      {
        text: selectedId == 1 ? '/ por mês' : selectedId == 2 && item?.invertValue ? '/ por mês, durante 3 meses' : selectedId == 2 ? '/ trimestre' : selectedId == 3 && item?.invertValue ? '/ por mês, durante 6 meses' : selectedId == 3 ? '/ semestre' : selectedId == 4 && item?.invertValue ? '/ por mês, durante 12 meses' : '/ por ano',
        style: (width) => ({ ...component.pricingStyle(width, index), fontSize: width < breakpoint ? 14 : 14 })
      },
    ]

    return (
      <>
        {item.isBestPrice && (
          <View style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: component?.bestOptionColor,
            borderRadius: "50%",
            padding: 5,
            zIndex: 2,
            width: 75,
            height: 75,
            justifyContent: "center",
            borderColor: "#FFFFFF",
            borderWidth: "2px"
          }}>
            <StandardText style={{ color: "#FFFFFF", fontSize: 12 }}>
              MELHOR OPÇÃO
            </StandardText>
          </View>
        )}
        <PricingCard
          key={item.id}
          title={item.title}
          color={component.titleStyle(width, index).color}
          price={
            <View style={{
              width: "100%",
              flexDirection: "row",
            }}>
              <View
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap", // Permite que o texto ocupe duas linhas
                  width: "100%"
                }}
              >
                <MultipleTexts component={component} content={content} width={width}></MultipleTexts>
              </View>
            </View>
          }
          info={selectedId == 1 ? item.infosMonthly : selectedId == 2 ? item.infosQuarterly : selectedId == 3 ? item.infosSemiannual : item.infosYearly}
          titleStyle={
            {
              ...component.titleStyle(width, index), width: "100%"
            }
          }
          infoStyle={
            {
              ...component.infoStyle(width, index),
            }
          }
          pricingStyle={
            {
              ...component.pricingStyle(width, index)
            }
          }
          button={{
            title: item.priceIdHidden == true ? "EM BREVE" : "ASSINAR",
            loading: selectedCard === item.id && isLoading,
            color: component.buttonStyle(width, index).color,
            titleStyle: component.buttonStyle(width, index).titleStyle,
            buttonStyle: component.buttonStyle(width, index).style,
            containerStyle: { marginTop: "auto" },
          }}
          containerStyle={{
            ...component.cardStyle(width, index),
            borderRadius: 15,
            borderWidth: "2px",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: width < breakpoint ? width * 0.8 : width * 0.30,
          }}
          wrapperStyle={{
            width: "100%",
            height: "100%",
            paddingHorizontal: width < breakpoint ? "0rem" : "1rem",
            paddingVertical: "2rem",
          }}
          onButtonPress={() => {
            if (isAdmin || item.priceIdHidden == true) {
              return;
            }
            setSelectedCard(item.id);
            setIsLoading(true);
            (async () => {
              try {
                await onSubscriptionButtonPress(
                  selectedId == 1 ? item.productIds.monthly : selectedId == 2 ? item.productIds.quarterly : selectedId == 3 ? item.productIds.semiannual : item.productIds.yearly,
                  item?.billing_address_collection
                );
                setIsLoading(false);
              } catch (error) {
                // Handle the error, if necessary.
                console.error(error);
                setIsLoading(false);
              }
            })();
          }}
        />
      </>
    )
  }

  const planCards = plansInfo.map((item, index) => (
    <PlanCard item={item} index={index} />
  ));

  return (
    <Provider>
      {planFrequency?.length > 1 && (
        <FlatList
          key={width < breakpoint ? undefined : planFrequency.length}
          data={planFrequency}
          renderItem={renderItem}
          showsVerticalScrollIndicator={false}
          extraData={selectedId}
          numColumns={width < breakpoint ? 2 : planFrequency.length}
          columnWrapperStyle={{ justifyContent: "center" }}
          style={{ flexGrow: 0 }}
        />
      )}
      {width < breakpoint ? (
        <>
          {plansInfo.map((item, index) => (
            <View style={{ flexBasis: "auto", justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '1rem' }}>
              <PlanCard item={item} index={index} />
              {visibleAlert && (
                <AlertBox
                  title={title}
                  message={message}
                  visible={visibleAlert}
                  onClose={hideAlert}
                ></AlertBox>
              )}
            </View>
          ))}
        </>
      ) : width > breakpoint && width - 64 > component.cardStyle(width).width * plansInfo.length ? (
        <View style={{ flex: 1, justifyContent: 'center', flexDirection: 'row', marginBottom: "2rem" }}>
          {plansInfo.map((item, index) => (
            <PlanCard item={item} index={index} />
          ))}
          {visibleAlert && (
            <AlertBox
              title={title}
              message={message}
              visible={visibleAlert}
              onClose={hideAlert}
            ></AlertBox>
          )}
        </View>
      ) : (
        <>
          <Slider array={planCards} cardWidth={component.cardStyle(width).width} component={component} width={width}></Slider>
          {visibleAlert && (
            <AlertBox
              title={title}
              message={message}
              visible={visibleAlert}
              onClose={hideAlert}
            ></AlertBox>
          )}
        </>
      )
      }
    </Provider>
  )
}

export default PlansCards