import styled from "styled-components/native";

export const Container = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    flexDirection: "column",
    flexBasis: "auto",
    height: "auto",
    backgroundColor: "#d3d3d3"
  },
})``;

export const Main = styled.View`
  margin: 0 1.25rem 1.25rem 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
`;

export const Heading = styled.Text`
  text-align: start;
  margin: 0;
  padding: 0;
  color: black;
`;

export const Description = styled.Text`
  font-family: ${(props) => props.theme.FONTFAMILY.SUBTITLE};
  font-weight: 500;
  color: #14181d;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
`;

export const FormContainer = styled.View`
  padding: 1.25rem;
  height: auto;
  background: #fff;
  border-radius: 0.5rem;
  width: "-webkit-fill-available",
  maxWidth: "35rem",
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
`;

export const ItemForm = styled.View`
  flex-direction: row;
  align-items: center;
  height: 70px;
  justify-content: space-between;
  padding: 0.5rem 0 0.5rem 0;
  border-color: #2e2e2e;
  border-bottom-width: 1px;
  margin-bottom: 0.5rem;
`;

export const TitleItem = styled.Text`
  font-size: ${(props) => props.theme.FONTSIZE.SMALL}px;
  font-family: ${(props) => props.theme.FONTFAMILY.MEDIUM};
`;

export const ButtonCustomLoginPage = styled.View`
  flex-direction: row;
  align-items: center;
  height: 70px;
  padding: 0.5rem 0 0.5rem 0;
  cursor: pointer;
  border-color: #2e2e2e;
  border-bottom-width: 1px;
  margin-bottom: 0.5rem;
`;

export const TextAndTooltip = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const BoxUploadFont = styled.View`
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  flex-direction: row;
`;
