import styled from 'styled-components/native';

export const ViewText = styled.View`
  width: 80%;
`;

export const Container = styled.View`
  flex: 1;
  background-color: white;
`;

export const ViewHeading = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

export const ContentIcon = styled.View`
  align-items: center;
  margin: 0rem 1rem;
`;

export const VerticalScroll = styled.ScrollView.attrs({
    showsVerticalScrollIndicator: true,
    contentContainerStyle: {
      flexDirection: "column",
      flexGrow: 1,
    },
  })`  
  `;

export const ViewPressable = styled.View`
width: 10%;
justify-content:center;
`;
