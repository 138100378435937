import React, { useState, useEffect } from 'react';
import { HeaderDesktop, Avatar, Content } from './style';
import { Icon } from 'react-native-elements';
import THEME from '../../config/theme';
import { HeaderContainer, FooterText } from '../../config/theme/globalStyles';
import { auth } from '../../services/firebase';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { aspectRatioLogoAbout, hasOutsideAboutPage } from '../../config/data';
import ViewPortProvider from '../../hooks/ViewPortProvider';
import useViewport from '../../hooks/useViewport';
import { View, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import LogoAbout from '../../../assets/LogoAbout.png';

const firstStep = 'Escolher compra';
const secondStep = 'Cadastrar';
const thirdStep = 'Completar pagamento';

const HeaderPurchase = ({ signUp }) => {
  const [login, setLogin] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogin(true);
      }
    });
  }, []);

  const MobileOrDesktopComponent = () => {
    const { width } = useViewport();
    const breakpoint = 1080;
    return width < breakpoint ? (
      <HeaderContainer flexDirection="column">
        <TouchableOpacity
          style={{ alignItems: 'center', height: '3rem', padding: '0.2rem' }}
          onPress={() => {
            hasOutsideAboutPage ? {} : navigation.navigate('Inicio');
          }}
        >
          <Avatar
            resizeMode="contain"
            source={LogoAbout}
            width={`${aspectRatioLogoAbout * 48}px`}
          />
        </TouchableOpacity>
        <Content>
          {signUp ? (
            <View style={{ flex: 1 }}>
              <FooterText color={THEME.COLORS.ICON_HEADER} numberOfLines={2}>
                {firstStep}
              </FooterText>
            </View>
          ) : (
            <View style={{ flex: 1 }}>
              <FooterText
                fontFamily={THEME.FONTFAMILY.MEDIUM}
                color={THEME.COLORS.PRIMARY_900}
                numberOfLines={2}
              >
                {firstStep}
              </FooterText>
            </View>
          )}
          <Icon
            type="material-community"
            name="chevron-right"
            size={THEME.FONTSIZE.SMALL}
            iconStyle={{
              color: THEME.COLORS.ICON_HEADER_PURCHASE,
            }}
          />
          {login ? (
            <View style={{ flex: 1 }}>
              <FooterText color={THEME.COLORS.ICON_HEADER} numberOfLines={2}>
                {thirdStep}
              </FooterText>
            </View>
          ) : signUp ? (
            <>
              <View style={{ flex: 1 }}>
                <FooterText
                  fontFamily={THEME.FONTFAMILY.MEDIUM}
                  color={THEME.COLORS.PRIMARY_900}
                  numberOfLines={2}
                >
                  {secondStep}
                </FooterText>
              </View>
              <Icon
                type="material-community"
                name="chevron-right"
                size={THEME.FONTSIZE.SMALL}
                iconStyle={{
                  color: THEME.COLORS.ICON_HEADER_PURCHASE,
                }}
              />
              <View style={{ flex: 1 }}>
                <FooterText color={THEME.COLORS.ICON_HEADER} numberOfLines={2}>
                  {thirdStep}
                </FooterText>
              </View>
            </>
          ) : (
            <>
              <View style={{ flex: 1 }}>
                <FooterText color={THEME.COLORS.ICON_HEADER} numberOfLines={2}>
                  {secondStep}
                </FooterText>
              </View>
              <Icon
                type="material-community"
                name="chevron-right"
                size={THEME.FONTSIZE.SMALL}
                iconStyle={{
                  color: THEME.COLORS.ICON_HEADER_PURCHASE,
                }}
              />
              <View style={{ flex: 1 }}>
                <FooterText color={THEME.COLORS.ICON_HEADER} numberOfLines={2}>
                  {thirdStep}
                </FooterText>
              </View>
            </>
          )}
        </Content>
      </HeaderContainer>
    ) : (
      <HeaderContainer>
        <HeaderDesktop style={{ alignItems: 'center' }}>
          <TouchableOpacity
            style={{ height: '3rem' }}
            onPress={() => {
              hasOutsideAboutPage ? {} : navigation.navigate('Inicio');
            }}
          >
            <Avatar
              width={`${aspectRatioLogoAbout * 48}px`}
              marginRight="1rem"
              resizeMode="contain"
              source={LogoAbout}
            />
          </TouchableOpacity>
          {signUp ? (
            <FooterText color={THEME.COLORS.ICON_HEADER}>
              {firstStep}
            </FooterText>
          ) : (
            <FooterText
              fontFamily={THEME.FONTFAMILY.MEDIUM}
              color={THEME.COLORS.PRIMARY_900}
            >
              {firstStep}
            </FooterText>
          )}
          <Icon
            type="material-community"
            name="chevron-right"
            size={THEME.FONTSIZE.SMALL}
            iconStyle={{
              color: THEME.COLORS.ICON_HEADER_PURCHASE,
            }}
          />
          {login ? (
            <FooterText color={THEME.COLORS.ICON_HEADER}>
              {thirdStep}
            </FooterText>
          ) : signUp ? (
            <>
              <FooterText
                fontFamily={THEME.FONTFAMILY.MEDIUM}
                color={THEME.COLORS.PRIMARY_900}
              >
                {secondStep}
              </FooterText>
              <Icon
                type="material-community"
                name="chevron-right"
                size={THEME.FONTSIZE.SMALL}
                iconStyle={{
                  color: THEME.COLORS.ICON_HEADER_PURCHASE,
                }}
              />
              <FooterText color={THEME.COLORS.ICON_HEADER}>
                {thirdStep}
              </FooterText>
            </>
          ) : (
            <>
              <FooterText color={THEME.COLORS.ICON_HEADER}>
                {secondStep}
              </FooterText>
              <Icon
                type="material-community"
                name="chevron-right"
                size={THEME.FONTSIZE.SMALL}
                iconStyle={{
                  color: THEME.COLORS.ICON_HEADER_PURCHASE,
                }}
              />
              <FooterText color={THEME.COLORS.ICON_HEADER}>
                {thirdStep}
              </FooterText>
            </>
          )}
        </HeaderDesktop>
      </HeaderContainer>
    );
  };

  return (
    <ViewPortProvider>
      <MobileOrDesktopComponent />
    </ViewPortProvider>
  );
};

export default HeaderPurchase;
