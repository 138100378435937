import React, { useEffect, useState } from "react";
import HeaderPurchase from '../../components/HeaderPurchase';
import Footer from "../../components/Footer";
import { View, SafeAreaView } from "react-native";
import THEME from "../../config/theme";
import { firestore } from "../../services/firebase/index";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { clientConfig } from '../../config/theme/sectionsStyles';
import ViewPortProvider from "../../hooks/ViewPortProvider";
import PageSection from "../../components/Section";
import Constants from "expo-constants";
import { ActivityIndicator } from "react-native-paper";

const { projectId } = Constants.manifest.extra;


export function Plans({ route }) {
  const { userId, plan , firstPlanPurchase, isAdmin } = route.params || {};

  const [plansInfo, setPlansInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [planFrequency, setPlanFrequency] = useState([]);

  const getPlans = async () => {
    const response = await getDocs(query(collection(firestore, "plans"), orderBy("order", "asc")));
    let plans = [];
    let frequency = [];
    response.forEach((doc) => {
      if (doc.data().isPlanFrequency == false && doc.data().archived == false) {
        plans.push({ id: doc.id, ...doc.data() });
      } else if (doc.data().isPlanFrequency == true) {
        frequency.push(doc.data().planFrequency);
      }
    });
    setPlansInfo(plans);
    setPlanFrequency(frequency[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    getPlans();
  }, []);

  const client = clientConfig.find((client) => client.name === projectId);
  const pageName = client?.pages.find((page) => page.name === "plans");

  if (isLoading) {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
          justifyContent: "center",
        }}
        color={THEME.COLORS.PRIMARY_800}
      />
    );
  }

  return (
    <SafeAreaView style={{ backgroundColor: THEME.COLORS.S5_BACKGROUND, flexGrow: 1 }}>
      <HeaderPurchase background={THEME.COLORS.BACKGROUND_HEADER} signUp={false} />
      <ViewPortProvider>
        <View style={{ justifyContent: "space-between", flex: 1 }}>
          {pageName?.sections.map((section, index) => (
            <>
              <PageSection
                key={`${projectId}-section-${index}`}
                client={client}
                pageName={pageName}
                sectionIndex={index}
                userId={userId}
                isAdmin={isAdmin}
                firstPlanPurchase={firstPlanPurchase}
                plan={plan}
                planFrequency={planFrequency}
                plansInfo={plansInfo}
              />
            </>
          ))}
          <Footer></Footer>
        </View>
      </ViewPortProvider>
    </SafeAreaView>
  );
}