import * as Linking from 'expo-linking';

const linking = {
    prefixes: [Linking.createURL("/")]
  };
  
  export default linking;


  
  