export const privacyData = [
    {
        // subtitle: "1. PESSOAS ENVOLVIDAS",
        paragraphs: [
            {
                text: "Os presentes Termos e Condições de Uso relacionam-se aos serviços oferecidos por BLIX SOLUÇÕES EM TECNOLOGIA, inscrita no CNPJ/MF sob o n° 43.589.960/0001-41, situado endereço Rua Jari, 359, ap. 301, Bloco D, 2º Andar, Bairro Passo D’Areia, Porto Alegre/RS, para utilização de plataformas e APP customizável ao USUÁRIO, voltados para criadores de conteúdos que promovam o bem-estar.",
            },
            {
                text: "Trata-se de um acordo legal entre o USUÁRIO e a BLIX, que vincula as partes e tem, para todos os fins, força de contrato. Assim, nos termos aqui delineados, estabelecem-se os direitos e deveres das partes contratante e contratada, que devem ser observados antes, durante e após a relação comercial.",
            },
            {
                text: "Para que possa utilizar quaisquer dos serviços disponibilizados, o USUÁRIO declara, expressamente, ter lido, compreendido e anuído com todos os termos e condições que serão adiante estabelecidas.",
            },
            {
                text: "É importante considerar, também, que o conteúdo dos presentes termos pode ser modificado periodicamente, visando ao aperfeiçoamento do serviço. Caberá, assim, ao USUÁRIO, revisar as políticas de forma regular, as quais serão, em todos os casos, sempre publicadas no painel administrativo.",
            },
        ],
    },
    {
        subtitle: "1. PESSOAS ENVOLVIDAS",
        paragraphs: [
            {
                text: "1.1 A relação comercial e de prestação de serviço tratada nestes Termos de Uso envolverá as seguintes Pessoas/Partes:",
            },
            {
                text: `a. BLIX SOLUÇÕES EM TECNOLOGIA: É a empresa licenciadora e fornecedora que, utilizando-se de Software Próprio, prestará os serviços de disponibilização e gestão de plataforma customizável ao USUÁRIO. A partir deste momento, será denominada, simplesmente, "BLIX".`,
            },
            {
                text: `b. "USUÁRIOS DOS SERVIÇOS”: São as pessoas (físicas ou jurídicas), instrutores, professores, treinadores ou proprietários/gestores de material educacional, responsáveis pela criação, gestão e comercialização de conteúdo digital, como aulas gravadas, aulas ao vivo ou cursos, e que figurarão como Usuários/Clientes da BLIX. A partir deste momento, serão denominados, simplesmente, "USUÁRIOS".`,
            },
            {
                text: `c. “ALUNOS DOS CURSOS/PRODUTOS DIGITAIS/PLANOS DE ASSINATURA OFERECIDOS PELOS USUÁRIOS”: São pessoas físicas ou jurídicas que utilizarão e se beneficiarão dos cursos/produtos digitais e/ou planos de assinatura criados, geridos e comercializados pelos "USUÁRIOS". Tratam-se dos consumidores finais dos cursos/produtos digitais e/ou planos de assinatura, que manterão relação exclusiva com os USUÁRIOS e, não, com a BLIX. A partir deste momento, serão denominados, simplesmente, "ALUNOS".`,
            },
            {
                text: "1.2. O USUÁRIO e a BLIX declaram serem independentes entre si, sem nenhuma relação societária ou comercial além da pactuada nestes Termos e Condições.",
            },
        ],
    },
    {
        subtitle: "2. OUTRAS DEFINIÇÕES:",
        paragraphs: [
            {
                text: `a. TERMOS DE USO: é o presente contrato eletrônico, que objetiva limitar a responsabilidade, direcionar a forma de prestação e utilização do serviço, bem como esclarecer, ao máximo, as possíveis dúvidas relacionadas ao seu funcionamento.`,
            },
            {
                text: "b. POLÍTICAS DE PRIVACIDADE: é um conjunto de termos que descreve e estabelece as práticas adotadas pela prestadora do serviço em relação às informações e dados dos USUÁRIOS.",
            },
            {
                text: "c. PLANOS: Envolvem as diferentes opções de serviços oferecidos para uso da plataforma, com maior ou menor abrangência e acesso a funcionalidades diversas. Cada plano, que deve ser selecionado pelo USUÁRIO previamente à contratação, descreve o serviço específico e os valores de custo, a depender das funcionalidades desejadas. Todos os Planos, independentemente do valor, abrangência ou formato, estão sujeitos a estes Termos de Uso e podem ser consultados pelo site: https://blix.digital/produtos.",
            },
        ],
    },
    {
        subtitle:
            "3. OBJETO (ESCOPO DO SERVIÇO)",
        paragraphs: [
            {
                text: `3.1. A PLATAFORMA oferecida pela BLIX a USUÁRIOS previamente cadastrados se trata de utilidade tecnológica, (APP mobile e web site), por meio da qual o USUÁRIO, cliente da BLIX, poderá personalizar e gerenciar ambiente virtual customizado e plataforma de mídia on-line, voltados à prestação de serviço de viabilização de distribuição de conteúdo digital por meio de aulas gravadas, aulas online e cursos.`,
            },
            {
                text: `3.2. O serviço oferecido pela BLIX envolve a viabilização de distribuição de conteúdo digital e divulgação/oferta de serviços, tendo como objetivo principal a intermediação de contato e relacionamento comercial entre instrutores/professores (USUÁRIOS) e seus Alunos, para partilha de conhecimento e/ou aulas práticas. Neste sentido, a plataforma possibilita ao USUÁRIO:`,
            },
            {
                text: `a. Customização da interface do APP, de domínio do USUÁRIO;`,
            },
            {
                text: `b. Gerenciamento de clientes, Logins e Cadastros;`,
            },
            {
                text: `c. Fornecimento de aulas ao vivo pela plataforma Zoom;`,
            },
            {
                text: `d. Streaming de vídeo-aulas e compartilhamento de materiais de ensino;`,
            },
            {
                text: `e. Gerenciamento de biblioteca de materiais (vídeos, áudios, pdfs e redirecionamento de links);`,
            },
            {
                text: `f. Venda de cursos com videoaulas e materiais específicos e planos de assinatura;`,
            },
            {
                text: `g. Aceite de pagamentos nacionais e internacionais com Stripe; Configuração de planos de assinaturas e/ou cursos online e programas específicos;`,
            },
            {
                text: `3.3. A abrangência dos serviços e funcionalidades contratadas dependerá do Plano escolhido pelo USUÁRIO, que será previamente esclarecido quanto às suas informações de característica, qualidade, quantidade, preço e prazo de utilização.`,
            },
            {
                text: `3.4. Os USUÁRIOS que oferecem seus serviços na plataforma operada pela BLIX são exclusiva e inteiramente responsáveis pelo conteúdo publicado em sua plataforma personalizada de ensino, não havendo relação direta entre a desenvolvedora BLIX e os Alunos/consumidores dos serviços oferecidos pelos USUÁRIOS.`,
            },
            {
                text: `3.5 O número máximo de horas de armazenamento de conteúdos digitais e largura de banda incluída referente a esses conteúdos é de acordo com o plano contratado. Caso extrapolado esse valor, serão cobrados valores extras, conforme o uso.`,
            },
        ],
    },
    {
        subtitle: "4. CONTRATAÇÃO, PREÇOS, FORMAS DE PAGAMENTO E CONSEQUÊNCIAS DA INADIMPLÊNCIA",
        paragraphs: [
            {
                text: `4.1. A contratação será realizada após escolha do Plano, com anuência automática aos presentes Termos de Uso. Antes de finalizar a compra, o USUÁRIO deve se informar a respeito da abrangência do Plano escolhido, das especificações do serviço e sua destinação.`,
            },
            {
                text: `4.2. A BLIX se resguarda quanto ao direito de estabelecer, ajustar ou reajustar, unilateralmente e a qualquer tempo, os valores dos serviços, que serão sempre aqueles vigentes no momento do pedido ou da renovação do Plano contratado.`,
            },
            {
                text: `4.3. O preço do serviço envolve, de forma cumulativa:`,
            },
            {
                text: `4.3.1. A mensalidade da BLIX, que deverá ser paga até o quinto dia útil do mês. Essa mensalidade é referente ao mês que vai ser utilizado.`,
            },
            {
                text: `4.3.2. A taxa retroativa por USUÁRIO assinante/mês + a taxa por curso ou produto vendido/mês.`,
            },
            {
                text: `4.3.3. As taxas retroativas de armazenamento de vídeos/áudios e material didático em geral + Largura de banda utilizada, quando realizado pelo Aplicativo. Essas taxas serão repassadas, pela BLIX, de acordo com o uso do Cloud Storage no projeto do Usuário criado na Firebase.`,
            },
            {
                text: "4.4. A cobrança das taxas será realizada pela inserção de usuários na plataforma, ou seja, independentemente da forma de ingresso, quer seja por pagamento, adesão, desconto, bonificação, compra, ingresso e assinatura. Para que não restem dúvidas a respeito da cobrança dessa taxa, esclarece-se, sem taxatividade e de forma exemplificativa:",
            },
            {
                text: "a. Ingresso por convite temporário ou permanente, cupons de desconto, migração de alunos de outras plataformas para a plataforma da Blix.",
            },
            {
                text: "4.5. O pagamento será realizado pelo USUÁRIO por meio de link ou boleto disponibilizado pela BLIX.",
            },
            {
                text: "4.6. O atraso no pagamento de qualquer obrigação prevista neste Termos de Uso sujeitará os valores devidos e não pagos à incidência de juros no valor de 1% (um por cento) ao mês e multa de 2% (dois por cento) aplicado desde a data do inadimplemento até o efetivo pagamento dos referidos valores.",
            },
            {
                text: "4.7. Ao contratar um dos Planos, o USUÁRIO não escolherá por meio da Plataforma a data de vencimento da sua mensalidade para fins de emissão do boleto bancário ou cartão de crédito. O pagamento da mensalidade/anuidade garante ao USUÁRIO a disponibilidade de uso da Plataforma independentemente da sua efetiva utilização.",
            },
            {
                text: "4.8. O não pagamento da primeira parcela ajustada será entendido como cancelamento automático da contratação e restabelecerá as partes ao seu status original.",
            },
            {
                text: "4.9. O não pagamento de parcelas previamente ajustadas, após a contratação, constituirá o inadimplente, imediatamente em mora, sem necessidade de notificação prévia e acarretará no cancelamento imediato do serviço.",
            },
            {
                text: "4.10. A BLIX se resguarda, em caso de inadimplemento pelo USUÁRIO, ao direito de suspender, interromper, cancelar ou inviabilizar, de qualquer forma, a utilização do serviço.",
            },
            {
                text: "4.11. Os valores inadimplidos serão atualizados, até seu pagamento mediante correção monetária e incidência de juros legais.",
            },
        ],
    },
    {
        subtitle: "5. UTILIZAÇÃO DA PLATAFORMA E SUPORTE",
        paragraphs: [
            {
                text: "5.1. Todo USUÁRIO que queira contratar e utilizar os Serviços deve ler previamente e aceitar todas as condições estabelecidas nestes Termos e Condições e qualquer pessoa que não aceite estes Termos e Condições, os quais detêm caráter obrigatório e vinculante, deverá se abster quanto à contratação e utilização dos Serviços.",
            },
            {
                text: "5.2. A BLIX outorga ao USUÁRIO o uso limitado, pessoal, revogável, não exclusivo e intransferível do Aplicativo e Web Service, unicamente para o objetivo de prestação de serviço de viabilização de distribuição de conteúdo digital por meio de aulas gravadas, aulas online e cursos, pelo USUÁRIO perante seus ALUNOS.",
            },
            {
                text: "5.3. A BLIX não reivindica a propriedade do material ou arquivos que o USUÁRIO disponibilizará para realização das aulas. No entanto, ao postar, carregar, inserir, fornecer ou enviar qualquer arquivo, o USUÁRIO concede à BLIX permissão para utilizá-los de acordos com o escopo e objetivos traçados nos presentes Termos de Uso, incluindo, sem limitação, a direitos de: copiar, distribuir, transmitir, exibir publicamente, executar publicamente, reproduzir, editar, traduzir e reformatar e publicar o material em conexão com o nome do USUÁRIO.",
            },
            {
                text: "5.4. Nenhuma compensação será paga com relação ao material disponibilizado pelo USUÁRIO.",
            },
            {
                text: "5.5. A BLIX se reserva o direito de recusar solicitações, cancelar ou suspender, com ou sem causa, de maneira temporária ou definitiva, a prestação de Serviços, sem que tal decisão ofereça ao USUÁRIO qualquer direito à indenização ou ressarcimento.",
            },
            {
                text: "5.6. A BLIX se reserva ao direito e exclusivo critério de decidir se as informações inseridas ou utilizadas pelos USUÁRIOS são apropriadas e estão em conformidade com os Termos de Uso, o escopo do serviço, as políticas internas e as leis e regulamentações aplicáveis.",
            },
            {
                text: "5.7. O USUÁRIO é inteiramente responsável pelo conteúdo disponibilizado e declara, expressamente, que detém os direitos autorais, intelectuais, de produção ou imagem do conteúdo a ser disponibilizado.",
            },
            {
                text: "5.8. A BLIX não será, de qualquer forma, responsabilizada pelo uso indevido de material ou qualquer violação de direito de terceiros ou dever confidencial causado pela disponibilização indevida realizada pelo USUÁRIO.",
            },
            {
                text: "5.9. A BLIX não será responsável por perda financeira, de lucros, de receita, de dados ou por danos indiretos, consequenciais, exemplares ou punitivos. A responsabilidade será limitada, eventualmente, ao valor pago pelo USUÁRIO pelo uso do serviço.",
            },
        ],
    },
    {
        subtitle: "5.10. PROVIDÊNCIAS E AUTORIZAÇÕES NECESSÁRIAS",
        paragraphs: [
            {
                text: "5.10.1. As lojas de aplicativos cobram uma comissão que deverá ser paga pelo USUÁRIO que desejar publicar o APP nas lojas. A BLIX não garante que o seu aplicativo seja aceito nas lojas de aplicativos.",
            },
            {
                text: "5.10.2. Se a qualquer momento o USUÁRIO optar por remover seu aplicativo de qualquer mercado, exigimos um período de aviso prévio de 30 (trinta) dias antes que a remoção possa ocorrer.",
            },
            {
                text: "5.10.3 Ao contratar o serviço e aderir aos termos de uso, o USUÁRIO concede à BLIX o direito de usar o nome ou marca como cliente da Empresa em seu site www.blix.digital e canais de mídia social como Facebook, Instagram e LinkedIn e reutilizar quaisquer imagens e vídeos tornados públicos pelo USUÁRIO nos canais sociais e site de propriedade da BLIX.",
            },
            {
                text: "5.10.4. Na conta Google da BLIX, será criado um projeto para cada USUÁRIO na Firebase, ferramenta utilizada exclusivamente pela BLIX para viabilização do produto/serviço.",
            },
            {
                text: "5.10.5. O USUÁRIO deverá criar uma conta na Stripe, cujo acesso será necessário por parte da BLIX para fazer a integração com o APP. A integração será feita com a conta criada no momento da contratação e qualquer modificação futura estará sujeita a realização de orçamento. Após o lançamento do aplicativo, o USUÁRIO se responsabiliza por alterar a senha e a BLIX não terá mais acesso ao Stripe do USUÁRIO. Todas as taxas cobradas pela Stripe.com são taxas da Stripe e não estão relacionadas às taxas da BLIX. O USUÁRIO poderá verificar todas as taxas relacionadas ao Stripe em www.stripe.com.",
            },
        ],
    },
    {
        subtitle: "5.11. CADASTRO DOS USUÁRIOS",
        paragraphs: [
            {
                text: "5.11.1. No ato da contratação da plataforma, o USUÁRIO deverá informar os seguintes dados: Nome completo ou Nome da Empresa, CPF ou CNPJ,  Telefone de contato, E-mail, CEP Endereço completo.",
            },
            {
                text: "5.11.2. O USUÁRIO deverá manter seu registro de dados completo e atualizado, comprometendo-se, integralmente, em relação às informações prestadas.",
            },
            {
                text: "5.11.3. Mediante a realização da contratação, o USUÁRIO concorda e oferece total consentimento de tratamento de seus dados pessoais para a BLIX, dentro do escopo e objetivos estabelecidos nos presentes Termos de Uso. Da mesma forma, declara e garante expressamente ser plenamente capaz e ciente quanto aos termos contratados.",
            },
        ],
    },
    {
        subtitle: "5.12. SUPORTE",
        paragraphs: [
            {
                text: "5.12.1. A BLIX é responsável por fornecer manutenção e suporte apenas para a plataforma BLIX utilizada. Caso o USUÁRIO pretenda um estudo de caso ou consultoria que vá além do escopo de trabalho previsto em contrato, poderá contratá-lo juntamente à BLIX ou perante terceiros.",
            },
            {
                text: "5.12.2. A orientação buscada perante terceiro é objeto de contrato entre USUÁRIO e terceiro e a BLIX não se responsabiliza por orientações não fornecidas através de sua consultoria e dessa forma o USUÁRIO concorda em utilizar os serviços de terceiros por sua conta e risco.",
            },
            {
                text: "5.12.3. A BLIX não é responsável por examinar ou avaliar o conteúdo ou exatidão de quaisquer informações ou serviços de terceiros e não será responsável por qualquer desses serviços ou informações.",
            },
            {
                text: "5.12.4. O suporte e o atendimento ao USUÁRIO em caso de dúvida, reclamações ou sugestões são realizados pela BLIX através do atendimento por e-mail ou whatsapp em horário comercial de segunda a sexta das 8h ao 12h e de 13h às 17h.",
            },
        ],
    },
    {
        subtitle: "6. RESPONSABILIDADES DE UTILIZAÇÃO (Responsabilidades do Usuário e isenções de responsabilidade da BLIX)",
        paragraphs: [
            {
                text: "6.1. Qualquer USUÁRIO que utilize a plataforma compromete-se a publicar apenas informações, conteúdos, fotografias e vídeos que lhe pertencem, e sobre os quais detenha direitos exclusivos de autor e imagem. As inclusões não podem conter informações errôneas, ilícitas ou que possam induzir a BLIX ou o Aluno em erro.",
            },
            {
                text: "6.2. É responsabilidade exclusiva do USUÁRIO a relação estabelecida com seus Alunos.",
            },
            {
                text: "6.3. Os Alunos não podem exigir prestações e os USUÁRIOS não podem realizar serviços diversos do escopo da plataforma. O desvirtuamento de utilização deve ser informado, por quem quer tenha conhecimento, de forma imediata.",
            },
            {
                text: "6.4. A BLIX não será responsabilizada por qualquer problema ocorrido durante a execução da prestação dos serviços pelo USUÁRIO ou, ainda, por informações incompletas, por dados insuficientes ou por problemas no fornecimento de materiais ou suporte necessários à prestação do serviço e/ou das aulas.",
            },
            {
                text: "6.5. A BLIX é desenvolvedora que oferece apenas a plataforma que será disponibilizada, de forma personalizada, pelo USUÁRIO a seus Alunos sendo a sua responsabilidade restrita ao funcionamento desta plataforma e às suas funcionalidades.",
            },
            {
                text: "6.6. Para que não restem dúvidas a respeito da limitação da responsabilidade, esclarece-se, sem taxatividade e de forma exemplificativa:",
            },
            {
                text: "a. As negociações, estabelecimento de preços de serviços e abrangência são realizadas exclusivamente entre USUÁRIOS e seus respectivos Alunos.",
            },
            {
                text: "b. A BLIX não realiza nem se obriga à verificação se o USUÁRIO detém a capacidade técnica para realização do serviço ou aquela eventualmente informada ao Aluno.",
            },
            {
                text: "c. A BLIX não garante nem se responsabiliza quanto à qualidade da prestação dos serviços prestados pelos USUÁRIOS aos Alunos, do material divulgado ou problemas oriundos de demora ou de má prestação de serviços pelos USUÁRIOS.",
            },
            {
                text: "d. O pagamento ajustado entre USUÁRIOS e Alunos ou as obrigações decorrentes da prestação de serviço pelo USUÁRIO não atingem, de forma alguma, a BLIX, que não se obriga em relação a qualquer uma das partes ou qualquer forma de inadimplência.",
            },
            {
                text: "e. A BLIX não será, em qualquer hipótese, responsável pelo relacionamento comercial ou de prestação de serviços mantidos entre USUÁRIOS e Alunos. Apenas o USUÁRIO e nunca a BLIX será responsável por cumprir as obrigações inerentes à relação comercial mantida com seus clientes (Alunos), incluindo sem limitação, todos os danos, prejuízos, acidentes e ocorrências na prestação dos serviços.",
            },
            {
                text: "f. A BLIX não é prestadora dos serviços de aulas online ou responsável por disponibilizar qualquer conteúdo didático. Tais serviços são prestados pelos USUÁRIOS de forma independente e autônoma.",
            },
            {
                text: "g. Os USUÁRIOS reconhecem, expressamente, que não são empregados, associados, subcontratados ou mesmo representantes da BLIX, isentando-a, desde já, por qualquer responsabilidade nesse sentido. Nada na relação tem a intenção de criar ou será interpretado como a criação de joint ventures, parceria, ou intermediação de relacionamento principal e secundário entre o USUÁRIO e seus Clientes/Alunos.",
            },
            {
                text: "h. A BLIX não é fiadora, seguradora ou garantidora de qualquer espécie na relação estabelecida entre os USUÁRIOS e Alunos.",
            },
            {
                text: "i. O não funcionamento da plataforma ou do APP não será motivo para que o USUÁRIO se exima de cumprir as obrigações assumidas com o Aluno, tampouco transfere à BLIX qualquer responsabilidade dessa natureza.",
            },
            {
                text: "6.7. É obrigação do USUÁRIO assegurar que seu equipamento pessoal é compatível com as características técnicas que viabilizam a utilização da plataforma.",
            },
            {
                text: "6.8. A adição de qualquer ferramenta de coleta e análise de dados (Exemplo: gerenciador de tags do Google, Facebook Pixel) poderá ser adicionada pela Blix a depender do plano contratado, e ficará sob inteira responsabilidade e gerenciamento exclusivo pelo USUÁRIO.",
            },
        ],
    },
    {
        subtitle: "7. RESPONSABILIDADES DE FORNECIMENTO (responsabilidades da BLIX)",
        paragraphs: [
            {
                text: "7.1. A plataforma funcionará normalmente 24 horas por dia e 07 dias por semana. Podem ocorrer pequenas interrupções de forma temporária para ajustes, manutenção, alteração de servidores, falhas operacionais ou em razão de força maior.",
            },
            {
                text: "7.2. A BLIX realizará os melhores esforços e iniciativas para manter a plataforma e o APP em operação sem interrupções, nos termos acima referidos. Não obstante, nenhuma hipótese garante a disponibilidade e continuidade permanente do serviço. Eventuais indisponibilidades ou a descontinuação permanente da plataforma não geram qualquer direito de indenização ao USUÁRIO.",
            },
            {
                text: "7.3. Considerando a existência de variáveis de qualidade, como a velocidade de conexão e download, localização, etc., a BLIX não garante a resolução e qualidade do conteúdo digital durante qualquer transmissão.",
            }, ,
        ],
    },
    {
        subtitle: "8. DIREITOS DE PROPRIEDADE INTELECTUAL, NÃO EXCLUSIVIDADE E DIVULGAÇÃO DO PRODUTO/SERVIÇO",
        paragraphs: [
            {
                text: "8.1. As funcionalidades, incluindo, sem limitação, os conteúdos das telas, bem como os programas, bases de dados, redes, arquivos etc. que permitem ao USUÁRIO acessar, personalizar e utilizar a plataforma e o APP, são propriedade exclusiva da BLIX, que se reserva todos os direitos inerentes.",
            },
            {
                text: "8.2. O USUÁRIO reconhece que a plataforma e o APP, bem como sua organização, estrutura e código-fonte, envolvem segredos comerciais e tecnológicos que pertencem exclusivamente a BLIX. Portanto, exceto pelo uso e licenças expressamente previstas nesses Termos de Uso, o USUÁRIO não poderá, direta ou indiretamente, por si ou por terceiros:",
            },
            {
                text: "a. Alterar, adaptar, traduzir o APP ou a partir dele criar nova tecnologia ou plataforma.",
            },
            {
                text: "b. Distribuir, conceder, franquear ou permitir o uso a terceiros, oferecer em locação / empréstimo, ou transferir de qualquer forma o uso de sua conta ou dados de acesso. A conta é pessoal é intransferível.",
            },
            {
                text: "c. Aplicar ferramentas de engenharia inversa ou reversa, decodificar, descompilar, desmontar, acessar, consultar ou tentar, de qualquer outro modo, decifrar o código-fonte do Aplicativo.",
            },
            {
                text: "8.3. Cada USUÁRIO reconhece e aceita a não exclusividade do serviço oferecido pela BLIX, o qual abrange o universo de pessoas (físicas ou jurídicas) que tenham interesse em oferecer serviços de aulas e cursos. É natural, dessa forma, que os USUÁRIOS sejam concorrentes entre si, não havendo nisso nenhuma implicação à BLIX.",
            },
            {
                text: "8.4. O USUÁRIO autoriza a BLIX, expressamente e de forma não onerosa, a utilizar sua marca, site, interface e App para fins de publicidade e divulgação do serviço prestado pela BLIX. A autorização envolve, exemplificativamente, mas não de forma isolada, a associação de marcas e a divulgação ao mercado consumidor de lista de clientes e sites/Apps desenvolvidos pela BLIX.",
            },
            {
                text: "8.5. Todo conteúdo didático, dados e arquivos inseridos e disponibilizados pelo USUÁRIO por meio da plataforma, bem como os direitos e interesses a eles relacionados, permanecerão em sua exclusiva propriedade intelectual, sem transferência de direitos para a BLIX.",
            },
        ],
    },
    {
        subtitle: "9. REDES SOCIAIS",
        paragraphs: [
            {
                text: "9.1. É permitido aos USUÁRIOS compartilhar links e conteúdo através de redes sociais, como Facebook e Instagram (exemplificativas). Os USUÁRIOS assumem esta opção como sua exclusiva responsabilidade, incluindo, entre outros, o cumprimento de todos os termos e condições dos serviços da respectiva rede social.",
            },
        ],
    },
    {
        subtitle: "10. POLÍTICAS DE PRIVACIDADE",
        paragraphs: [
            {
                text: "10.1. Ao acessar a Plataforma, utilizar os serviços disponibilizados pela BLIX e informar dados, o USUÁRIO expressamente adere à Política de Privacidade e consente e autoriza a BLIX realizar o tratamento de seus dados pessoais para as finalidades determinadas e previstas nos presentes Termos de Uso e Política de Privacidade.",
            },
            {
                text: "10.2. O consentimento do USUÁRIO poderá ser revogado, gratuitamente, a qualquer tempo, devendo, neste caso, o USUÁRIO entrar em contato com a BLIX por meio dos canais usuais de atendimento. A revogação do consentimento do USUÁRIO implicará a suspensão ou o bloqueio de seu acesso à Plataforma e serviços oferecidos, sem ônus ou penalidade para a BLIX. Essa revogação não terá efeito retroativo, sendo ratificados os tratamentos de dados pessoais realizados sob amparo do consentimento anteriormente manifestado pelo USUÁRIO.",
            },
            {
                text: "10.3. A BLIX esclarece que coletará somente os dados pessoais fornecidos diretamente pelo seu titular com fito de contratação do serviço. São coletadas informações de identificação pessoal, tais como nome, sobrenome e e-mail, cartão de crédito (exemplificativamente), fornecidos exclusivamente pelo titular e mantidos na base de dados e tratados de forma sigilosa e dentro do escopo estabelecido nos Termos de Uso.",
            },
            {
                text: "10.4. A BLIX, como controladora, apenas realizará o tratamento de Dados Pessoais quando houver fundamentação legal para fazê-lo. A base legal para o tratamento de Dados Pessoais será uma das seguintes:",
            },
            {
                text: "a. Consentimento do Titular para o tratamento dos seus Dados Pessoais para um ou mais fins específicos, automático na adesão ao serviço, nos termos da Cláusula 7.1 acima.",
            },
            {
                text: "b. Quando o tratamento for necessário para o cumprimento de um contrato.",
            },
            {
                text: "c. Quando o tratamento for necessário para alcançar legítimos interesses ou cumprir obrigação legal.",
            },
            {
                text: "10.5. Os Dados Pessoais serão armazenados pelo tempo que for necessário para as finalidades e escopo definido nos presentes termos de Uso (sendo essa a duração de nosso relacionamento).",
            },
            {
                text: "10.6. A BLIX pode utilizar cookies ou outros dispositivos de identificação de uso do site e da plataforma. Esta informação será mantida, mas nenhuma informação ou dados referentes a ela serão divulgados ou vendidos a terceiros sem a permissão do USUÁRIO.",
            },
            {
                text: "10.7. Poderão ser eliminados dados pessoais a requerimento do titular, dependendo de seu requerimento e da legitimidade da solicitação.",
            },
            {
                text: "10.8. Após encerramento dos serviços, todos os dados, arquivos, documentos e material didático armazenado será eliminado (apagado) em até 30 (trinta) dias.",
            },
            {
                text: "10.9. Cabe ao USUÁRIO manter armazenado em backups ou arquivos de reserva os arquivos pessoais que entender relevantes, isentando a BLIX quanto à eliminação de qualquer informação ou dado, de acordo com o prazo acima previsto.",
            },
        ],
    },
    {
        subtitle: "11. CANCELAMENTO E ENCERRAMENTO",
        paragraphs: [
            {
                text: "11.1. Por livre disposição, a assinatura de qualquer plano poderá ser por tempo limitado e renovável de forma automática.",
            },
            {
                text: "11.2. Cabe ao USUÁRIO cancelar, formalmente por email para contato@blix.digital, sua assinatura, antes da próxima cobrança para evitar o faturamento. O acesso ao sistema será disponibilizado, após o pedido de encerramento, até o final do período já remunerado.",
            },
            {
                text: "11.3. O acesso ao aplicativo será disponibilizado, após o pedido de encerramento, apenas até o final do período já remunerado pelo USUÁRIO, independente de plano/produto ou prazo originalmente contratados pelos Alunos.",
            },
            {
                text: "11.4. Ao encerrar a utilização do serviço, caberá ao USUÁRIO solicitar a exportação do material disponibilizado na plataforma, dentro do prazo de 30 (trinta) dias. Recomenda-se, no entanto, que o USUÁRIO mantenha em backup próprio todo o material disponibilizado na plataforma, a fim de evitar qualquer prejuízo decorrente de perda de dados.",
            },
            {
                text: "11.5. Após o prazo de 30 (trinta) dias contado do encerramento (por solicitação ou contado a partir do inadimplemento) a BLIX está autorizada a excluir definitivamente todo o material disponibilizado pelo USUÁRIO.",
            },
            {
                text: "11.6. Se o aviso do cancelamento do plano não for dado pelo menos 30 (trinta) dias antes do final do seu período de cobrança, o valor do pagamento final devido será cobrado com base na data de cancelamento até o prazo de 30 (trinta) dias ser atingido.",
            },
            {
                text: "11.7 Se o USUÁRIO estiver em um contrato mensal com a BLIX, qualquer uma das partes terá o direito de cancelar o serviço a qualquer momento com um período de aviso prévio de 30 (trinta) dias. Uma vez que o aviso de 30 (trinta) dias é dado, a conta é encerrada e todos os dados e acesso são removidos de nossos servidores no final deste período.",
            },
            {
                text: "11.8 Se o USUÁRIO estiver em um contrato anual com a BLIX, qualquer uma das partes tem o direito de cancelar a renovação automática do contrato com aviso prévio de pelo menos 30 (trinta) dias antes da data de renovação do prazo. Em caso de cancelamento do plano anual que possuir previsão de pagamento mensal, anterior à data de renovação do prazo, a BLIX terá o direito de cobrar uma taxa de cancelamento de 20% sobre o valor pago do momento do início do contrato até o cancelamento, com o intuito de restabelecer o equilíbrio econômico das partes, visto que o plano anual tem um desconto de 15% em relação ao plano mensal.",
            },
        ],
    },
    {
        subtitle: "12. DISPOSIÇÕES FINAIS",
        paragraphs: [
            {
                text: "12.1. Estes termos se tornam vigentes a partir do momento em que o USUÁRIO usa nossos Serviços pela primeira vez e continua enquanto usar nosso Serviço ou tiver uma conta conosco, o que for mais longo.",
            },
            {
                text: "12.2. Qualquer tolerância quanto ao descumprimento ou cumprimento irregular das obrigações previstas nos presentes Termos de Uso, por qualquer das partes, não constituirá novação ou alteração das disposições ora pactuadas, configurando somente liberalidade.",
            },
            {
                text: "12.3. É proibido a qualquer das partes ceder ou transferir os direitos e obrigações oriundas dos presentes Termos, sem o prévio e expresso consentimento da outra parte.",
            },
            {
                text: "12.4. Estes Termos e Condições obrigam as partes, seus sucessores e cessionários a qualquer título.",
            },
            {
                text: "12.5. Os presentes Termos de Uso revogam e substituem todos e quaisquer entendimentos, acordos ou contratos anteriormente celebrados entre as partes, tenham sido escritos ou verbais, representando o completo e integral entendimento em relação ao serviço.",
            },
            {
                text: "12.6. A relação estabelecida entre as partes é regida pelas leis vigentes no Brasil e qualquer controvérsia será submetida ao Foro da Cidade de Porto Alegre/RS.",
            },
        ],
    },
    {
        paragraphs: [
            {
                text: "Atualizado em: 24/11/2022",
            },
        ],
    },
];