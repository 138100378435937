import styled from 'styled-components/native';

export const ModalView = styled.View`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  align-items: center;
`;

export const Container = styled.View`
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: 'rgba(0, 0, 0, 0.5)';
`;

export const Input = styled.TextInput`
  border-bottom-width: 1px;
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  color: #2e2e2e;
`;

export const ButtonContainer = styled.View`
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  gap: 5px;
`;

export const ErrorMessage = styled.Text`
  color: red;
  margin: 0.5rem 0;
`;

export const InfoMessage = styled.Text`
  text-align: center;
  padding: 16px;
`;

