import styled from "styled-components/native";

export const ContentIcon = styled.TouchableOpacity`
  align-items: center;
  padding: 0.2rem;
  width: 6rem;
  background-color: rgba(0,0,0,0.1);
  border-radius: 10px;
`;

export const ViewButton = styled.View`
  flex-direction: row;
  justify-content: space-around;
  flex:1
`;