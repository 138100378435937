import styled from "styled-components/native";

export const FlexBox = styled.View`
  align-item: center;
  flex-direction: row;
`;

export const Select = styled.Picker`
  border: none;
`;
