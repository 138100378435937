import styled from "styled-components/native";

export const DropFile = styled.View`
  background: #b1b1b1;
  height: 100%;
  width: 60px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const NameFile = styled.Text`
  width: 3.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
