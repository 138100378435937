import React, { useEffect, useState } from "react";
import { Icon } from "react-native-elements";
import THEME from '../../config/theme';
import Button from '../../components/Button';
import { StandardText, SmallText, Container } from "../../config/theme/globalStyles";
import { auth } from "../../services/firebase";
import { ActivityIndicator } from "react-native-paper";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { CommonActions } from '@react-navigation/native';

export function Success({ navigation }) {
  const [login, setLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogin(true);
      } 
      setIsLoading(false);
    })
  }, []);

  return isLoading ? (
    <ActivityIndicator
    style={{
      flex: 1,
      backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
      justifyContent: "center",
    }}
    color={THEME.COLORS.PRIMARY_800}
  />
  ) : (
    <Container justifyContent="center">
      <Icon
        type="material-icons"
        name="check-circle-outline"
        color={THEME.COLORS.PRIMARY_900}
        size="60px"
      />
      {login ?
        <>
          <StandardText color={THEME.COLORS.PRIMARY_900} margin="1rem 0rem">Compra realizada com sucesso!</StandardText>
          <SmallText color={THEME.COLORS.PRIMARY_900} margin="0rem 0rem 3rem 0rem">Clique no botão abaixo para acessar os conteúdos!</SmallText>
          <Button
            title={"Acesse os conteúdos"}
            onPress={() =>
              navigation.dispatch(
                CommonActions.reset({
                  index: 1,
                  routes: [{ name: "Conteudo" }],
                }))}
          >
          </Button>
        </>
        :
        <>
          <StandardText color={THEME.COLORS.PRIMARY_900} margin="1rem 0rem">Compra realizada com sucesso!</StandardText>
          <SmallText color={THEME.COLORS.PRIMARY_900} margin="0rem 0rem 3rem 0rem">Faça o seu primeiro login.</SmallText>
          <Button
            title={"Fazer Login"}
            onPress={() =>
              navigation.dispatch(
                CommonActions.reset({
                  index: 1,
                  routes: [{ name: 'Login' }],
                }))}
          >
          </Button>
        </>
      }
    </Container>
  );
};