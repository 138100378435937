import styled from 'styled-components/native';

export const Touchable = styled.TouchableOpacity`
`;

export const Image = styled.ImageBackground`
  width: 20rem;
  margin: 0rem 1rem 0.5rem 0rem;
  height: 11.25rem;
  border-radius: 10px;
  overflow: hidden;
`;

export const ViewLock = styled.View`
  justify-content:flex-end;
  align-items:flex-end;
  flex:1;
  padding:0.3rem;
`;

export const ContentText = styled.View`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '')};
  padding: 0.2rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ViewCommentNotification = styled.View`
  justify-content:flex-end;
  align-items:flex-end;
  flex:1;
`;