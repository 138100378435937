import React from 'react';
import { SmallText } from '../../config/theme/globalStyles';
import { Container } from './style';

const TouchableText = ({
  title,
  color,
  textDecoration,
  fontFamily,
  fontSize,
  margin,
  ...rest
}) => {
 

  const removeSansSerif = (font)=> {
    return font?.split(',')[0].trim();
  }


  return (
    <Container {...rest}>
      <SmallText
        textDecoration={textDecoration}
        color={color}
        fontFamily={removeSansSerif(fontFamily)}
        fontSize={fontSize}
        margin={margin ? margin : margin}
      >
        {title}
      </SmallText>
    </Container>
  );
};
export default TouchableText;
