import { useCallback, useEffect, useState } from "react";
import { useUpdatedFonts } from "../../../api/Brand";
import { useCustomTheme } from "../../../contexts/useCustomTheme";

export const useCustomFont = () => {
  const [fontXS, setFontXS] = useState();
  const [fontSM, setFontSM] = useState();
  const [fontMD, setFontMD] = useState();
  const [fontLG, setFontLG] = useState();
  const [fontLX, setFontLX] = useState();

  const [selectedFontTitle, setSelectedFontTitle] = useState();
  const [selectedFontSubtitle, setSelectedFontSubtitle] = useState();
  const [selectedFontText, setSelectedFontText] = useState();

  const { mutateAsync: updatedFonts } = useUpdatedFonts();

  const { customFont: customFonts } = useCustomTheme();

  const debouncedUpdateFonts = useCallback(
    _.debounce((fonts) => {
      updatedFonts(fonts);
    }, 500),
    []
  );

  useEffect(() => {
    const fonts = { fontXS, fontSM, fontMD, fontLG, fontLX };
    if (Object.values(fonts).some((value) => value !== undefined)) {
      debouncedUpdateFonts(fonts);
    }
  }, [fontXS, fontSM, fontMD, fontLG, fontLX]);

  useEffect(() => {
    const fonts = {
      fontFamilyTitle: selectedFontTitle,
    };
    if (selectedFontTitle) {
      debouncedUpdateFonts(fonts);
    }
  }, [selectedFontTitle]);

  useEffect(() => {
    const fonts = {
      fontFamilySubtitle: selectedFontSubtitle,
    };
    if (selectedFontSubtitle) {
      debouncedUpdateFonts(fonts);
    }
  }, [selectedFontSubtitle]);

  useEffect(() => {
    const fonts = {
      fontFamilyText: selectedFontText,
    };
    if (selectedFontText) {
      debouncedUpdateFonts(fonts);
    }
  }, [selectedFontText]);

  useEffect(() => {
    if (customFonts && Object.keys(customFonts).length > 1) {
      setFontXS(customFonts.fontXS ? customFonts.fontXS : 12);
      setFontSM(customFonts.fontSM ? customFonts.fontSM : 14);
      setFontMD(customFonts.fontMD ? customFonts.fontMD : 16);
      setFontLG(customFonts.fontLG ? customFonts.fontLG : 20);
      setFontLX(customFonts.fontLX ? customFonts.fontLX : 22);
      setSelectedFontTitle(
        customFonts.fontFamilyTitle && customFonts.fontFamilyTitle
      );
      setSelectedFontSubtitle(
        customFonts.fontFamilySubtitle && customFonts.fontFamilySubtitle
      );
      setSelectedFontText(
        customFonts.fontFamilyText && customFonts.fontFamilyText
      );
    } else {
      setFontXS(12);
      setFontSM(14);
      setFontMD(16);
      setFontLG(20);
      setFontLX(22);
    }
  }, [customFonts]);

  return {
    fontXS,
    setFontXS,
    fontSM,
    setFontSM,
    fontMD,
    setFontMD,
    fontLG,
    setFontLG,
    fontLX,
    setFontLX,
    selectedFontTitle,
    setSelectedFontTitle,
    selectedFontSubtitle,
    setSelectedFontSubtitle,
    selectedFontText,
    setSelectedFontText,
  };
};