import React from "react";
import { BaseToast } from "react-native-toast-message";

const toastConfig = {
  error: ({ text1, text2, ...rest }) => (
    <BaseToast
      {...rest}
      style={{ borderLeftColor: "red" }}
      contentContainerStyle={{ paddingHorizontal: 15 }}
      text1Style={{
        fontSize: 18, // Aumenta o tamanho da fonte do título
        fontWeight: "medium",
      }}
      text2Style={{
        fontSize: 16, // Aumenta o tamanho da fonte da mensagem
        color: "black",
        opacity: 0.7,
      }}
      text1={text1}
      text2={text2}
    />
  ),
  success: ({ text1, text2, ...rest }) => (
    <BaseToast
      {...rest}
      style={{ borderLeftColor: "green" }}
      contentContainerStyle={{ paddingHorizontal: 15 }}
      text1Style={{
        fontSize: 18, // Aumenta o tamanho da fonte do título
        fontWeight: "medium",
      }}
      text2Style={{
        fontSize: 16, // Aumenta o tamanho da fonte da mensagem
        color: "black",
        opacity: 0.7,
      }}
      text1={text1}
      text2={text2}
    />
  ),
};

export default toastConfig;
