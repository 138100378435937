import { BoxUploadFont, ItemForm, TextAndTooltip } from "../style";
import { InputCustomNumeric } from "../InputCustomNumeric";
import { PickerFonts } from "../PickerFonts";
import { ContainerFontSize, ItemFontSize } from "./style";
import { TooltipCustom } from "../../../components/Tooltip";
import _ from "lodash";
import { useCustomFont } from "./useCustomFont";
import { useCustomTheme } from "../../../contexts/useCustomTheme";
import { StandardText, SmallText } from '../../../config/theme/globalStyles'

export const CustomFont = () => {
  const {
    fontXS,
    setFontXS,
    fontSM,
    setFontSM,
    fontMD,
    setFontMD,
    fontLG,
    setFontLG,
    fontLX,
    setFontLX,
    selectedFontTitle,
    setSelectedFontTitle,
    selectedFontSubtitle,
    setSelectedFontSubtitle,
    selectedFontText,
    setSelectedFontText,
  } = useCustomFont();
  const { customFont } = useCustomTheme();
  return (
    <>
      <TextAndTooltip style={{ marginTop: "0.5rem", height: "1rem" }}>
        <StandardText textAlign="left" color="black">
          Fontes
        </StandardText>
        <TooltipCustom text="A fonte escolhida será visualizada somente em telas que são visíveis para o usuário final." />
      </TextAndTooltip>

      <ItemForm>
        <SmallText color="black">
          Fonte para os títulos:
        </SmallText>
        <BoxUploadFont>
          <PickerFonts
            selectedValue={selectedFontTitle}
            setSelectValue={setSelectedFontTitle}
            fileType="fontFamilyTitle"
          />
        </BoxUploadFont>
      </ItemForm>

      <ItemForm>
        <SmallText color="black">
          Fonte para os subtítulos:
        </SmallText>
        <BoxUploadFont>
          <PickerFonts
            selectedValue={selectedFontSubtitle}
            setSelectValue={setSelectedFontSubtitle}
            fileType="fontFamilySubtitle"
          />
        </BoxUploadFont>
      </ItemForm>

      <ItemForm>
        <SmallText color="black">
          Fonte para os textos:
        </SmallText>
        <BoxUploadFont>
          <PickerFonts
            selectedValue={selectedFontText}
            setSelectValue={setSelectedFontText}
            fileType="fontFamilyText"
          />
        </BoxUploadFont>
      </ItemForm>

      <TextAndTooltip style={{ marginTop: "0.5rem", height: "1rem" }}>
        <StandardText textAlign="left" color="black">
          Tamanho das fontes:
        </StandardText>
        <TooltipCustom text="Os tamanhos de fontes dos textos determinam a altura das letras e palavras exibidas, medidos em pixels (px)." />
      </TextAndTooltip>
      <ContainerFontSize>
        <ItemFontSize>
          <SmallText color="black">
            Tamanho extra pequeno:
          </SmallText>
          <InputCustomNumeric
            value={fontXS}
            onChangeText={(text) =>
              setFontXS(Number(text.replace(/[^0-9]/g, "")))
            }
          />
        </ItemFontSize>
        <ItemFontSize style={{ flexDirection: "row", alignItems: "center" }}>
          <SmallText color="black">
            Tamanho pequeno:
          </SmallText>
          <InputCustomNumeric
            value={fontSM}
            onChangeText={(text) =>
              setFontSM(Number(text.replace(/[^0-9]/g, "")))
            }
          />
        </ItemFontSize>
        <ItemFontSize style={{ flexDirection: "row", alignItems: "center" }}>
          <SmallText color="black">
            Tamanho médio:
          </SmallText>
          <InputCustomNumeric
            value={fontMD}
            onChangeText={(text) =>
              setFontMD(Number(text.replace(/[^0-9]/g, "")))
            }
          />
        </ItemFontSize>
        <ItemFontSize style={{ flexDirection: "row", alignItems: "center" }}>
          <SmallText color="black">
            Tamanho grande:
          </SmallText>
          <InputCustomNumeric
            value={fontLG}
            onChangeText={(text) =>
              setFontLG(Number(text.replace(/[^0-9]/g, "")))
            }
          />
        </ItemFontSize>
        <ItemFontSize style={{ flexDirection: "row", alignItems: "center" }}>
          <SmallText color="black">
            Tamanho extra grande:
          </SmallText>
          <InputCustomNumeric
            value={fontLX}
            onChangeText={(text) =>
              setFontLX(Number(text.replace(/[^0-9]/g, "")))
            }
          />
        </ItemFontSize>
      </ContainerFontSize>
    </>
  );
};
