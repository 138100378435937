import { TextInput } from 'react-native-paper';
import { useCustomTheme } from '../../../contexts/useCustomTheme';
import THEME from '../../../config/theme';
import { useState } from 'react';

export const InputCustomNumeric = (rest) => {
  const { customFont } = useCustomTheme();
  const [value, setValue] = useState('');

  const handleChangeText = (text) => {
    const numericValue = text.replace(/[^0-9]/g, '');
    if (numericValue.length <= 2) {
      setValue(numericValue);
    }
  };

  return (
    <TextInput
      style={{
        backgroundColor: '#fff',
        width: 80,
        height: 24,
        marginLeft: '10px',
        fontFamily:
          customFont && customFont.fontFamilyText
            ? customFont.fontFamilyText
            : THEME.FONTFAMILY.REGULAR,
      }}
      theme={{
        colors: {
          primary: '#B1B1B1',
          outline: '#B1B1B1',
        },
      }}
      mode="outlined"
      keyboardType="numeric"
      maxLength={2} 
      textColor="#000"
      value={value}
      onChangeText={handleChangeText} 
      {...rest}
    />
  );
};
