import React, { useState, useEffect } from 'react';
import { TextInput as Input } from 'react-native-paper';
import THEME from '../../config/theme';
import { useCustomTheme } from '../../contexts/useCustomTheme';
import { removeSansSerif } from '../../utils';
const TextInput = (props) => {
  const [inputHeight, setInputHeight] = useState(40);
  const [textValue, setTextValue] = useState(props.value || '');

  const { customColor, customFont } = useCustomTheme();

  useEffect(() => {
    setTextValue(props.value || '');
  }, [props.value]);
  const handleTextChange = (text) => {
    setTextValue(text);
    if (props.onChangeText) {
      props.onChangeText(text);
    }
    const numberOfLines = text.split('\n').length;
    const newHeight = numberOfLines * 20 + 20;
    setInputHeight(Math.max('6rem', newHeight));
  };
  return (
    <Input
      {...props}
      style={{
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : customColor && customColor.background && customColor.background.auth
          ? customColor.background.auth
          : THEME.COLORS.BACKGROUND_ABOUT,

        fontFamily: props.fontFamily
          ? removeSansSerif(props.fontFamily)
          : customFont && customFont.fontFamilyText
          ? removeSansSerif(customFont.fontFamilyText)
          : THEME.FONTFAMILY.REGULAR,

        fontSize: props.fontSize
          ? props.fontSize
          : customFont && customFont.fontSM
          ? customFont.fontSM
          : THEME.FONTSIZE.SMALL,

        width: props.width,
        height: props.multiline ? Math.max(40, inputHeight) : '',
        borderBottomColor:
          props.mode === 'flat'
            ? customColor && customColor.primary
              ? customColor.primary
              : THEME.COLORS.PRIMARY_900
            : '',
        borderBottomWidth: props.mode === 'flat' ? 1 : 0,
      }}
      mode={props.mode ? props.mode : 'outlined'}
      
      underlineColor={
        props.mode === 'flat'
          ? customColor && customColor.secondary
            ? customColor.secondary
            : THEME.COLORS.PRIMARY_800
          : undefined
      }
      theme={{
        colors: {
          primary: props.color
            ? props.color
            : customColor && customColor.primary
            ? customColor.primary
            : THEME.COLORS.PRIMARY_900,

          outline: props.color
            ? props.color
            : customColor && customColor.secondary
            ? customColor.secondary
            : THEME.COLORS.PRIMARY_800,

          onSurfaceVariant: props.color
            ? props.color
            : customColor && customColor.text && customColor.text.auth
            ? customColor.text.auth
            : THEME.COLORS.TEXT_ABOUT,

          onSurface: props.color
            ? props.color
            : customColor && customColor.text && customColor.text.auth
            ? customColor.text.auth
            : THEME.COLORS.TEXT_ABOUT,
        },
      }}
      multiline={props.multiline}
      value={textValue}
      onChangeText={handleTextChange}
      onContentSizeChange={(event) => {
        if (props.multiline) {
          setInputHeight(event.nativeEvent.contentSize.height);
        }
      }}
    />
  );
};
export default TextInput;
