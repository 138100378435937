import styled from 'styled-components/native'

export const ContainerMobile = styled.View`
 flex-grow:1
`;

export const ContainerDesktop = styled.View`
  flex:1;
`;

export const Content = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ViewComment = styled.View`
  flex-direction: row;
  align-items: flex-start;
  margin: 0.5rem 0rem;
`;


export const ViewAvatar = styled.View`
  width: 3.2rem;
  height: 2.2rem;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.2rem;
`;

export const Avatar = styled.Image`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
`;

export const VerticalScroll = styled.View`
  flex: 2
  padding: 0rem 1rem;
`;

export const VerticalScrollDesktop = styled.View`
  align-self: center;
  width: 30%;
  height: 75%;
  padding: 0rem 1rem;
`;
