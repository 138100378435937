import styled from 'styled-components/native';
import { Dimensions } from 'react-native';
import THEME from "../../config/theme";

const windowWidth = Dimensions.get('window').width;

export const VerticalScroll = styled.View`
  flex: 2
  padding: 0rem 1rem;
`;

export const Container = styled.View`
  flex:1;
  padding: 1rem;
`;

export const VerticalScrollDesktop = styled.View`

`;

export const Image = styled.ImageBackground`
  width: 100%;
  height: 100%;
`;

export const ContentVideoDesktop = styled.View`
  align-self: center;
  width: undefined;
  height: 75%;
  aspect-ratio: 16/9;
`;

export const ContentVideo = styled.View`
  width: ${windowWidth};
  height: ${(windowWidth-32) / 1.78}px;
  margin-bottom: 1rem;
  padding: 0rem 1rem;
  justify-content: center;
  align-items: center;
`;

export const ImageBlocked = styled.View`
  background-color: "rgba(0,0,0,0.7)";
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const ContentList = styled.View`
  width: 30%;
  height: ${((windowWidth* 0.70) - 32) / 1.78}px;
  padding: 0rem 1rem;
`;

export const ContainerPlaylist = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Avatar = styled.ImageBackground`
	height: 5rem;
  width: 8.8rem;
`;

export const Content = styled.View`
  flex: 1;
  padding: 0.5rem;
  justify-content: center;
`;

export const ViewTextInput = styled.View`
  height: 5rem;
`;

export const ViewOrder = styled.View`
  height: 5rem;
  align-items: flex-start;
`;

export const ViewText = styled.View`
  height: 80%;
`;

export const ViewButton = styled.View`
  padding: 0.5rem 0rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ContentImage = styled.TouchableOpacity`
  background-color: "rgba(0,0,0,0.5)";
  align-items: center;
  justify-content: center;
  width:60%;
  margin: 0.8rem 0rem
`;

export const ViewModal = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    alignItems: "center",
    padding: "2rem",
    backgroundColor: "#FFFFFF",
    flexGrow: 1
  },
})`  
`;

export const ContentIcon = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 30px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '')};
`;

export const ListContainer = styled.View`
  margin: 20px;
  background-color: white;
  border-radius: 20px;
  padding: 35px;
  align-items: center;
  width: ${windowWidth * 0.7}
`;

export const SwitchView = styled.View`
  padding: 0.5rem;
  flex-direction: row;
`;

export const FlatListView = styled.View`
  background-color: white;
  padding: 1rem 0rem;
  flex-basis: auto;
`;

export const ViewCommentNotification = styled.View`
  justify-content:flex-end;
  align-items:flex-end;
  width:20%;
`;