import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { TextInput } from "react-native-paper";
import { useUpload } from "../../hooks/useUpload";
import { Image } from "react-native";
import { DropFile, NameFile } from "./style";
import { View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { animated, useSpring } from "react-spring";
import { borderRadiusButtons } from "../../config/data";
import { useCustomTheme } from "../../contexts/useCustomTheme";

export const UploadImages = ({ fileType }) => {
  const [files, setFiles] = useState([]);

  const {
    handleUpload,
    onDropRejectedMessage,
    IconStatus,
    loadExistingImage,
    existingImage,
    handleDeleteExistingImage,
    statusUpload,
  } = useUpload();

  useEffect(() => {
    loadExistingImage(fileType);
  }, [fileType]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleUpload(acceptedFiles, fileType, setFiles);
    },
    [fileType, setFiles]
  );

  const dropzone = useDropzone({
    accept: { "image/png": [".png"] },
    maxSize: 2097152, // 2MB in bytes
    onDropRejected: onDropRejectedMessage,
    maxFiles: 1,
    onDrop,
    onFileDialogCancel: () => {
      setFiles([]); // Reset files state when file dialog is cancelled
    },
  });

  const { getRootProps, getInputProps } = dropzone;

  const rotateProps = useSpring({
    loop: true,
    from: { rotateZ: 0 },
    to: { rotateZ: 360 },
    config: { duration: 2000 },
  });
  const AnimatedView = animated(View);

  const { customInfo } = useCustomTheme();
  return (
    <DropFile
      {...getRootProps()}
      style={{
        borderRadius: customInfo
          ? `${customInfo.borderRadius}px`
          : borderRadiusButtons,
      }}
    >
      <TextInput {...getInputProps()} />
      {existingImage ? (
        <>
          <View>
            {fileType.includes("font") ? (
              <NameFile>{existingImage}</NameFile>
            ) : (
              <Image
                source={{ uri: existingImage }}
                style={{
                  width: "48px",
                  maxWidth: "48px",
                  minHeight: "48px",
                  maxHeight: "48px",
                  objectFit: "cover",
                }}
              />
            )}
          </View>

          <AnimatedView
            style={
              statusUpload === "uploading"
                ? {
                    ...rotateProps,
                    position: "absolute",
                    top: "-6px",
                    right: "-6px",
                  }
                : { position: "absolute", top: "-6px", right: "-6px" }
            }
          >
            <FontAwesome
              name={
                statusUpload === "uploading" ? "circle-o-notch" : "times-circle"
              }
              size={24}
              color={statusUpload === "uploading" ? "#14181d" : "red"}
              onPress={() => handleDeleteExistingImage(fileType)}
            />
          </AnimatedView>
        </>
      ) : (
        <IconStatus />
      )}
    </DropFile>
  );
};
