import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../services/firebase/index";


export const getVideos = ({
    categoryId,
    cardId
}) => {
    const videosCollectionRef = collection(firestore, `categories/${categoryId}/cards/${cardId}/videos`);
    return getDocs(videosCollectionRef);
}