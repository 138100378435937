import styled from "styled-components/native";
import THEME from '../../config/theme';

export const Container = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const Avatar = styled.Image`
	height: 3.5rem;
	border-radius: 5px;
  width: 6.2rem;
`;

export const Content = styled.View`
  flex-direction: row;
  flex:2
  align-items: center;
`;

export const ContentIcon = styled.View`
  align-items: flex-end;
  flex-direction: row;
  flex:1
`;

export const ViewIcon = styled.View`
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : `${THEME.COLORS.TEXT_MAIN}`)};
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  margin-right: 0.4rem;
`;