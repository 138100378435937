import styled from "styled-components/native";

export const ContainerFontSize = styled.View`
  margin: 10px 0;
`;

export const ItemFontSize = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
