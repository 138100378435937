import React, { useState, useEffect } from 'react';
import { View, Modal, StyleSheet, Switch } from 'react-native';
import TextInput from "../../../components/TextInput";
import {
  StandardText,
  FooterText,
  SmallText,
  SubTitle
} from "../../../config/theme/globalStyles";
import Button from "../../../components/Button";
import {
  ViewTextInput,
  ViewButton,
  ViewRow
} from "./style";
import { getProductById } from '../../../services/guru';
import { Dimensions } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { firestore } from '../../../services/firebase'
import { doc, getDoc } from "firebase/firestore";

const screenWidth = Dimensions.get("window").width;

const EditProductModal = ({ isVisible, onClose, onEdit, selectedProduct }) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [productInfo, setProductInfo] = useState('');
  const [offersInfos, setOffersInfos] = useState('');
  const [saveButtonVisible, setSaveButtonVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [type, setType] = useState("");
  const [archived, setArchived] = useState(true);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [description, setDescription] = useState('');
  const [hasExpirationDate, setHasExpirationDate] = useState(false);
  const [expirationDays, setExpirationDays] = useState(0);
  const [expirationDateRequired, setExpirationDateRequired] = useState(false);
  const [showExpirationDaysWarning, setShowExpirationDaysWarning] = useState(false);

  useEffect(() => {
    findPlanAndOffers()
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000); // Esconde a mensagem após 3 segundos
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  const handleEdit = async () => {
    try {
      setLoadingSave(true);
      if ((productInfo?.type === "product" || type == 'product') && !archived && (!expirationDays || Number.isNaN(expirationDays))) {
        setShowExpirationDaysWarning(true);
        return
      }
      await onEdit(productInfo, offersInfos, hasExpirationDate, expirationDays, checkoutUrl, archived, description, selectedProduct);

      setShowSuccessMessage(true);
      onClose();
    } catch (error) {
      console.error('Erro ao criar produto:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  const findPlanAndOffers = async () => {
    try {
      setLoadingSearch(true);

      const infos = await getProductById(selectedProduct.productId);
      setProductInfo(infos.product);
      setOffersInfos(infos.offers);
      setSaveButtonVisible(true);
      setType(infos.product.type);
      setExpirationDateRequired(infos.product.type === "product");
      setHasExpirationDate(infos.product.type === "product" ? true : false);

      const productRef = doc(firestore, "products", selectedProduct?.id);
      const docSnap = await getDoc(productRef);
      if (docSnap.exists()) {
        const productData = docSnap.data()
        setDescription(productData?.description)
        setArchived(productData?.archived);
        setCheckoutUrl(productData?.checkoutUrl);
        setHasExpirationDate(productData?.hasExpirationDate);
        setExpirationDays(productData?.days);
      }
    } catch (error) {
      console.error('Erro ao buscar informações do produto:', error);
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <Modal animationType="fade" isVisible={isVisible} onBackdropPress={onClose} transparent={true}>
      <View style={styles.centeredView}>
        <View style={styles.modalContainer}>
          <View style={styles.editProductLine}>
            <SubTitle padding="0.2rem 0rem" color="#FFFFFF">Editar produto</SubTitle>
          </View>
          <View style={styles.content}>
            <ViewTextInput>
              <StandardText padding="0.2rem 0rem" color="#2e2e2e">{selectedProduct?.productId}</StandardText>
            </ViewTextInput>
            {loadingSearch &&
              <ActivityIndicator
                style={{
                  flex: 1,
                  backgroundColor: "#FFFFFF",
                  justifyContent: "center",
                }}
                color='#d3d3d3'
                size="small"
              />
            }
            {saveButtonVisible && (
              <>
                <TextInput
                  placeholder="Descrição"
                  color="black"
                  backgroundColor="white"
                  value={description}
                  onChangeText={(description) => setDescription(description)}
                />
                <ViewTextInput>
                  <TextInput
                    placeholder="Checkout URL"
                    color="black"
                    backgroundColor="white"
                    value={checkoutUrl}
                    onChangeText={(url) => setCheckoutUrl(url)}
                  />
                </ViewTextInput>
                <ViewTextInput>
                  <ViewRow>
                    <StandardText padding="0.5rem 1rem 0.5rem 0rem" color="#2e2e2e" textAlign="left">Habilitar no aplicativo:</StandardText>
                    <Switch
                      value={!archived}
                      onValueChange={(value) => setArchived(!value)}
                    />
                  </ViewRow>
                </ViewTextInput>
                {type === "product" && (
                  <ViewTextInput>
                    <SmallText padding="0.2rem 0rem" color="#2e2e2e" textAlign="left">Data de Validade após Compra:</SmallText>
                    <ViewTextInput>
                      <TextInput
                        placeholder="Dias de Expiração"
                        color="black"
                        backgroundColor="white"
                        value={expirationDays || 0}
                        onChangeText={(days) => setExpirationDays(parseInt(days))}
                        keyboardType="numeric"
                      />
                    </ViewTextInput>
                    {showExpirationDaysWarning && (
                      <FooterText color="red">
                        Por favor, preencha os dias de expiração para salvar as alterações.
                      </FooterText>
                    )}
                  </ViewTextInput>
                )}
                {type === "plan" && (
                  <ViewTextInput>
                    <ViewRow>
                      <StandardText padding="0.5rem 1rem 0.5rem 0rem" color="#2e2e2e" textAlign="left">Data de Validade após Compra:</StandardText>
                      <Switch
                        value={hasExpirationDate}
                        onValueChange={(value) => setHasExpirationDate(value)}
                      />
                    </ViewRow>
                    <SmallText padding="0.2rem 0rem" color="#2e2e2e" textAlign="left">⚠ ATENÇÃO: Apenas adicione uma data de validade se esse produto for uma venda única (one-time purchase).</SmallText>
                    {hasExpirationDate && (
                      <ViewTextInput>
                        <TextInput
                          placeholder="Dias de Expiração"
                          color="black"
                          backgroundColor="white"
                          value={expirationDays}
                          onChangeText={(days) => setExpirationDays(parseInt(days))}
                          keyboardType="numeric"
                        />
                      </ViewTextInput>
                    )}
                  </ViewTextInput>
                )}
              </>
            )}

            {saveButtonVisible && (
              <ViewButton style={{ marginTop: '1rem' }}>
                <Button
                  title="Salvar"
                  isLoading={loadingSave}
                  onPress={handleEdit}
                  colorbutton={"#3FC380E6"}
                />
                <Button
                  title="Cancelar"
                  onPress={onClose}
                  colorbutton={"#FA5D5D"}
                  margin="0rem 0rem 0rem 1rem"
                />
              </ViewButton>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modalContainer: {
    borderRadius: 10,
    backgroundColor: 'white',
    alignItems: 'center',
    width: screenWidth > 980 ? screenWidth * 0.6 : screenWidth * 0.9,
  },
  editProductLine: {
    backgroundColor: '#1e1e1e',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%', // Ocupa toda a largura disponível
  },
  content: {
    width: '100%',
    paddingHorizontal: 10,
  },
  // Adicione outros estilos conforme necessário
});

export default EditProductModal;