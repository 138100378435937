import React, { useMemo } from 'react';
import { View } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import { Dimensions } from "react-native";

const screenWidth = Dimensions.get("window").width;

const VideoChart = ({ data }) => {

    const groupedData = useMemo(() => {
        return data.reduce((grouped, item) => {
            if (grouped[item.date]) {
                grouped[item.date] += 1;
            } else {
                grouped[item.date] = 1;
            }
            return grouped;
        }, {});
    }, [data]);

    const chartLabels = useMemo(() => Object.keys(groupedData), [groupedData]);

    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                data: chartLabels.map((date) => groupedData[date]),
            },
        ],
    };

    // Verifique se data não está vazio para evitar "NaN" no gráfico
    if (data.length === 0) {
        return <View />;
    }

    const chartConfig = {
        backgroundGradientFrom: "#ffffff",
        backgroundGradientFromOpacity: 1,
        backgroundGradientTo: "#ffffff",
        backgroundGradientToOpacity: 1,
        color: (opacity = 1) => `rgba(0, 122, 255, ${opacity})`,
        labelColor: (opacity = 0.6) => `rgba(0, 0, 0, ${opacity})`,
    };

    return (
        <View style={{ alignItems: 'center', borderRadius: 16 }}>
            <LineChart
                data={chartData}
                width={screenWidth * 0.8}
                height={256}
                verticalLabelRotation={30}
                chartConfig={chartConfig}
                bezier
                style={{
                    paddingVertical: 20,
                    borderRadius: 10,
                }}
            />
        </View>
    );
};

export default VideoChart;