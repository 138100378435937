import styled from 'styled-components/native';

export const ContentIcon = styled.TouchableOpacity`
  align-self: center;
  width: 100%;
  padding: 0.2rem;
  padding: 0.5rem 0rem;
  background-color: 'rgba(0,0,0,0.1)';
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
`;


export const ViewDescription = styled.View`
  justify-content: center;
`;


export const ViewPdf = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16;
  width: 100%;
`;

export const ViewActionsPdf = styled.View`
  flex-direction: row;
  margin-left: 8;
  width: 10%
`;

export const IconsPdf = styled.TouchableOpacity`
  align-self: center;
  justify-content: flex-end;
`;
