import { useQuery } from '@tanstack/react-query';
import { firestore } from '../services/firebase';
import { USER, USERS } from '../constants/entities';
import { auth } from '../services/firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

export const useUser = () => {
  return useQuery({
    queryKey: [USER, auth.currentUser?.uid],
    queryFn: async () => {
      if (!auth.currentUser?.uid) {
        throw new Error('No user ID found.');
      }

      const userDocRef = doc(firestore, USERS, auth.currentUser?.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        return {
          Celular: '',
          created_at: null,
          Email: '',
          Nome_Completo: '',
          isSignUpFromApp: null,
        };
      }

      return {id: auth.currentUser?.uid, ...userDoc.data()};
    },
    enabled: !!auth.currentUser?.uid,
  });
};

export const useUserPlan = () => {
  const userId = auth.currentUser?.uid;

  return useQuery({
    queryKey: [USER, userId, 'plan'],
    queryFn: async () => {
      if (!userId) {
        throw new Error('No user ID found.');
      }

      const userDocRef = doc(firestore, USERS, userId);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        throw new Error('User document not found.');
      }

      const userData = userDoc.data();
      const plan = userData?.plan;
      let userPlan = false;

      if (plan && userData?.planType) {
        const subscriptionsRef = collection(userDocRef, 'subscriptions');
        const subscriptionsSnapshot = await getDocs(subscriptionsRef);

        subscriptionsSnapshot.forEach((subscriptionDoc) => {
          const subscriptionData = subscriptionDoc.data();

          if (subscriptionData.items) {
            const activeItem = subscriptionData.items.find(
              (item) => item.active && item.product === plan
            );

            if (activeItem) {
              userPlan = {
                plan: plan,
                created: activeItem.created,
              };
            }
          }
        });

        if (userPlan) {
          return userPlan;
        }
      }

      if (userData.productIds) {
        const subscriptionsRef = collection(userDocRef, 'subscriptions');
        const subscriptionsSnapshot = await getDocs(subscriptionsRef);

        for (const product of userData.productIds) {
          if (product.customid) {
            subscriptionsSnapshot.forEach((subscriptionDoc) => {
              const subscriptionData = subscriptionDoc.data();

              if (subscriptionDoc.id === product.customid) {
                userPlan = {
                  plan: subscriptionData.productId,
                  created: subscriptionData.date_ms,
                };
              }
            });
          } else {
            const productId = product.productId;
            subscriptionsSnapshot.forEach((subscriptionDoc) => {
              const subscriptionData = subscriptionDoc.data();

              const matchingItem = subscriptionData.items?.find(
                (item) => item.plan?.product === productId
              );

              if (matchingItem) {
                userPlan = {
                  plan: productId,
                  created: subscriptionData.dates?.started_at,
                };
              }
            });
          }
        }

        if (userPlan) {
          return userPlan;
        }
      }

      return userPlan;
    },
    enabled: !!userId,
  });
};

