import { useCallback, useEffect, useState } from "react";
import { useColor } from "react-color-palette";
import _ from "lodash";
import useViewport from "../../hooks/useViewport";
import { Main } from "./style";
import { useUpdatedColors, useUpdatedCustomizationInfo } from "../../api/Brand";
import { useCustomTheme } from "../../contexts/useCustomTheme";
import THEME from "../../config/theme";

export const usebrand = () => {
  const [valueBorderRadius, setValueBorderRadius] = useState(10);

  //instanciar as cores default
  const [primaryColor, setPrimaryColor] = useColor(THEME.COLORS.PRIMARY_900);
  const [secondaryColor, setSecondaryColor] = useColor(
    THEME.COLORS.PRIMARY_800
  );

  //use state para mostrar ou não o modal de cores
  const [show, setShow] = useState({
    showPrimaryColor: false,
    showSecondaryColor: false,
  });
  const handleShowPicker = useCallback((field, value) => {
    setShow((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  });

  const {
    customColor: colorInfo,
    customInfo,
    isLoadingColorInfo,
    isLoadingCustomInfo,
  } = useCustomTheme();

  const { mutateAsync: updatedColor } = useUpdatedColors();

  const debouncedUpdateColor = useCallback(
    _.debounce((newColors) => {
      updatedColor(newColors);
    }, 500),
    []
  );
  //get das cores que estao no banco, se não tiver ficará na default
  useEffect(() => {
    if (colorInfo && Object.keys(colorInfo).length > 1) {
      setPrimaryColor({
        ...primaryColor,
        hex: colorInfo.primary ? colorInfo.primary : THEME.COLORS.PRIMARY_900,
      });
      setSecondaryColor({
        ...secondaryColor,
        hex: colorInfo.secondary
          ? colorInfo.secondary
          : THEME.COLORS.PRIMARY_800,
      });
    }
  }, [colorInfo]);

  useEffect(() => {
    const newColors = {
      primary: primaryColor?.hex,
      secondary: secondaryColor.hex,
    };
    debouncedUpdateColor(newColors);
  }, [primaryColor, secondaryColor]);

  //BORDER RADIUS
  const { mutateAsync: updatedInfo } = useUpdatedCustomizationInfo();
  useEffect(() => {
    if (customInfo) {
      setValueBorderRadius(customInfo.borderRadius);
    }
  }, [customInfo]);

  useEffect(() => {
    if (valueBorderRadius) {
      updatedInfo({ borderRadius: valueBorderRadius });
    }
  }, [valueBorderRadius]);

  const MobileOrDesktopForm = useCallback(({ children }) => {
    const { width } = useViewport();
    const breakpoint = 1600;
    return width < breakpoint ? (
      <Main style={{ flexDirection: "column" }}>{children}</Main>
    ) : (
      <Main style={{ flexDirection: "row" }}>{children}</Main>
    );
  }, []);

  return {
    primaryColor,
    secondaryColor,
    setSecondaryColor,
    setPrimaryColor,
    valueBorderRadius,
    setValueBorderRadius,
    handleShowPicker,
    show,
    MobileOrDesktopForm,
    isLoadingCustomInfo,
    isLoadingColorInfo,
  };
};
