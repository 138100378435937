import React, { useState } from 'react';
import { Image, View } from 'react-native';

export const CustomImage = ({ source, width, aspectRatio, styles }) => {
  const [height, setHeight] = useState(width / aspectRatio);

  const handleLayout = event => {
    const { width } = event.nativeEvent.layout;
    setHeight(width / aspectRatio);
  };

  return (
    <View style={styles.container} onLayout={handleLayout}>
      <Image source={source} style={{...styles.image, height }} />
    </View>
  );
};