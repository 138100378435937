import React from 'react';
import { View, ScrollView, Text, Image } from 'react-native';
import Slider from '../../Slider';

const Testimonial = ({ item, component, width }) => {
    const imageSource = item.image ? { uri: item.image } : null;
    return (
        <View style={component.cardStyle(width)}>
            {imageSource ? (
                <Image source={imageSource} style={{ width: '100%', aspectRatio: 1, resizeMode: "contain" }} />
            ) : (
                <>
                    <Text style={component.titleStyle(width)}>{item.title}</Text>
                    <Text style={component.infoStyle(width)}>{item.infos}</Text>
                </>
            )}
        </View>
    );
};

const Testimonials = ({ array, component, width, breakpoint }) => {
    const cardWidth = parseInt(component.cardStyle(width).width, 10) * 16; // transform unit in "rem" to pixel

    const testimonialCards = array.map((item, index) => (
        <Testimonial key={index} item={item} index={index} component={component} width={width} />
    ));

    return (
        <>
            {width < breakpoint ? (
                <Slider array={testimonialCards} cardWidth={cardWidth} component={component} width={width}></Slider>
            ) : width > breakpoint && width - (64 * array.length) > cardWidth * array.length ? (
                <View style={{ flex: 1, justifyContent: 'center', flexDirection: 'row', paddingBottom: "3rem" }}>
                    {testimonialCards}
                </View>
            ) : (
                <Slider array={testimonialCards} cardWidth={cardWidth} component={component} width={width}></Slider>
            )}
        </>
    );
};

export default Testimonials;
