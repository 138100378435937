import React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity, Linking } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import useViewport from '../../hooks/useViewport';
import { useNavigation } from "@react-navigation/native";
import { CustomImage } from './Components/CustomImage';
import FAQ from './Components/FAQ';
import IconCards from './Components/IconCards';
import VideoPlayer from '../VideoPlayer';
import MultipleTexts from './Components/MultipleTexts';
import Header from './Components/Header';
import PlansCards from './Components/PlansCards';
import ImageCards from './Components/ImageCards';
import Testimonials from './Components/Testimonials';
import PlansCardsWithoutFlatlist from './Components/PlansCardsWithoutFlatlist';

const PageSection = ({ pageName, sectionIndex, userId, user, plan, isAdmin, planFrequency, plansInfo, coursesInfo, firstPlanPurchase, handleOnPressButton }) => {

  const { width } = useViewport();
  const breakpoint = 1080;

  const navigation = useNavigation();

  const config = pageName.sections[sectionIndex];
  const header = config.header || null;

  const containerStyles = config.containerStyles(width) || {};
  const contentStyles = config.contentStyles ? config.contentStyles(width) : {};

  const renderContent = () => {
    const columnCount = config.columns.length;
    const columnWidth = `${100 / columnCount}%`;

    const renderColumn = (column) => {

      const columnStyles = column.style ? column.style(width, columnWidth) : {}

      return (
        <>
          <View style={columnStyles}>
            {column.items.map((component, index) => {
              switch (component.type) {
                case 'text':
                  return <Text key={index} style={component.style(width)}>{component.text}</Text>
                case 'multipleTexts':
                  return <View><MultipleTexts component={component} content={component.content} width={width}></MultipleTexts></View>
                case 'button':
                  return <TouchableOpacity key={index} style={component.buttonStyle(width)} onPress={component.onPress}>
                    <Text style={component.textStyle(width)}>{component.text}</Text>
                  </TouchableOpacity>
                case 'plansButton':
                  return plansInfo && plansInfo.length > 0 ? (<TouchableOpacity key={index} style={component.buttonStyle(width)} onPress={() => navigation.navigate("Planos", { userId, plan, firstPlanPurchase, isAdmin })}>
                    {component?.uri ? (
                      <Image
                        source={component.uri}
                        style={{ ...component.buttonStyle(width), resizeMode: "contain", width: '200px' }}
                      />
                    ) : (
                      <Text style={component.textStyle(width)}>{userId ? "VER PLANOS" : component.text}</Text>
                    )}
                  </TouchableOpacity>
                  ) : null;
                case 'image':
                  return <CustomImage key={index} source={component.uri} width={width} styles={component.styles(width)} aspectRatio={component.aspectRatio} />
                case 'FAQ':
                  return <FAQ component={component} width={width} />
                case 'iconCards':
                  return <View style={{ flexDirection: width < breakpoint ? 'column' : 'row', alignItems: "center", justifyContent: "center" }}>
                    {component.cardData.map((card, index) => (
                      <View style={{ flex: width < breakpoint ? undefined : 1 }}>
                        <IconCards
                          key={index}
                          width={width}
                          component={component}
                          card={card}
                        />
                      </View>
                    ))}
                  </View>
                case 'video':
                  return <View style={{
                    width: width < breakpoint ? "100%" : "60%",
                    aspectRatio: 16 / 9,
                    borderRadius: 25,
                    overflow: "hidden",
                  }}>
                    <VideoPlayer key={index} previewVideo={component.uri} />
                  </View>
                case 'appleStoreButton':
                  return <TouchableOpacity
                    onPress={() => {
                      Linking.openURL(component.uri)
                    }}>
                    <Image
                      source={"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Available_on_the_App_Store_%28black%29.png/320px-Available_on_the_App_Store_%28black%29.png"}
                      style={{ width: 150, height: 40, resizeMode: "cover", marginVertical: 10 }}
                    />
                  </TouchableOpacity>
                case 'googlePlayButton':
                  return <TouchableOpacity
                    onPress={() => {
                      Linking.openURL(component.uri)
                    }}>
                    <Image
                      source={"https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/320px-Google_Play_Store_badge_EN.svg.png"}
                      style={{ width: 150, height: 40, resizeMode: "cover" }}
                    />
                  </TouchableOpacity>
                default:
                  return null
              }
            })}
          </View>
          {column.items.map((component, index) => {
            switch (component.type) {
              case 'mainButton':
                return <TouchableOpacity key={index} style={component.buttonStyle(width)} onPress={() => navigation.navigate("Conteudo", { screen: "Aplicativo" })} >
                  {component?.uri ?
                    <Image
                      source={component.uri}
                      style={{ ...component.buttonStyle(width), resizeMode: "contain", width: '200px' }}
                    />
                    :
                    <Text style={component.textStyle(width)}>{component.text}</Text>
                  }
                </TouchableOpacity>
              case 'imageCards':
                return <ImageCards array={component.info} component={component} width={width} breakpoint={breakpoint} user={user}></ImageCards>
              case 'testimonials':
                return <Testimonials array={component.info} component={component} width={width} breakpoint={breakpoint} user={user}></Testimonials>
              case 'coursesCards':
                return <ImageCards array={coursesInfo} component={component} width={width} isCoursesCards={true} breakpoint={breakpoint} user={user} handleOnPressButton={handleOnPressButton}></ImageCards>
              case 'plansCards':
                return <PlansCards component={component} plansInfo={plansInfo} planFrequency={planFrequency} user={user} isAdmin={isAdmin} plan={plan} firstPlanPurchase={firstPlanPurchase} />
              case 'plansCardsWithoutFlatlist':
                return <PlansCardsWithoutFlatlist component={component} plansInfo={plansInfo} user={user} isAdmin={isAdmin} plan={plan} firstPlanPurchase={firstPlanPurchase} />
            }
          }
          )}
        </>
      )
    }

    return config.columns.map((column, index) => (
      renderColumn(column)
    ))
  };

  const renderBackground = () => {
    if (config.backgroundImage) {
      return (
        <ImageBackground source={width < breakpoint ? config.backgroundImage.mobile.uri : config.backgroundImage.web.uri} style={containerStyles}>
          {header && <Header width={width} breakpoint={breakpoint} navigation={navigation} header={header} userId={userId} user={user} />}
          <View style={contentStyles}>
            {renderContent()}
          </View>
        </ImageBackground>
      );
    } else
      if (containerStyles.backgroundColor && !config.backgroundImage) {
        return (
          <View style={[containerStyles]}>
            {header && <Header width={width} breakpoint={breakpoint} navigation={navigation} header={header} userId={userId} user={user} />}
            <View style={contentStyles}>
              {renderContent()}
            </View>
          </View>
        )
      } else if (config.gradientColors) {
        return (
          <View style={containerStyles}>
            <LinearGradient colors={config.gradientColors?.colors} style={config.gradientBackgroundStyles} start={config.gradientColors?.start} end={config.gradientColors?.end} />
            {header && <Header width={width} breakpoint={breakpoint} navigation={navigation} header={header} userId={userId} user={user} />}
            <View style={contentStyles}>
              {renderContent()}
            </View>
          </View>
        );
      }
  };

  return renderBackground()

};

export default PageSection;
