import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const IconCards = ({ component, card, width }) => {

  return (
    <View style={styles.card}>
      <TouchableOpacity
        onPress={ card.uri ? () => Linking.openURL(card.uri) : undefined}
        >
        <View style={styles.iconContainer}>
          {card.icon && <Ionicons name={card.icon} size={40} color={component.color} />}
          {card.image && <Image source={card.image} width={50} height={50} />}
        </View>
        <View >
        <Text style={component.titleStyle(width)}>{card.title}</Text>
        <Text style={component.descriptionStyle(width)}>{card.description}</Text>
        </View>
      </TouchableOpacity>
    </View >
  );
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'transparent',
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column',
    padding: 10
  },
  iconContainer: {
    borderRadius: 25,
    height: 50,
    width: 50,
    marginVertical: 10,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default IconCards;
