import React from 'react';
import { Container, Content } from './style';
import { Text } from 'react-native';
import { useCustomTheme } from '../../contexts/useCustomTheme';
import THEME from '../../config/theme';

const Privacy = (props) => {
  const { customFont } = useCustomTheme();

  return (
    <Container>
      {props.privacyData.map((element, index) => (
        <Content key={index}>
          <Text
            style={{
              color: '#454247',
              fontSize: 22,
              fontWeight: 'bold',
              marginBottom: '1rem',
              fontFamily:
                customFont && customFont.fontFamilySubtitle
                  ? customFont.fontFamilySubtitle
                  : THEME.FONTFAMILY.MEDIUM,
            }}
          >
            {element.subtitle}
          </Text>
          {element.paragraphs.map((item, subIndex) => (
            <Text
              key={subIndex}
              style={{
                color: '#454247',
                fontSize: 18,
                margin: '1rem 0rem 0.5rem 0rem',
                textAlign: 'justify',
                fontFamily:
                  customFont && customFont.fontFamilyText
                    ? customFont.fontFamilyText
                    : THEME.FONTFAMILY.REGULAR,
              }}
            >
              {item.text}
            </Text>
          ))}
        </Content>
      ))}
    </Container>
  );
};

export default Privacy;
