import styled from "styled-components/native";
import THEME from "../../config/theme";

export const Container = styled.View`
background-color: ${THEME.COLORS.BACKGROUND_ABOUT};
padding: 0.5rem;
flex:1;
`;

export const ViewSuccess = styled.View`
justify-content: center;
align-items: center;
flex:1;
`;

export const ContentIcon = styled.View`
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0.5rem 0rem;
`;

export const Wrapper= styled.View`
align-items: center;
justify-content: flex-start;
flex:6;
`;


export const Content = styled.View`
padding: 0.5rem 1rem;
margin: 0.5rem 0rem;
border-radius: 10px;
background-color: "rgba(0,0,0,0.1)";
`;

export const ViewPressable = styled.View`
align-items:flex-end;
flex:1;
`;