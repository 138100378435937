import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  addDoc,
  serverTimestamp,
  setDoc,
  doc,
  onSnapshot,
  query,
  orderBy,
  getDocs,
  deleteDoc,
  getDoc,
} from 'firebase/firestore';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import * as ImagePicker from 'expo-image-picker';
import { firestore, storage } from '../../../services/firebase';

const CommunityContext = createContext();

export const CommunityProvider = ({ children }) => {
  const [isPostModalVisible, setIsPostModalVisible] = useState(false);
  const [textContent, setTextContent] = useState('');
  const [image, setImage] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUser, setIsUser] = useState();
  const [messageImageError, setMessageImageError] = useState(false);
  const [loadingCard, setLoadingCard] = useState(true);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [post, setPost] = useState([]);

  const auth = getAuth();

  useEffect(() => {
    const fetchData = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user?.isAnonymous) {
          setIsAnonymous(true);
        } else {
          setIsAnonymous(false);
        }
      });

      await getUser(auth.currentUser.uid, setIsUser);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoadingCard(true);
        const q = query(
          collection(firestore, 'posts'),
          orderBy('createdAt', 'desc')
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const fetchedPosts = [];
          snapshot.forEach((doc) => {
            const postData = doc.data();
            const post = {
              id: doc.id,
              ...postData,
              dataLikes: [],
              dataComments: [],
            };

            const postLikesRef = collection(doc.ref, 'likes');
            onSnapshot(postLikesRef, (likesSnapshot) => {
              post.dataLikes = [];
              likesSnapshot.forEach((likeDoc) => {
                post.dataLikes.push({ id: likeDoc.id, ...likeDoc.data() });
              });
              updatePost(post);
            });

            const postCommentsRef = collection(doc.ref, 'comments');
            onSnapshot(postCommentsRef, (commentsSnapshot) => {
              post.dataComments = [];
              commentsSnapshot.forEach((commentDoc) => {
                const commentData = commentDoc.data();
                const comment = {
                  id: commentDoc.id,
                  ...commentData,
                  replies: [],
                };

                const commentRepliesRef = collection(commentDoc.ref, 'replies');
                onSnapshot(commentRepliesRef, (repliesSnapshot) => {
                  comment.replies = [];
                  repliesSnapshot.forEach((replyDoc) => {
                    comment.replies.push({
                      id: replyDoc.id,
                      ...replyDoc.data(),
                    });
                  });
                  updatePost(post);
                });

                post.dataComments.push(comment);
              });
              updatePost(post);
            });

            fetchedPosts.push(post);
          });

          setPosts(fetchedPosts);
          setLoadingCard(false);
        });
        return () => {
          unsubscribe();
        };
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, [
    handlePost,
    handleCommentSubmit,
    handleDeleteComment,
    handleDeletePost,
    handleDeleteReply,
  ]);

  const getUser = async (auth, setIsUser) => {
    const docRef = doc(firestore, 'users', auth);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) return;
    setIsUser(() => ({ id: docSnap.id, ...docSnap.data() }));
  };

  const updatePost = (updatedPost) => {
    setPosts((prevPosts) => {
      return prevPosts.map((prevPost) => {
        if (prevPost.id === updatedPost.id) {
          return updatedPost;
        }
        return prevPost;
      });
    });
  };

  const openImagePicker = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.assets[0]?.mimeType) {
        return setMessageImageError(true);
      }
      if (!result.cancelled) {
        setImage(result.assets[0].uri);
        setMessageImageError(false);
      }
    } catch (error) {
      console.error('Erro ao abrir seletor de imagem:', error);
    }
  };

  const closeModal = () => {
    setIsPostModalVisible(false);
    setTextContent('');
    setImage(null);
    setLoadingCard(false);
  };

  const handlePost = async () => {
    try {
      setLoading(true);
      if (image) {
        const imageRef = ref(
          storage,
          `community/${Math.random().toString(36).substring(2)}`
        );
        const response = await fetch(image);
        const blob = await response.blob();

        const uploadTask = uploadBytesResumable(imageRef, blob);

        uploadTask.on(
          'state_changed',
          null,
          (error) => {
            console.error('Erro durante o upload da imagem:', error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);

            createPost(url);
          }
        );
      } else {
        createPost(null);
      }
    } catch (error) {
      console.error('Erro ao criar o post:', error);
      setMessageError(true);
    }
  };

  const createPost = async (imageUrl) => {
    try {
      const newPostData = {
        authorId: auth.currentUser.uid,
        contentText: textContent,
        createdAt: serverTimestamp(),
        nameUser: isUser.Nome_Completo,
      };

      if (imageUrl) {
        newPostData.contentImage = imageUrl;
      }

      if (isUser.photo) {
        newPostData.photo = isUser.photo;
      }

      const newPostRef = await addDoc(
        collection(firestore, 'posts'),
        newPostData
      );
      const postId = newPostRef.id;

      const newPostWithId = { ...newPostData, id: postId };
      await setDoc(doc(firestore, 'posts', postId), newPostWithId);

      setPosts((prevPosts) => [newPostWithId, ...prevPosts]);
      setLoading(false);
      closeModal();
    } catch (error) {
      console.error('Erro ao salvar o post no Firestore:', error);
    }
  };


  const filterPostForId = (id) => {
    const filteredPost = posts.filter((post) => post.id === id);

    if (filteredPost.length > 0) {
      const validComments = filteredPost[0].dataComments.filter(
        (comment) => comment.createdAt && comment.createdAt.seconds
      );

      const sortedComments = validComments.sort(
        (a, b) => b.createdAt.seconds - a.createdAt.seconds
      );

      const updatedPost = { ...filteredPost[0], dataComments: sortedComments };

      setPost([updatedPost]);
      return [updatedPost];
    }

    return [];
  };

  const handleCommentSubmit = async (comment, setComment, postId) => {
    try {
      const commentData = {
        userId: auth.currentUser.uid,
        comment: comment,
        createdAt: serverTimestamp(),
        userName: isUser.Nome_Completo,
      };

      const commentRef = collection(
        doc(firestore, 'posts', postId),
        'comments'
      );
      await addDoc(commentRef, commentData);
      setComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleReplySubmit = async (parentCommentId, replyText, postId) => {
    try {
      const replyData = {
        userId: auth.currentUser.uid,
        reply: replyText,
        createdAt: serverTimestamp(),
        likes: 0,
        commentId: parentCommentId,
      };

      const commentRef = doc(
        firestore,
        'posts',
        postId,
        'comments',
        parentCommentId
      );
      const repliesCollectionRef = collection(commentRef, 'replies');
      await addDoc(repliesCollectionRef, replyData);
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  };

  const isAdminOrIsUserCurrent = (id) => {
    if (isUser.id === id || isUser.isAdmin === true) {
      return true;
    }
    return false;
  };

  const handleDeleteComment = async (commentId, id) => {
    try {
      const postRef = doc(firestore, 'posts', id);

      const commentRef = doc(postRef, 'comments', commentId);

      const repliesQuerySnapshot = await getDocs(
        collection(commentRef, 'replies')
      );

      const deleteRepliesPromises = repliesQuerySnapshot.docs.map(
        async (replyDoc) => {
          await deleteDoc(replyDoc.ref);
        }
      );

      await Promise.all(deleteRepliesPromises);

      await deleteDoc(commentRef);
    } catch (error) {
      console.error('Erro ao excluir comentário e suas respostas:', error);
    }
  };

  const handleDeleteReply = async (commentId, replyId, id) => {
    try {
      const postRef = doc(firestore, 'posts', id);
      const commentRef = doc(postRef, 'comments', commentId);
      const replyRef = collection(commentRef, 'replies');
      const specificReplyRef = doc(replyRef, replyId);
      await deleteDoc(specificReplyRef);
    } catch (error) {
      console.error('Erro ao excluir resposta:', error);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      const likesRef = collection(firestore, 'posts', postId, 'likes');
      const likesQuerySnapshot = await getDocs(likesRef);
      likesQuerySnapshot.forEach(async (likeDoc) => {
        await deleteDoc(doc(firestore, 'posts', postId, 'likes', likeDoc.id));
      });

      const commentsRef = collection(firestore, 'posts', postId, 'comments');
      const commentsQuerySnapshot = await getDocs(commentsRef);
      commentsQuerySnapshot.forEach(async (commentDoc) => {
        const repliesRef = collection(
          firestore,
          'posts',
          postId,
          'comments',
          commentDoc.id,
          'replies'
        );

        const repliesQuerySnapshot = await getDocs(repliesRef);
        repliesQuerySnapshot.forEach(async (replyDoc) => {
          await deleteDoc(
            doc(
              firestore,
              'posts',
              postId,
              'comments',
              commentDoc.id,
              'replies',
              replyDoc.id
            )
          );
        });
        await deleteDoc(
          doc(firestore, 'posts', postId, 'comments', commentDoc.id)
        );
      });

      const postDocRef = doc(firestore, 'posts', postId);
      const postDocSnapshot = await getDoc(postDocRef);

      if (!postDocSnapshot.exists()) {
        console.error('Documento do post não encontrado');
        return;
      }

      const postData = postDocSnapshot.data();
      const imgUrl = postData.contentImage;

      if (imgUrl) {
        const imagePath = imgUrl.split('%2F')[1].split('?')[0];
        const storageRef = ref(storage, `community/${imagePath}`);
        await deleteObject(storageRef);
      } else {
        console.error('URL da imagem não encontrada no documento do post');
      }
      await deleteDoc(doc(firestore, 'posts', postId));
    } catch (error) {
      console.error('Erro ao excluir o post:', error);
    }
  };

  const handleLike = async (item, auth) => {
    try {
      const filteredLikes = item.dataLikes?.some(
        (i) => i.userId === auth.currentUser.uid
      );

      if (!filteredLikes) {
        const likedRef = collection(doc(firestore, 'posts', item.id), 'likes');
        const likeData = {
          userId: auth.currentUser.uid,
          createdAt: serverTimestamp(),
          liked: true,
        };

        await addDoc(likedRef, likeData);
      } else {
        const postRef = doc(firestore, 'posts', item.id);
        const querySnapshot = await getDocs(collection(postRef, 'likes'));

        querySnapshot.forEach(async (doc) => {
          if (doc.data().userId === auth.currentUser.uid) {
            await deleteDoc(doc.ref);
          }
        });
      }
    } catch (error) {
      console.error('Error updating like:', error);
    }
  };

  const contextValues = {
    isPostModalVisible,
    setIsPostModalVisible,
    textContent,
    setTextContent,
    image,
    setImage,
    posts,
    loading,
    isUser,
    openImagePicker,
    closeModal,
    handlePost,
    isAnonymous,
    messageError,
    setMessageError,
    messageImageError,
    loadingCard,
    setPosts,
    auth,
    filterPostForId,
    post,
    handleCommentSubmit,
    handleReplySubmit,
    isAdminOrIsUserCurrent,
    handleDeleteComment,
    handleDeleteReply,
    handleDeletePost,
    handleLike,
    getUser,
  };

  return (
    <CommunityContext.Provider value={contextValues}>
      {children}
    </CommunityContext.Provider>
  );
};

export const useCommunity = () => useContext(CommunityContext);
