import React, { useEffect, useState } from "react";
import {
  Container,
  ViewModal,
  ViewTextInput,
  ViewButton,
  ContentIcon,
  ViewDescription,
  ViewSwitch,
  ViewSectionAdmin,
} from "./style";
import Footer from "../../components/Footer";
import { hasOutsideAboutPage } from "../../config/data";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  addDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  SubTitle,
  SmallText,
  FooterText,
  StandardText,
} from "../../config/theme/globalStyles";
import { firestore } from "../../services/firebase/index";
import THEME from "../../config/theme";
import Button from "../../components/Button";
// import { Cookie } from "../../components/CookieConsent";
import { View, ScrollView, Modal, TouchableOpacity, Image } from "react-native";
import { ActivityIndicator, HelperText, Switch } from "react-native-paper";
import ViewPortProvider from "../../hooks/ViewPortProvider";
import { Provider } from "react-native-paper";
import TextInput from "../../components/TextInput";
import { AlertBox } from "../../components/AlertBox";
import { AntDesign } from "@expo/vector-icons";
import CurrencyInput from "react-native-currency-input";
import { textValidator, daysValidator, linkValidator } from "../../utils";
import { getFunctions, httpsCallable } from "firebase/functions";
import Constants from "expo-constants";
import PageSection from "../../components/Section";
import { clientConfig } from '../../config/theme/sectionsStyles';
import { footerSocialData } from '../../config/data';
import { Linking } from 'react-native';

const { addPlanEnabled, projectId } = Constants.manifest.extra;

const functions = getFunctions();

const auth = getAuth();

const whatsappInfo = footerSocialData.find(obj => obj.name === "whatsapp");
const whatsappURL = whatsappInfo?.link || '';

const openWhatsApp = () => {
  if (whatsappURL) {
    Linking.openURL(whatsappURL);
  } else {
    console.error('A URL do WhatsApp não está definida ou é inválida.');
  }
};

export function About() {
  const [user, setUser] = useState("");
  const [userId, setUserId] = useState("");
  const [course, setCourse] = useState({ infos: [""] });
  const {
    infos,
  } = course;

  const [plan, setPlan] = useState("");
  const [coursesInfo, setCoursesInfo] = useState([]);
  const [plansInfo, setPlansInfo] = useState([]);
  const [firstPlanPurchase, setFirstPlanPurchase] = useState(true);
  const [planFrequency, setPlanFrequency] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [titleCourse, setTitleCourse] = useState({ value: "", error: "" });
  const [priceCourse, setPriceCourse] = useState({ value: "", error: "" });
  const [priceCourseStripe, setPriceCourseStripe] = useState();
  const [valueCourseWithRS, setValueCourseWithRS] = useState("");
  const [smallText, setSmallText] = useState("");
  const [validityCourse, setValidityCourse] = useState({ value: "", error: "" });

  const [salesPageUrl, setSalesPageUrl] = useState("");

  const [youtube, setYoutube] = useState({ value: "", error: "" });
  const [loadingSave, setLoadingSave] = useState(false);
  const [titlePlan, setTitlePlan] = useState({ value: "", error: "" });
  const [pricePlanMonthly, setPricePlanMonthly] = useState({ value: "", error: "" });
  const [pricePlanStripeMonthly, setPricePlanStripeMonthly] = useState();
  const [valuePlanWithRSMonthly, setValuePlanWithRSMonthly] = useState("");

  const [mockedProductIdMonthly, setMockedProductIdMonthly] = useState("");
  const [mockedProductIdQuarterly, setMockedProductIdQuarterly] = useState("");
  const [mockedProductIdSemiannual, setMockedProductIdSemiannual] = useState("");
  const [mockedProductIdYearly, setMockedProductIdYearly] = useState("");
  const [mockedPlanId, setMockedPlanId] = useState("");

  const [pricePlanQuarterlyAtSight, setPricePlanQuarterlyAtSight] = useState({ value: "", error: "" });
  const [pricePlanStripeQuarterlyAtSight, setPricePlanStripeQuarterlyAtSight] = useState();
  const [valuePlanWithRSQuarterlyAtSight, setValuePlanWithRSQuarterlyAtSight] = useState("");
  const [pricePlanQuarterly, setPricePlanQuarterly] = useState({ value: "", error: "" });
  const [pricePlanStripeQuarterly, setPricePlanStripeQuarterly] = useState();
  const [valuePlanWithRSQuarterly, setValuePlanWithRSQuarterly] = useState("");

  const [pricePlanSemiannualAtSight, setPricePlanSemiannualAtSight] = useState({ value: "", error: "" });
  const [pricePlanStripeSemiannualAtSight, setPricePlanStripeSemiannualAtSight] = useState();
  const [valuePlanWithRSSemiannualAtSight, setValuePlanWithRSSemiannualAtSight] = useState("");
  const [pricePlanSemiannual, setPricePlanSemiannual] = useState({ value: "", error: "" });
  const [pricePlanStripeSemiannual, setPricePlanStripeSemiannual] = useState();
  const [valuePlanWithRSSemiannual, setValuePlanWithRSSemiannual] = useState("");

  const [pricePlanYearlyAtSight, setPricePlanYearlyAtSight] = useState({ value: "", error: "" });
  const [pricePlanStripeYearlyAtSight, setPricePlanStripeYearlyAtSight] = useState();
  const [valuePlanWithRSYearlyAtSight, setValuePlanWithRSYearlyAtSight] = useState("");
  const [pricePlanYearly, setPricePlanYearly] = useState({ value: "", error: "" });
  const [pricePlanStripeYearly, setPricePlanStripeYearly] = useState();
  const [valuePlanWithRSYearly, setValuePlanWithRSYearly] = useState("");

  const [infosPlan, setInfosPlan] = useState([]);
  const [itens, setItens] = useState([{ id: 1, item: "Digitar item 1" }]);

  const [published, setPublished] = useState();
  const toggleSwitch = () => setPublished((previousState) => !previousState);
  const toggleSwitch2 = () => setIsSellOn((previousState) => !previousState);
  const [isSellOn, setIsSellOn] = useState();

  const [isReadyForSale, setIsReadyForSale] = useState(false);

  const [visible, setVisible] = useState(false);
  const hideModal = () => setVisible(false);

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [message, setMessege] = useState(null);
  const [visible1, setVisible1] = useState(false);
  const hideModal1 = () => setVisible1(false);

  const [visible2, setVisible2] = useState(false);
  const [visibleEditPlan, setVisibleEditPlan] = useState(false);

  const hideModalEditPlan = () => setVisibleEditPlan(false);

  const hideModal2 = () => setVisible2(false);

  const getUser = async (user) => {
    const docRef = doc(firestore, "users", user);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;
    if (docSnap.exists() && docSnap.data().firstPlanPurchase == false) {
      setFirstPlanPurchase(docSnap.data().firstPlanPurchase);
    }
    setUser(docSnap.data());
    setPlan(docSnap.data().plan);
    await getCourses(docSnap.data().isAdmin)
    setIsLoading(false);
  };

  const getCourses = async (isAdmin) => {
    const response = await getDocs(collection(firestore, "courses"));
    let courses = [];
    if (response.docs.length == 0) return setIsLoading(false)

    response.forEach((doc) => {
      if (isAdmin) {
        courses.push({ id: doc.id, ...doc.data() });
      } else {
        if (doc.data().archived == false && !doc.data().invisibleAbout) {
          courses.push({ id: doc.id, ...doc.data() });
        }
      }
    });
    setCoursesInfo(courses);
    setIsLoading(false);
  };

  const getPlans = async () => {
    const response = await getDocs(
      query(collection(firestore, "plans"), orderBy("order", "asc"))
    );
    let plans = [];
    let frequency = [];
    if (response.docs.length == 0) return setIsLoading(false)

    response.forEach((doc) => {
      const data = doc.data();
      if (data.isPlanFrequency == false && data.archived == false) {
        plans.push({ id: doc.id, ...data });
      } else if (data.isPlanFrequency == true) {
        frequency.push(data.planFrequency);
      }
    });
    setPlansInfo(plans);
    setPlanFrequency(frequency[0]);
    setIsLoading(false);
  };

  const showAlert = (message) => {
    setVisibleAlert(true);
    setMessege(message);
  };

  const hideAlert = (status) => {
    setVisibleAlert(status);
  };

  const addPlan = () => {
    setLoadingSave(true);
    let myData = {
      trial: 7,
      titlePlanValue: titlePlan.value,
      pricePlanStripeMonthly: pricePlanStripeMonthly,
      pricePlanStripeQuarterlyAtSight: pricePlanStripeQuarterlyAtSight,
      pricePlanStripeQuarterly: pricePlanStripeQuarterly,
      pricePlanStripeSemiannualAtSight: pricePlanStripeSemiannualAtSight,
      pricePlanStripeSemiannual: pricePlanStripeSemiannual,
      pricePlanStripeYearlyAtSight: pricePlanStripeYearlyAtSight,
      pricePlanStripeYearly: pricePlanStripeYearly,
      infosPlan: infosPlan,
      infosPlanQuarterly: infosPlan,
      infosPlanSemiannual: infosPlan,
      infosPlanYearly: infosPlan,
      valuePlanWithRSMonthly: valuePlanWithRSMonthly,
      valuePlanWithRSQuarterlyAtSight: valuePlanWithRSQuarterlyAtSight,
      valuePlanWithRSQuarterly: valuePlanWithRSQuarterly,
      valuePlanWithRSSemiannualAtSight: valuePlanWithRSSemiannualAtSight,
      valuePlanWithRSSemiannual: valuePlanWithRSSemiannual,
      valuePlanWithRSYearlyAtSight: valuePlanWithRSYearlyAtSight,
      valuePlanWithRSYearly: valuePlanWithRSYearly,
      order: plansInfo.length + 1,
      user: user
    };
    setLoadingSave(true);
    const addPlanOnStripe = httpsCallable(functions, "addPlanOnStripe");
    addPlanOnStripe(myData)
      .then((result) => {
        // Read result of the Cloud Function.
        const data = result.data;
        showAlert("Plano adicionado com sucesso!");
        setVisible2(false);
        setLoadingSave(false);
        setTitlePlan({ value: "", error: "" });
        setInfosPlan([]);
        setItens([{ id: 1, item: "Digitar item 1" }]);
        setPricePlanMonthly({ value: "", error: "" });
        setPricePlanQuarterlyAtSight({ value: "", error: "" });
        setPricePlanQuarterly({ value: "", error: "" });
        setPricePlanSemiannualAtSight({ value: "", error: "" });
        setPricePlanSemiannual({ value: "", error: "" });
        setPricePlanYearlyAtSight({ value: "", error: "" });
        setPricePlanYearly({ value: "", error: "" });

        setPricePlanStripeMonthly();
        setPricePlanStripeQuarterlyAtSight();
        setPricePlanStripeQuarterly();
        setPricePlanStripeSemiannualAtSight();
        setPricePlanStripeSemiannual();
        setPricePlanStripeYearlyAtSight();
        setPricePlanStripeYearly();

        setValuePlanWithRSMonthly("");
        setValuePlanWithRSQuarterlyAtSight("");
        setValuePlanWithRSQuarterly("");
        setValuePlanWithRSSemiannualAtSight("");
        setValuePlanWithRSSemiannual("");
        setValuePlanWithRSYearlyAtSight("");
        setValuePlanWithRSYearly("");

        getCourses();
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        // ...
      })
      .finally(() => {
        setLoadingSave(false);
      });
    // };
  };

  const editPlan = () => {
    setLoadingSave(true);
    let myData = {
      trial: 7,
      pricePlanStripeMonthly: pricePlanStripeMonthly,
      pricePlanStripeQuarterlyAtSight: pricePlanStripeQuarterlyAtSight,
      pricePlanStripeQuarterly: pricePlanStripeQuarterly,
      pricePlanStripeSemiannualAtSight: pricePlanStripeSemiannualAtSight,
      pricePlanStripeSemiannual: pricePlanStripeSemiannual,
      pricePlanStripeYearlyAtSight: pricePlanStripeYearlyAtSight,
      pricePlanStripeYearly: pricePlanStripeYearly,
      infosPlan: infosPlan,
      infosPlanQuarterly: infosPlan,
      infosPlanSemiannual: infosPlan,
      infosPlanYearly: infosPlan,
      valuePlanWithRSMonthly: valuePlanWithRSMonthly,
      valuePlanWithRSQuarterlyAtSight: valuePlanWithRSQuarterlyAtSight,
      valuePlanWithRSQuarterly: valuePlanWithRSQuarterly,
      valuePlanWithRSSemiannualAtSight: valuePlanWithRSSemiannualAtSight,
      valuePlanWithRSSemiannual: valuePlanWithRSSemiannual,
      valuePlanWithRSYearlyAtSight: valuePlanWithRSYearlyAtSight,
      valuePlanWithRSYearly: valuePlanWithRSYearly,
      productIdMonthly: mockedProductIdMonthly,
      productIdQuarterly:mockedProductIdQuarterly,
      productIdSemiannual: mockedProductIdSemiannual,
      productIdYearly:mockedProductIdYearly,
      mockedPlanId: mockedPlanId,
      user: user
    };
    setLoadingSave(true);
    const editPlanOnStripe = httpsCallable(functions, "editPlanOnStripe");
    editPlanOnStripe(myData)
      .then((result) => {
        // Read result of the Cloud Function.
        const data = result.data;
        showAlert("Plano editado com sucesso!");
        setVisibleEditPlan(false);
        setLoadingSave(false);
        setInfosPlan([]);
        setMockedPlanId("");
        setMockedProductIdMonthly("");
        setMockedProductIdQuarterly("");
        setMockedProductIdSemiannual("");
        setMockedProductIdYearly("");
        setPricePlanMonthly({ value: "", error: "" });
        setPricePlanQuarterlyAtSight({ value: "", error: "" });
        setPricePlanQuarterly({ value: "", error: "" });
        setPricePlanSemiannualAtSight({ value: "", error: "" });
        setPricePlanSemiannual({ value: "", error: "" });
        setPricePlanYearlyAtSight({ value: "", error: "" });
        setPricePlanYearly({ value: "", error: "" });

        setPricePlanStripeMonthly();
        setPricePlanStripeQuarterlyAtSight();
        setPricePlanStripeQuarterly();
        setPricePlanStripeSemiannualAtSight();
        setPricePlanStripeSemiannual();
        setPricePlanStripeYearlyAtSight();
        setPricePlanStripeYearly();

        setValuePlanWithRSMonthly("");
        setValuePlanWithRSQuarterlyAtSight("");
        setValuePlanWithRSQuarterly("");
        setValuePlanWithRSSemiannualAtSight("");
        setValuePlanWithRSSemiannual("");
        setValuePlanWithRSYearlyAtSight("");
        setValuePlanWithRSYearly("");

        getCourses();
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code + message + details);
        // ...
      })
      .finally(() => {
        setLoadingSave(false);
      });
    // };
  };

  const validation = () => {
    const titleError = textValidator(titleCourse.value);
    const priceError = textValidator(priceCourse.value);
    const daysError = daysValidator(Number(validityCourse.value));
    const youtubeError = linkValidator(youtube.value);

    if (titleError || daysError || priceError) {
      setTitleCourse({ ...titleCourse, error: titleError });
      setPriceCourse({ ...priceCourse, error: priceError });
      setValidityCourse({ ...validityCourse, error: daysError });
      setYoutube({ ...youtube, error: youtubeError });
      setLoadingSave(false);
      return;
    }
    return false;
  };

  const addCourse = () => {

    setLoadingSave(true);
    validation();
    let myData = {
      titleCourseValue: titleCourse.value,
      priceCourseStripe: priceCourseStripe.toFixed(0),
      youtubeValue: youtube.value,
      validityCourseValue: validityCourse.value,
      smallText: smallText,
      salesPageUrl: salesPageUrl,
      hasOutsidePage: salesPageUrl.length > 0 ? true : false,
      infosCourse: infos,
      valueCourseWithRS: valueCourseWithRS,
      user: user,
    };
    if (validation() === false) {

      setLoadingSave(true);
      const addProductOnStripe = httpsCallable(functions, "addProductOnStripe");
      addProductOnStripe(myData)
        .then((result) => {
          // Read result of the Cloud Function.
          const data = result.data;
          showAlert("Produto adicionado com sucesso!");
          setVisible1(false);
          setLoadingSave(false);
          setTitleCourse({ value: "", error: "" });
          setSmallText("");
          setCourse({ infos: [""] });
          setPriceCourse({ value: "", error: "" });
          setPriceCourseStripe();
          setValueCourseWithRS("");
          setValidityCourse({ value: "", error: "" });
          setYoutube({ value: "", error: "" });
          getPlans();
        })
        .catch((error) => {
          // Getting the Error details.
          const code = error.code;
          const message = error.message;
          const details = error.details;
          // ...
        })
        .finally(() => {
          setLoadingSave(false);
        });
    }
  };

  const createPlanFrequency = async () => {

    const data = [{ frequency: "Mensal", id: 1 }, { frequency: "Trimestral", id: 2 }, { frequency: "Semestral", id: 3 }, { frequency: "Anual", id: 4 }]

    const docRef = await addDoc(collection(firestore, "plans"), {
      isPlanFrequency: true,
      order: 0,
      planFrequency: data
    });
  };

  useEffect(() => {
    getCourses(false);
    getPlans();
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        getUser(user.uid);
        setUserId(user.uid)
      }
    });
  }, []);

  if (isLoading) {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
          justifyContent: "center",
        }}
        color={THEME.COLORS.PRIMARY_800}
      />
    );
  }

  const client = clientConfig.find((client) => client.name === projectId);
  const pageName = client?.pages.find((page) => page.name === "about");

  return (
    <Provider>
      <Container>
        <ViewPortProvider>
          <View>
            {pageName?.sections.map((section, index) => {
              // Check if the section has the 'coursesCards' component
              const hasCoursesCards =
                section.columns &&
                section.columns.some((column) =>
                  column.items.some((item) => item.type === 'coursesCards')
                );

              // Render the section if it doesn't have 'coursesCards' or if 'coursesCards.length > 0 and hasCoursesCards'
              if (!hasCoursesCards || (hasCoursesCards && coursesInfo.length > 0) || (hasCoursesCards && user?.isAdmin)) {
                return (
                  <PageSection
                    key={`${projectId}-section-${index}`}
                    client={client}
                    pageName={pageName}
                    sectionIndex={index}
                    userId={userId}
                    user={user}
                    firstPlanPurchase={firstPlanPurchase}
                    plan={plan}
                    isAdmin={user?.isAdmin}
                    coursesInfo={coursesInfo}
                    planFrequency={planFrequency}
                    plansInfo={plansInfo}
                    handleOnPressButton={() => setVisible1(true)}
                  />
                );
              } else {
                return null;
              }
            })}
          </View>
        </ViewPortProvider>
        {addPlanEnabled === "true" && user?.isAdmin && !hasOutsideAboutPage && (
          <ViewSectionAdmin background={THEME.COLORS.S5_BACKGROUND}>
            <Button
              title={"Adicionar novo Plano"}
              onPress={() => setVisible2(true)}
              margin="0rem 0rem 1rem 0rem"
            ></Button>
            <Button
              title={"Adicionar doc isPlanFrequency"}
              onPress={() => createPlanFrequency()}
            ></Button>
            <Button
              title={"Editar Plano existente"}
              onPress={() => setVisibleEditPlan(true)}
              margin="0rem 0rem 1rem 0rem"
            ></Button>
          </ViewSectionAdmin>
        )}
        <View>
          <Footer></Footer>
        </View>
      </Container>
      {whatsappURL &&
        <TouchableOpacity
          onPress={openWhatsApp}
          style={{ position: 'absolute', bottom: 40, right: 20 }}
        >
          <Image
            source={require('../../../assets/whatsapp-logo.svg')}
            style={{ width: 50, height: 50 }}
          />
        </TouchableOpacity>
      }
      <Modal animationType="fade" visible={visible1} onDismiss={hideModal1}>
        <ViewModal>
          <ScrollView>
            <SubTitle
              padding="0rem 0rem 0.5rem 0rem"
              color="#2e2e2e"
            >
              Adicionar Novo Produto:
            </SubTitle>
            <SmallText>
              Produtos são vendas online com pagamentos SEM recorrência, são
              pagos uma ÚNICA vez e você escolhe a VALIDADE que eles ficarão
              disponíveis para seus alunos. Pode ser uma compra válida por 1
              dia, 30 dias (1mês) ou até o prazo máximo de 365 dias (1 ano)! A
              dica aqui é você vender aulas ao vivo avulsas, séries com aulas
              gravadas e desafios, cursos online, rituais.
            </SmallText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Nome do Produto:
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Digitar nome"
              keyboardType="default"
              returnKeyType="go"
              value={titleCourse.value}
              onChangeText={(text) => {
                setTitleCourse({ value: text, error: "" });
              }}
              error={!!titleCourse.error}
            />
            <HelperText type="error" visible={titleCourse.error}>
              {titleCourse.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                Descrição do Produto:
              </SmallText>
            </ViewDescription>
            {Array.isArray(infos) && infos.map((item, index) => (
              <ViewTextInput key={index}>
                <TextInput
                  placeholder="Digitar descrição"
                  keyboardType="default"
                  returnKeyType="go"
                  value={item}
                  onChange={({ target: { value } }) => {
                    infos[index] = value
                    setCourse(prevState => {
                      return { ...prevState, infos: infos }
                    });
                  }}
                />
              </ViewTextInput>
            ))}
            <View
              style={{ flexDirection: "row", justifyContent: "space-evenly" }}
            >
              <ContentIcon onPress={() => {
                infos.push("")
                setCourse(prevState => {
                  return { ...prevState, infos: infos }
                });
              }}>
                <AntDesign
                  name="plus"
                  size={18}
                  color="#2e2e2e"
                />
                <FooterText color="#2e2e2e" padding="0.5rem">
                  Adicionar parágrafo
                </FooterText>
              </ContentIcon>
              <ContentIcon onPress={() => {
                infos.pop()
                setCourse(prevState => {
                  return { ...prevState, infos: infos }
                });
              }}>
                <AntDesign
                  name="minus"
                  size={18}
                  color="#2e2e2e"
                />
                <FooterText color="#2e2e2e" padding="0.5rem">
                  Remover parágrafo
                </FooterText>
              </ContentIcon>
            </View>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 100.00"
              error={!!priceCourse.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={priceCourse.value}
                  onChangeValue={(text) => {
                    setPriceCourse({ value: Number(text), error: "" });
                    setPriceCourseStripe(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValueCourseWithRS(value);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={priceCourse.error}>
              {priceCourse.error}
            </HelperText>

            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Validade de acesso (em dias):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 90"
              keyboardType="decimal-pad"
              returnKeyType="go"
              value={validityCourse.value}
              onChangeText={(text) => {
                setValidityCourse({
                  value: text.replace(/[^0-9]/g, ""),
                  error: "",
                });
              }}
              error={!!validityCourse.error}
            />
            <HelperText type="error" visible={validityCourse.error}>
              {validityCourse.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT} padding="1rem 0rem">
                Página de vendas do produto (opcional):
              </SmallText>
              <FooterText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
                ATENÇÃO: Caso você não tenha página de vendas específica para este produto, não adicione nada neste campo e a página será a padrão já existente no aplicativo
              </FooterText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: https://www.yourdomain.com/product1"
              keyboardType="default"
              returnKeyType="go"
              value={salesPageUrl}
              onChange={({ target: { value } }) => {
                setSalesPageUrl(value);
              }}
            />
            <ViewDescription>
              <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
                Texto em destaque:
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: ✔ Acesso válido por 1 ano"
              keyboardType="default"
              returnKeyType="go"
              value={smallText}
              onChange={({ target: { value } }) => {
                setSmallText(value);
              }}
            />
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                Vídeo de vendas:
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Colar link do youtube"
              keyboardType="default"
              returnKeyType="go"
              value={youtube.value}
              onChangeText={(text) => {
                setYoutube({ value: text, error: "" });
              }}
              error={!!youtube.error}
            />
            <HelperText type="error" visible={youtube.error}>
              {youtube.error}
            </HelperText>
            <FooterText
              color="#2e2e2e"
              textAlign="flex-start"
              margin="1rem 0rem"
            >
              * Itens obrigatórios
            </FooterText>
            <ViewButton>
              <Button
                title={"Salvar"}
                isLoading={loadingSave}
                onPress={() => addCourse()}
                margin="0rem 0.5rem"
              ></Button>
              <Button
                title={"Cancelar"}
                onPress={() => {
                  setTitleCourse({ value: "", error: "" });
                  setSmallText("");
                  setCourse({ infos: [""] });
                  setPriceCourse({ value: "", error: "" });
                  setPriceCourseStripe();
                  setValueCourseWithRS("");
                  setValidityCourse({ value: "", error: "" });
                  setYoutube({ value: "", error: "" });
                  setVisible1(false);
                }}
                margin="0rem 0.5rem"
              ></Button>
            </ViewButton>
          </ScrollView>
        </ViewModal>
      </Modal>
      <Modal animationType="fade" visible={visible2} onDismiss={hideModal2}>
        <ViewModal>
          <ScrollView>
            <SubTitle
              padding="0rem 0rem 0.5rem 0rem"
              color="#2e2e2e"
            >
              Adicionar Novo Plano:
            </SubTitle>
            <SmallText>
              Planos são vendas online com pagamentos COM recorrência e que os
              conteúdos ficarão disponíveis para seus alunos enquanto fizer
              sentido para eles serem pagantes ativos.
            </SmallText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Nome do Plano de Assinatura:
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Digitar nome"
              keyboardType="default"
              returnKeyType="go"
              value={titlePlan.value}
              onChangeText={(text) => {
                setTitlePlan({ value: text, error: "" });
              }}
              error={!!titlePlan.error}
            />
            <HelperText type="error" visible={titleCourse.error}>
              {titlePlan.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Itens incluídos no Plano:
              </SmallText>
            </ViewDescription>
            {itens.map((item, index) => (
              <ViewTextInput key={item.id}>
                <TextInput
                  placeholder={item.item}
                  keyboardType="default"
                  returnKeyType="go"
                  value={infosPlan[index]}
                  onChange={({ target: { value } }) => {
                    infosPlan[index] = value;
                  }}
                />
              </ViewTextInput>
            ))}
            <View
              style={{ flexDirection: "row", justifyContent: "space-evenly" }}
            >
              <ContentIcon
                onPress={() => {
                  setItens([
                    ...itens,
                    {
                      id: itens.length + 1,
                      item: `Digitar item ${itens.length + 1}`,
                    },
                  ]);
                }}
              >
                <AntDesign
                  name="plus"
                  size={18}
                  color="#2e2e2e"
                />
                <FooterText color="#2e2e2e" padding="0.5rem">
                  Adicionar Item
                </FooterText>
              </ContentIcon>
              <ContentIcon
                onPress={() => {
                  setItens(itens.slice(0, itens.length - 1));
                }}
              >
                <AntDesign
                  name="minus"
                  size={18}
                  color="#2e2e2e"
                />
                <FooterText color="#2e2e2e" padding="0.5rem">
                  Remover Item
                </FooterText>
              </ContentIcon>
            </View>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Mensal (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 120.00"
              error={!!pricePlanMonthly.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanMonthly.value}
                  onChangeValue={(text) => {
                    setPricePlanMonthly({ value: Number(text), error: "" });
                    setPricePlanStripeMonthly(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSMonthly(`${value}/mês`);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanMonthly.error}>
              {pricePlanMonthly.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Trimestral à vista (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 300.00"
              error={!!pricePlanQuarterlyAtSight.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanQuarterlyAtSight.value}
                  onChangeValue={(text) => {
                    setPricePlanQuarterlyAtSight({
                      value: Number(text),
                      error: "",
                    });
                    setPricePlanStripeQuarterlyAtSight(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSQuarterlyAtSight(`${value}/trimestre`);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanQuarterlyAtSight.error}>
              {pricePlanQuarterlyAtSight.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Trimestral (em 3x) (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 108.00"
              error={!!pricePlanQuarterly.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanQuarterly.value}
                  onChangeValue={(text) => {
                    setPricePlanQuarterly({ value: Number(text), error: "" });
                    setPricePlanStripeQuarterly(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSQuarterly(value);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanQuarterly.error}>
              {pricePlanQuarterly.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Semestral à vista (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 480.00"
              error={!!pricePlanSemiannualAtSight.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanSemiannualAtSight.value}
                  onChangeValue={(text) => {
                    setPricePlanSemiannualAtSight({
                      value: Number(text),
                      error: "",
                    });
                    setPricePlanStripeSemiannualAtSight(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSSemiannualAtSight(`${value}/semestre`);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanSemiannualAtSight.error}>
              {pricePlanSemiannualAtSight.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Semestral (em 6x) (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 85.00"
              error={!!pricePlanSemiannual.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanSemiannual.value}
                  onChangeValue={(text) => {
                    setPricePlanSemiannual({ value: Number(text), error: "" });
                    setPricePlanStripeSemiannual(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSSemiannual(value);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanSemiannual.error}>
              {pricePlanSemiannual.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Anual à vista (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 700.00"
              error={!!pricePlanYearlyAtSight.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanYearlyAtSight.value}
                  onChangeValue={(text) => {
                    setPricePlanYearlyAtSight({
                      value: Number(text),
                      error: "",
                    });
                    setPricePlanStripeYearlyAtSight(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSYearlyAtSight(`${value}/ano`);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanYearlyAtSight.error}>
              {pricePlanYearlyAtSight.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Anual (em 12x) (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 62.00"
              error={!!pricePlanYearly.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanYearly.value}
                  onChangeValue={(text) => {
                    setPricePlanYearly({ value: Number(text), error: "" });
                    setPricePlanStripeYearly(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSYearly(value);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanYearly.error}>
              {pricePlanYearly.error}
            </HelperText>

            <FooterText
              color="#2e2e2e"
              textAlign="flex-start"
              margin="1rem 0rem"
            >
              * Itens obrigatórios
            </FooterText>
            <ViewButton>
              <Button
                title={"Salvar"}
                isLoading={loadingSave}
                onPress={() => addPlan()}
                margin="0rem 0.5rem"
              ></Button>
              <Button
                title={"Cancelar"}
                onPress={() => {
                  setItens([{ id: 1, item: "Digitar item 1" }]);
                  setTitlePlan({ value: "", error: "" });
                  setInfosPlan([]);
                  setPricePlanMonthly({ value: "", error: "" });
                  setPricePlanStripeMonthly();
                  setValuePlanWithRSMonthly("");

                  setPricePlanQuarterlyAtSight({ value: "", error: "" });
                  setPricePlanStripeQuarterlyAtSight();
                  setValuePlanWithRSQuarterlyAtSight("");
                  setPricePlanQuarterly({ value: "", error: "" });
                  setPricePlanStripeQuarterly();
                  setValuePlanWithRSQuarterly("");

                  setPricePlanSemiannualAtSight({ value: "", error: "" });
                  setPricePlanStripeSemiannualAtSight();
                  setValuePlanWithRSSemiannualAtSight("");
                  setPricePlanSemiannual({ value: "", error: "" });
                  setPricePlanStripeSemiannual();
                  setValuePlanWithRSSemiannual("");

                  setPricePlanYearlyAtSight({ value: "", error: "" });
                  setPricePlanStripeYearlyAtSight();
                  setValuePlanWithRSYearlyAtSight("");
                  setPricePlanYearly({ value: "", error: "" });
                  setPricePlanStripeYearly();
                  setValuePlanWithRSYearly("");

                  setVisible2(false);
                }}
                margin="0rem 0.5rem"
              ></Button>
            </ViewButton>
          </ScrollView>
        </ViewModal>
      </Modal>

      <Modal animationType="fade" visible={visibleEditPlan} onDismiss={hideModalEditPlan}>
        <ViewModal>
          <ScrollView>
            <SubTitle
              padding="0rem 0rem 0.5rem 0rem"
              color="#2e2e2e"
            >
              Editar Plano Existente:
            </SubTitle>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * PlanId na Firestore do Plano:
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Digitar planId"
              keyboardType="default"
              returnKeyType="go"
              value={mockedPlanId}
              onChangeText={(text) => {
                setMockedPlanId(text);
              }}
            />
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * ProductIdMonthly na Firestore do Plano:
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Digitar ProductIdMonthly"
              keyboardType="default"
              returnKeyType="go"
              value={mockedProductIdMonthly}
              onChangeText={(text) => {
                setMockedProductIdMonthly(text);
              }}
            />
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * ProductIdQuarterly na Firestore do Plano:
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Digitar ProductIdQuarterly"
              keyboardType="default"
              returnKeyType="go"
              value={mockedProductIdQuarterly}
              onChangeText={(text) => {
                setMockedProductIdQuarterly(text);
              }}
            />
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * ProductIdSemiannual na Firestore do Plano:
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Digitar ProductIdSemiannual"
              keyboardType="default"
              returnKeyType="go"
              value={mockedProductIdSemiannual}
              onChangeText={(text) => {
                setMockedProductIdSemiannual(text);
              }}
            />
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * ProductIdYearly na Firestore do Plano:
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Digitar ProductIdYearly"
              keyboardType="default"
              returnKeyType="go"
              value={mockedProductIdYearly}
              onChangeText={(text) => {
                setMockedProductIdYearly(text);
              }}
            />
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Mensal (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 120.00"
              error={!!pricePlanMonthly.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanMonthly.value}
                  onChangeValue={(text) => {
                    setPricePlanMonthly({ value: Number(text), error: "" });
                    setPricePlanStripeMonthly(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSMonthly(`${value}/mês`);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanMonthly.error}>
              {pricePlanMonthly.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Trimestral à vista (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 300.00"
              error={!!pricePlanQuarterlyAtSight.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanQuarterlyAtSight.value}
                  onChangeValue={(text) => {
                    setPricePlanQuarterlyAtSight({
                      value: Number(text),
                      error: "",
                    });
                    setPricePlanStripeQuarterlyAtSight(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSQuarterlyAtSight(`${value}/trimestre`);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanQuarterlyAtSight.error}>
              {pricePlanQuarterlyAtSight.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Trimestral (em 3x) (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 108.00"
              error={!!pricePlanQuarterly.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanQuarterly.value}
                  onChangeValue={(text) => {
                    setPricePlanQuarterly({ value: Number(text), error: "" });
                    setPricePlanStripeQuarterly(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSQuarterly(value);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanQuarterly.error}>
              {pricePlanQuarterly.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Semestral à vista (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 480.00"
              error={!!pricePlanSemiannualAtSight.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanSemiannualAtSight.value}
                  onChangeValue={(text) => {
                    setPricePlanSemiannualAtSight({
                      value: Number(text),
                      error: "",
                    });
                    setPricePlanStripeSemiannualAtSight(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSSemiannualAtSight(`${value}/semestre`);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanSemiannualAtSight.error}>
              {pricePlanSemiannualAtSight.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Semestral (em 6x) (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 85.00"
              error={!!pricePlanSemiannual.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanSemiannual.value}
                  onChangeValue={(text) => {
                    setPricePlanSemiannual({ value: Number(text), error: "" });
                    setPricePlanStripeSemiannual(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSSemiannual(value);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanSemiannual.error}>
              {pricePlanSemiannual.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Anual à vista (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 700.00"
              error={!!pricePlanYearlyAtSight.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanYearlyAtSight.value}
                  onChangeValue={(text) => {
                    setPricePlanYearlyAtSight({
                      value: Number(text),
                      error: "",
                    });
                    setPricePlanStripeYearlyAtSight(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSYearlyAtSight(`${value}/ano`);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanYearlyAtSight.error}>
              {pricePlanYearlyAtSight.error}
            </HelperText>
            <ViewDescription>
              <SmallText textAlign="flex-start" color="#2e2e2e">
                * Valor Anual (em 12x) (R$):
              </SmallText>
            </ViewDescription>
            <TextInput
              placeholder="Ex.: 62.00"
              error={!!pricePlanYearly.error}
              render={(props) => (
                <CurrencyInput
                  {...props}
                  prefix="R$"
                  delimiter=","
                  separator="."
                  precision={2}
                  value={pricePlanYearly.value}
                  onChangeValue={(text) => {
                    setPricePlanYearly({ value: Number(text), error: "" });
                    setPricePlanStripeYearly(Number(text) * 100);
                  }}
                  onChangeText={(value) => {
                    setValuePlanWithRSYearly(value);
                  }}
                />
              )}
            />
            <HelperText type="error" visible={pricePlanYearly.error}>
              {pricePlanYearly.error}
            </HelperText>

            <FooterText
              color="#2e2e2e"
              textAlign="flex-start"
              margin="1rem 0rem"
            >
              * Itens obrigatórios
            </FooterText>
            <ViewButton>
              <Button
                title={"Salvar"}
                isLoading={loadingSave}
                onPress={() => editPlan()}
                margin="0rem 0.5rem"
              ></Button>
              <Button
                title={"Cancelar"}
                onPress={() => {
                  setInfosPlan([]);
                  setPricePlanMonthly({ value: "", error: "" });
                  setPricePlanStripeMonthly();
                  setValuePlanWithRSMonthly("");

                  setMockedPlanId("");
                  setMockedProductIdMonthly("");
                  setMockedProductIdQuarterly("");
                  setMockedProductIdSemiannual("");
                  setMockedProductIdYearly("");

                  setPricePlanQuarterlyAtSight({ value: "", error: "" });
                  setPricePlanStripeQuarterlyAtSight();
                  setValuePlanWithRSQuarterlyAtSight("");
                  setPricePlanQuarterly({ value: "", error: "" });
                  setPricePlanStripeQuarterly();
                  setValuePlanWithRSQuarterly("");

                  setPricePlanSemiannualAtSight({ value: "", error: "" });
                  setPricePlanStripeSemiannualAtSight();
                  setValuePlanWithRSSemiannualAtSight("");
                  setPricePlanSemiannual({ value: "", error: "" });
                  setPricePlanStripeSemiannual();
                  setValuePlanWithRSSemiannual("");

                  setPricePlanYearlyAtSight({ value: "", error: "" });
                  setPricePlanStripeYearlyAtSight();
                  setValuePlanWithRSYearlyAtSight("");
                  setPricePlanYearly({ value: "", error: "" });
                  setPricePlanStripeYearly();
                  setValuePlanWithRSYearly("");

                  setVisibleEditPlan(false);
                }}
                margin="0rem 0.5rem"
              ></Button>
            </ViewButton>
          </ScrollView>
        </ViewModal>
      </Modal>






      <Modal animationType="fade" visible={visible} onDismiss={hideModal}>
        <ViewModal>
          <ScrollView>
            <SubTitle
              padding="0rem 0rem 0.5rem 0rem"
              color="#2e2e2e"
            >
              Editar Plano:
            </SubTitle>
            <SmallText textAlign="flex-start">
              • O preço e o nome do plano não podem ser alterados por aqui, mas
              caso seja necessário, entre em contato com a gente!
            </SmallText>
            <SmallText textAlign="flex-start">
              • Caso você ainda esteja editando as informações do seu plano,
              mantenha-o arquivado. Dessa forma, ele não irá aparecer na página
              de vendas do seu app.
            </SmallText>
            <SmallText textAlign="flex-start" margin="0rem 0rem 1rem 0rem">
              • Quando o plano estiver pronto, você poderá publicá-lo e,
              automaticamente, ele estará habilitado para a venda! Caso você
              queira apenas divulgar seu plano e habilitar a venda apenas em
              datas específicas, desabilite o botão "desabilitar venda".
            </SmallText>
            <TextInput
              label="Nome do produto"
              keyboardType="default"
              returnKeyType="go"
              value="Title Fake"
            />
            <ViewSwitch>
              <StandardText color={THEME.COLORS.TEXT_ABOUT} padding="0.5rem">
                Publicar:
              </StandardText>
              <Switch
                trackColor={{
                  false: "#767577",
                  true: THEME.COLORS.PRIMARY_900,
                }}
                thumbColor={published ? THEME.COLORS.PRIMARY_800 : "#f4f3f4"}
                ios_backgroundColor="#3e3e3e"
                activeThumbColor={THEME.COLORS.PRIMARY_800}
                onValueChange={toggleSwitch}
                value={published}
              />
            </ViewSwitch>
            {published == true ? (
              <>
                <ViewSwitch>
                  <StandardText
                    color="#2e2e2e"
                    padding="0.5rem"
                  >
                    Habilitar venda:
                  </StandardText>
                  <Switch
                    trackColor={{
                      false: "#767577",
                      true: THEME.COLORS.PRIMARY_900,
                    }}
                    thumbColor={
                      published ? THEME.COLORS.PRIMARY_800 : "#f4f3f4"
                    }
                    ios_backgroundColor="#3e3e3e"
                    activeThumbColor={THEME.COLORS.PRIMARY_800}
                    onValueChange={toggleSwitch2}
                    value={isSellOn}
                  />
                </ViewSwitch>
              </>
            ) : (
              <></>
            )}
            {isReadyForSale == true ? (
              <StandardText color="#2e2e2e" padding="0.5rem">
                Esse plano não está disponível para venda ainda! Por favor,
                aguarde uns minutos e tente novamente.
              </StandardText>
            ) : (
              <></>
            )}
            <ViewButton>
              <Button
                title={"Salvar"}
                isLoading={loadingSave}
                onPress={() => { addPlan() }}
                margin="0rem 0.5rem"
              ></Button>
              <Button
                title={"Cancelar"}
                onPress={() => setVisible2(false)}
                margin="0rem 0.5rem"
              ></Button>
            </ViewButton>
          </ScrollView>
        </ViewModal>
      </Modal>
      {visibleAlert && (
        <AlertBox
          message={message}
          visible={visibleAlert}
          onClose={hideAlert}
          leftButtonFunction={hideAlert}
          leftButton={"OK"}
        ></AlertBox>
      )}
    </Provider>
  )
}
