import styled from 'styled-components/native';
import { RFValue } from "react-native-responsive-fontsize";


export const ContainerCommunity = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_MAIN};
`;

export const Container = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_MAIN};
  justify-content: center;
`;

export const ViewTextInput = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    maxHeight: "8rem",
  },
})`  
`;


export const Touch = styled.TouchableOpacity`
  position: absolute !important;
  bottom: ${RFValue(10)}px;
  right: ${RFValue(20)}px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_900};
  padding: 8px;
  border-radius: 10px;
  z-index: 999;
`;

export const ModalBackground = styled.View`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 24px;
  justify-content: flex-start;
  align-items: center;
`;

export const ModalContainer = styled.View`
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_MAIN};
  padding: 20px;
  border-radius: 10px;
  width: ${({ windowWidth }) => (windowWidth > 768 ? '40%' : '80%')};
  max-width: 400px;
`;

export const ModalContent = styled.View`
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_MAIN};
  padding: 20px;
  border-radius: 10px;
  width: 100%;
`;

export const ContainerModalTitle = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const ModalTitle = styled.Text`
  color: ${({ theme }) => theme.COLORS.TEXT_MAIN};
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.FONTSIZE.MEDIUM}px;
`;

export const SelectImageBtn = styled.TouchableOpacity`
  align-items: center;
  margin-bottom: ${RFValue(10)}px;
`;

export const SelectImageText = styled.Text`
  color: ${({ theme }) => theme.COLORS.TEXT_MAIN};
`;

export const SelectedImage = styled.Image`
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
`;

export const Input = styled.TextInput`
  border-width: 1px;
  border-color: ${({ theme }) => theme.COLORS.TEXT_MAIN};
  border-radius: 5px;
  padding: ${RFValue(10)}px;
  margin-vertical: ${RFValue(10)}px;
  color: ${({ theme }) => theme.COLORS.TEXT_MAIN};
`;

export const ButtonContainer = styled.View`
  flex-direction: row;
  justify-content: space-around;
  margin-vertical: 1rem;
`;

export const MessageCommunity = styled.Text`
  color: ${({ theme }) => theme.COLORS.TEXT_MAIN};
  text-align: center;
  margin: auto;
  font-size: ${({ theme }) => theme.FONTSIZE.MEDIUM}px;
`;
