import React, { useState } from 'react';
import { View, ScrollView, Text, TouchableOpacity, Modal } from 'react-native';
import { ContentIcon, ViewButton } from '../styles'
import Slider from '../../Slider';
import { ImageUpload } from "../../ImagePicker";
import { Card } from "react-native-paper";
import { FooterText } from '../../../config/theme/globalStyles';
import THEME from '../../../config/theme';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import { doc, updateDoc } from 'firebase/firestore';
import Button from "../../Button";

const ImageCard = ({ item, index, component, width, isCoursesCards, user }) => {
    const navigation = useNavigation();
    const [visible, setVisible] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);

    const handleOnImageUploadCompleted = async (backgroundImage) => {
        const courseRef = doc(firestore, "courses", selectedCourse?.id);
        await updateDoc(courseRef, {
            backgroundImage,
        });
    };

    return (
        <Card key={index} style={component.cardStyle(width)}>
            <Card.Cover source={{ uri: item.backgroundImage?.url || item.image }} style={component.cardCoverStyle(width)} />
            <Card.Content>
                <Text style={component.titleStyle(width)} numberOfLines={isCoursesCards? 2 : undefined}>{item.title}</Text>
                <Text style={component.infoStyle(width)} numberOfLines={isCoursesCards ? 5 : undefined}>{item.infos}</Text>
                {isCoursesCards && (
                    <Text style={component.priceStyle(width)}>
                        {item.price}
                    </Text>
                )}
                <Card.Actions>
                    {isCoursesCards ?
                        user?.isAdmin ? (
                            <ViewButton>
                                <ContentIcon
                                    onPress={() => {
                                        setVisible(true);
                                        setSelectedCourse(item);
                                    }}
                                >
                                    <Feather
                                        name="image"
                                        size={24}
                                        color={THEME.COLORS.TEXT_ABOUT}
                                    />
                                    <FooterText
                                        color={THEME.COLORS.TEXT_ABOUT}
                                        margin="0.5rem 0rem"
                                    >
                                        Alterar Imagem
                                    </FooterText>
                                </ContentIcon>
                                <ContentIcon
                                    onPress={() => {
                                        navigation.navigate("Produto", {
                                            courseId: item?.id,
                                        })
                                    }}
                                >
                                    <Feather
                                        name="edit-3"
                                        size={24}
                                        color={THEME.COLORS.TEXT_ABOUT}
                                    />
                                    <FooterText
                                        color={THEME.COLORS.TEXT_ABOUT}
                                        margin="0.5rem 0rem"
                                    >
                                        Editar Informações
                                    </FooterText>
                                </ContentIcon>
                            </ViewButton>
                        ) : (
                            <Button
                                title={"Saiba mais"}
                                width="100%"
                                borderRadius={component.buttonStyle(width).borderRadius}
                                colorbutton={component.buttonStyle(width).backgroundColor}
                                colortitle={component.textStyle(width).color}
                                fontFamily={component.textStyle(width).fontFamily}
                                fontSize={component.textStyle(width).fontSize}
                                onPress={() => navigation.navigate("Produto", { courseId: item.id })}
                            ></Button>
                        ) : null}
                </Card.Actions>
            </Card.Content>
            <Modal visible={visible} onDismiss={() => setVisible(false)}>
                <ImageUpload
                    onClose={() => setVisible(false)}
                    onUploadCompleted={handleOnImageUploadCompleted}
                    metadata={{
                        databasePath: `courses/${selectedCourse?.id}`,
                        databasePropName: "backgroundImage",
                    }}
                />
            </Modal>
        </Card>
    );
};

const ImageCards = ({ array, component, width, isCoursesCards, breakpoint, user, handleOnPressButton }) => {


    const cardWidth = parseInt(component.cardStyle(width).width, 10) * 16; // transform unit in "rem" to pixel

    const imageCards = array.map((item, index) => (
        <ImageCard key={index} item={item} index={index} component={component} width={width} isCoursesCards={isCoursesCards} user={user} />
    ));

    return (
        <>
            {user?.isAdmin && (user?.planType === "growth" || user?.planType === "premium") && isCoursesCards && (
                <Button
                    title={"Adicionar novo Produto"}
                    onPress={handleOnPressButton}
                    buttonStyle={{
                        padding: '12px',
                        justifyContent: 'center',
                        borderRadius: 10,
                        alignSelf: 'center',
                        backgroundColor: THEME.COLORS.PRIMARY_900,
                    }}
                    textStyle={{
                        color: "white",
                        fontFamily: THEME.FONTFAMILY.BOLD,
                        fontSize: THEME.FONTFAMILY.MEDIUM
                    }}
                    margin="1rem"
                ></Button>
            )}
            {width < breakpoint ? (
                <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{ width: '100%', paddingBottom: "3rem" }}>
                    {imageCards}
                </ScrollView>
            ) : width > breakpoint && width - 64 - (32 * array.length) > cardWidth * array.length ? (
                <View style={{ flex: 1, justifyContent: 'center', flexDirection: 'row', paddingBottom: "3rem" }}>
                    {imageCards}
                </View>
            ) : (
                <Slider array={imageCards} cardWidth={cardWidth} component={component} width={width}></Slider>
            )}
        </>
    );
};

export default ImageCards;
