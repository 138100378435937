import { ItemForm, TextAndTooltip } from "../../Brand/style";
import PickerColorHex from "../../../components/PickerColorHex";
import { UploadImages } from "../../../components/UploadImages";
import { TooltipCustom } from "../../../components/Tooltip";
import { useCustomLogin } from "../useCustomLogin";
import { StandardText, SmallText } from '../../../config/theme/globalStyles'
import { View } from 'react-native'
import { TextInput } from 'react-native-paper';

const FormCustomLogin = () => {
  const {
    backgroundColor,
    setBackgroundColor,
    textColor,
    setTextColor,
    backgroundButtonColor,
    setBackgroundButtonColor,
    textButtonColor,
    setTextButtonColor,
    show,
    handleShowPicker,
    textAbout,
    setTextAbout
  } = useCustomLogin();


  return (
    <>
      <ItemForm style={{ marginTop: "1rem" }}>
        <TextAndTooltip>
          <StandardText textAlign="left" color="black">
            Logo (png)
          </StandardText>
          <TooltipCustom
            text="Tamanho(px): recomendado usar 300x300 e limitado a 2MB. Formato: apenas imagens no formato .png são aceitas."
          />
        </TextAndTooltip>
        <UploadImages fileType={"logoAuth"} />
      </ItemForm>

      <ItemForm>
        <TextAndTooltip>
          <StandardText textAlign="left" color="black">
            Imagem lateral (png)
          </StandardText>
          <TooltipCustom
            text="Tamanho(px): recomendado usar 980x1289 e limitado a 2MB. Formato: apenas imagens no formato .png são aceitas."
          />
        </TextAndTooltip>
        <UploadImages fileType={"imageAuth"} />
      </ItemForm>

      <StandardText textAlign="left" margin="1rem 0rem" color="black">
        Cores gerais:
      </StandardText>
      <ItemForm>
        <SmallText color="black">
          Cor de fundo
        </SmallText>

        <PickerColorHex
          colorName={backgroundColor}
          props={{ color: backgroundColor, onChange: setBackgroundColor }}
          setShow={() =>
            handleShowPicker("showBackgroundColor", !show.showBackgroundColor)
          }
          isShowPicker={show.showBackgroundColor}
          setChange={setBackgroundColor}
        />
      </ItemForm>
      <ItemForm>
        <SmallText color="black">
          Cor dos textos
        </SmallText>

        <PickerColorHex
          colorName={textColor}
          props={{ color: textColor, onChange: setTextColor }}
          setShow={() => handleShowPicker("showTextColor", !show.showTextColor)}
          isShowPicker={show.showTextColor}
          setChange={setTextColor}
        />
      </ItemForm>

      <ItemForm>
        <SmallText color="black">
          Cor de fundo do botão
        </SmallText>

        <PickerColorHex
          colorName={backgroundButtonColor}
          props={{
            color: backgroundButtonColor,
            onChange: setBackgroundButtonColor,
          }}
          setShow={() =>
            handleShowPicker(
              "showBackgroundButtonColor",
              !show.showBackgroundButtonColor
            )
          }
          isShowPicker={show.showBackgroundButtonColor}
          setChange={setBackgroundButtonColor}
        />
      </ItemForm>
      <ItemForm>
        <SmallText color="black">
          Cor do texto do botão
        </SmallText>

        <PickerColorHex
          iconPosition="14.5rem"
          bottom="0px"
          colorName={textButtonColor}
          props={{
            color: textButtonColor,
            onChange: setTextButtonColor,
          }}
          setShow={() =>
            handleShowPicker("showTextButtonColor", !show.showTextButtonColor)
          }
          isShowPicker={show.showTextButtonColor}
          setChange={setTextButtonColor}
        />
      </ItemForm>

      <StandardText textAlign="left" color="black" margin="1rem 0rem">
        Customização da tela inicial do app mobile
      </StandardText>
      <ItemForm>
        <TextAndTooltip>
          <SmallText color="black">
            Imagem de abertura do app mobile (png)
          </SmallText>
          <TooltipCustom
            text="Imagem usada na abertura do aplicativo mobile. Tamanho(px): recomendado usar 980x1289 e limitado a 2MB. Formato: apenas imagens no formato .png são aceitas."
          />
        </TextAndTooltip>
        <UploadImages fileType={"imageAbout"} />
      </ItemForm>
      <View style={{ marginVertical: '8px' }}>
        <SmallText textAlign="left" color='black'>
          Texto sobre a imagem (opcional):
        </SmallText>
        <TextInput
          placeholder="Escreva o texto aqui"
          style={{
            backgroundColor: '#fff',
            height: 32,
            zIndex: 0,
            marginLeft: '0.2rem',
            borderBottomWidth: '1px',
            borderBottomColor: '#B1B1B1',
            textAlignVertical: 'top',
            borderColor: 'none',
          }}
          contentStyle={{
            paddingHorizontal: '0px',
            border: 'none',
            minHeight: '1.5rem',
          }}
          theme={{
            colors: {
              outline: '#fff',
              primary: '#B1B1B1',
            },
          }}
          textColor="#000"
          value={textAbout}
          onChangeText={(text) => setTextAbout(text)}
        />
      </View>
    </>
  );
};

export default FormCustomLogin;
