import React, { useEffect, useState } from 'react';
import { SafeAreaView, Pressable, View } from 'react-native';
import {
  doc,
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  deleteDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import THEME from '../../../config/theme';
import { useNavigation, useRoute } from '@react-navigation/native';

import Header from '../../../components/Header';
import * as S from './styles';
import { useCommunity } from '../ContextCommunity/CommunityContext';
import { ActivityIndicator, Provider } from 'react-native-paper';
import CardCommunity from '../CardCommunity';
import { Feather, FontAwesome } from '@expo/vector-icons';
import TextInput from '../../../components/TextInput';
import CommentItem from '../../../components/VideoComments/CommentItem';
import { FlatList } from 'react-native';
import { useCustomTheme } from '../../../contexts/useCustomTheme';

const PostCommunity = () => {
  const {
    filterPostForId,
    auth,
    posts,
    post,
    handleCommentSubmit,
    handleReplySubmit,
    isAdminOrIsUserCurrent,
    handleDeleteComment,
    handleDeleteReply,
    handleDeletePost,
    handleLike,
    getUser,
  } = useCommunity();
  const navigation = useNavigation();
  const [isUser, setIsUser] = useState(null);
  const route = useRoute();
  const [loading, setLoading] = useState(true);

  const [comment, setComment] = useState('');
  const [isReply, setIsReply] = useState(false);
  const [dataReply, setDataReply] = useState(null);

  const { customColor, customFont } = useCustomTheme();

  useEffect(() => {
    getUser(auth.currentUser.uid, setIsUser);
    const postId = route.params.id;
    filterPostForId(postId);
    setLoading(false);
  }, [posts, route.params.id]);

  if (loading) {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          backgroundColor:
            customColor && customColor.background
              ? customColor.background.auth
              : THEME.COLORS.BACKGROUND_ABOUT,
          justifyContent: 'center',
        }}
        color={
          customColor && customColor.primary
            ? customColor.primary
            : THEME.COLORS.PRIMARY_800
        }
      />
    );
  }

  const submitReply = (dataReply, comment, id) => {
    handleReplySubmit(dataReply, comment, id);
    setIsReply(false);
    setComment('');
  };

  const idPost = route.params.id;

  return (
    <Provider>
      <SafeAreaView
        style={{
          backgroundColor:
            customColor && customColor.background?.app
              ? customColor.background.app
              : THEME.COLORS.BACKGROUND_MAIN,
          minHeight: '100%',
        }}
      >
        <View>
          <Header
            onPress={() => {
              navigation.navigate('Community');
            }}
            user={isUser}
          />
        </View>
        <S.CardContainer>
          <CardCommunity
            posts={post}
            handleLike={handleLike}
            user={isUser}
            handleDeletePost={handleDeletePost}
            isRoute={route.params.id}
          />
        </S.CardContainer>

        {
          <S.ViewComment
            style={{
              backgroundColor:
                customColor && customColor.background?.app
                  ? customColor.background.app
                  : THEME.COLORS.BACKGROUND_MAIN,
            }}
          >
            <FlatList
              data={post[0]?.dataComments || []}
              keyExtractor={(item, index) => item.id || index.toString()}
              renderItem={({ item }) => (
                <CommentItem
                  login={isUser}
                  comment={item}
                  editPlaceholder={() => {}}
                  setIsLoading={() => {}}
                  community={route.name.includes('Community')}
                  handleReplySubmit={handleReplySubmit}
                  handleDeleteComment={handleDeleteComment}
                  handleDeleteReply={handleDeleteReply}
                  changeReply={setIsReply}
                  getDataReply={setDataReply}
                  isAdminOrIsUserCurrent={isAdminOrIsUserCurrent}
                  idPost={idPost}
                />
              )}
            />
          </S.ViewComment>
        }

        {
          <S.CommentContainer
            style={{
              backgroundColor:
                customColor && customColor.background?.app
                  ? customColor.background.app
                  : THEME.COLORS.BACKGROUND_MAIN,
            }}
          >
            <Feather
              name="user"
              size={22}
              color={
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : THEME.COLORS.TEXT_MAIN
              }
            />
            <TextInput
              placeholder={
                isReply
                  ? `Responder ${dataReply?.userName}`
                  : 'Faça um comentário'
              }
              value={comment}
              onChangeText={setComment}
              placeholderTextColor="#7F7F7F"
              width={'90%'}
              fontFamily={
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.REGULAR
              }
            />

            <Pressable
              onPress={() =>
                isReply
                  ? submitReply(dataReply.id, comment, route.params.id)
                  : handleCommentSubmit(comment, setComment, route.params.id)
              }
            >
              <FontAwesome
                name="send-o"
                size={18}
                color="white"
                style={{
                  backgroundColor:
                    customColor && customColor.primary
                      ? customColor.primary
                      : THEME.COLORS.PRIMARY_900,
                  padding: '0.4rem',
                  borderRadius: '5px',
                }}
              />
            </Pressable>

            {isReply && (
              <Pressable onPress={() => setIsReply(false)}>
                <FontAwesome
                  name="close"
                  size={18}
                  color="white"
                  style={{
                    backgroundColor:
                      customColor && customColor.primary
                        ? customColor.primary
                        : THEME.COLORS.PRIMARY_900,
                    padding: '0.4rem',
                    borderRadius: '5px',
                  }}
                />
              </Pressable>
            )}
          </S.CommentContainer>
        }
      </SafeAreaView>
    </Provider>
  );
};

export default PostCommunity;
