import { createContext, useContext } from "react";
import { useGetTheme } from "../api/Brand";

const UseCustomTheme = createContext({});

export const CustomThemeProvider = ({ children }) => {
  const { data: customColor, isLoading: isLoadingColorInfo } =
    useGetTheme("colors");
  const { data: customFont, isLoading: isLoadingFont } = useGetTheme("fonts");
  const { data: customInfo, isLoading: isLoadingCustomInfo } =
    useGetTheme("infos");
  return (
    <UseCustomTheme.Provider
      value={{
        customColor,
        customFont,
        customInfo,
        isLoadingColorInfo,
        isLoadingFont,
        isLoadingCustomInfo,
      }}
    >
      {children}
    </UseCustomTheme.Provider>
  );
};
export const useCustomTheme = () => {
  const context = useContext(UseCustomTheme);

  if (!context) {
    throw new Error(
      "useCustomTheme must be used within an CustomThemeProvider"
    );
  }

  return context;
};
