import React, { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { AppRoutes } from './AppRoutes';
import { AdminRoutes } from './AdminRoutes';
import LinkingConfiguration from './LinkingConfiguration';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { auth, firestore } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ActivityIndicator } from 'react-native-paper';
import THEME from '../config/theme';
import { StatusBar } from 'expo-status-bar';
import { useUpload } from '../hooks/useUpload';
import {
  CustomThemeProvider,
  useCustomTheme,
} from '../contexts/useCustomTheme';
import Toast from 'react-native-toast-message';
import toastConfig from '../components/Toast/toast.config';
import { ThemeProvider } from 'styled-components/native';
import { RemoteConfigProvider } from '../contexts/useRemoteConfigContext';

const { Screen, Navigator } = createNativeStackNavigator();


export default function Navigation() {
  const [user, setUser] = useState();
  const [isLoading, setLoading] = useState(true);

  const {
    customColor: customTheme,
  } = useCustomTheme();

  const getUserInformation = async (userId) => {
    const docRef = doc(firestore, 'users', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUser(docSnap.data());
    }
    setLoading(false);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        getUserInformation(user.uid);
      } else if (!user) {
        // Nenhum usuário está logado, realizar login anônimo.
        signInAnonymously(auth)
          .then(() => {
            setLoading(false);
            // Login anônimo realizado com sucesso.
          })
          .catch((error) => {
            // Houve um erro ao realizar login anônimo.
            console.error(error);
          });
      } else {
        setLoading(false);
      }
    });
  }, []);

  return isLoading ? (
    <ActivityIndicator
      style={{
        flex: 1,
        backgroundColor:
          customTheme && customTheme.background && customTheme.background.auth
            ? customTheme.background.auth
            : THEME.COLORS.BACKGROUND_ABOUT,
        justifyContent: 'center',
      }}
      color={
        customTheme && customTheme.primary
          ? customTheme.primary
          : THEME.COLORS.PRIMARY_800
      }
    />
  ) : (
    <NavigationContainer linking={LinkingConfiguration}>
      {user?.isAdmin ? (
        <Navigator screenOptions={{ headerShown: false }}>
          <Screen name="Painel" component={AdminRoutes} />
        </Navigator>
      ) : (
        <AppRoutes />
      )}
    </NavigationContainer>
  );
}

export const MainRoutes = ({ fontsLoaded }) => {
  const [isLoadingFavIcon, setIsLoadingFavIcon] = useState(false);

  const {
    customColor: customTheme,
    isLoadingColorInfo,
    isLoadingFont
  } = useCustomTheme();

  const { updateFavicon } = useUpload();

  useEffect(() => {
    const getCustomFavIcon = async () => {
      setIsLoadingFavIcon(true);
      try {
        const docRef = doc(firestore, 'customization', 'images');
        const docSnap = await getDoc(docRef);

        const imageData = { id: docSnap.id, ...docSnap.data() };

        if (imageData['favIcon'] && imageData['favIcon'].url) {
          await updateFavicon(imageData['favIcon'].url);
        }
      } finally {
        setIsLoadingFavIcon(false);
      }
    };
    getCustomFavIcon();
  }, []);

  return (
    <ThemeProvider theme={THEME}>
      {!fontsLoaded ||
        isLoadingColorInfo ||
        isLoadingFont ||
        isLoadingFavIcon ? (
        <ActivityIndicator
          style={{
            flex: 1,
            backgroundColor:
              customTheme && customTheme.background && customTheme.background.auth
                ? customTheme.background.auth
                : 'white',
            justifyContent: 'center',
          }}
          color={
            customTheme && customTheme.primary
              ? customTheme.primary
              : '#d3d3d3'
          }
        />
      ) : (
        <CustomThemeProvider>
          <RemoteConfigProvider>
            <Navigation />
            <Toast config={toastConfig} />
          </RemoteConfigProvider>
        </CustomThemeProvider>
      )}
      <StatusBar style="auto" />
    </ThemeProvider>
  );
};
