import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const MultipleTexts = ({ component, content, width, numOfLines}) => {

    return (
        <View style={styles.container}>
            <Text style={{...styles.paragraph, textAlign: component?.textAlign ? component?.textAlign : "center"}} numberOfLines={numOfLines ? numOfLines : undefined}>
                {content.map((item, index) => (
                    <Text key={index} style={item.style(width)}>
                        {item.text}{' '}
                    </Text>
                ))}
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    paragraph: {
        fontSize: 18,
    },
});

export default MultipleTexts;
