import { useCallback, useEffect, useState } from "react";
import { useColor } from "react-color-palette";
import { useCustomTheme } from "../../contexts/useCustomTheme";
import THEME from "../../config/theme";
import {
  useUpdatedColors,
  useUpdatedCustomizationInfo,
} from "../../api/Brand";

export const useCustomLogin = () => {
  //use state para mostrar ou não o modal de cores
  const [textAbout, setTextAbout] = useState("");
  const [show, setShow] = useState({
    showPrimaryColor: false,
    showSecondaryColor: false,
    showBackgroundColor: false,
    showTextColor: false,
    showBackgroundButtonColor: false,
    showTextButtonColor: false,
  });
  const handleShowPicker = useCallback((field, value) => {
    setShow((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  });

  //instanciar as cores default
  const [backgroundColor, setBackgroundColor] = useColor(THEME.COLORS.BACKGROUND_ABOUT);
  const [textColor, setTextColor] = useColor(THEME.COLORS.TITLE_ABOUT);
  const [backgroundButtonColor, setBackgroundButtonColor] = useColor(THEME.COLORS.PRIMARY_900);
  const [textButtonColor, setTextButtonColor] = useColor(THEME.COLORS.TEXT_BUTTON);

  const {
    customColor: colorInfo,
    customInfo: customizationInfo,
    isLoadingColorInfo
  } = useCustomTheme();

  const { mutateAsync: updatedColor } = useUpdatedColors();

  const debouncedUpdateColor = useCallback(
    _.debounce((newColors) => {
      updatedColor(newColors);
    }, 500),
    []
  );

  //get das cores que estao no banco
  useEffect(() => {
    if (colorInfo && Object.keys(colorInfo).length > 1) {
      setBackgroundColor({
        ...backgroundColor,
        hex:
          colorInfo.background && colorInfo.background.auth
            ? colorInfo.background.auth
            : THEME.COLORS.BACKGROUND_ABOUT,
      });
      setTextColor({
        ...textColor,
        hex:
          colorInfo.text && colorInfo.text.auth
            ? colorInfo.text.auth
            : THEME.COLORS.TITLE_ABOUT,
      });
      setBackgroundButtonColor({
        ...backgroundButtonColor,
        hex:
          colorInfo.button && colorInfo.button.backgroundAuth
            ? colorInfo.button.backgroundAuth
            : THEME.COLORS.PRIMARY_900,
      });
      setTextButtonColor({
        ...textButtonColor,
        hex:
          colorInfo.button && colorInfo.button.textAuth
            ? colorInfo.button.textAuth
            : THEME.COLORS.TEXT_BUTTON,
      });
    }
  }, [colorInfo]);

  //post para atualizar as cores
  useEffect(() => {
    const newColors = {
      background: {
        auth: backgroundColor.hex,
      },
      text: {
        auth: textColor.hex,
      },
      button: {
        backgroundAuth: backgroundButtonColor.hex,
        textAuth: textButtonColor.hex,
      },
    };
    debouncedUpdateColor(newColors);
  }, [
    backgroundColor,
    textColor,
    backgroundButtonColor,
    textButtonColor,
  ]);

  //TEXTO ABOUT APP MOBILE
  const { mutateAsync: updatedCustomInfo } = useUpdatedCustomizationInfo();

  const debounceText = useCallback(
    _.debounce((text) => {
      updatedCustomInfo(text);
    }, 500),
    []
  );

  //get no titulo e subtitulo
  useEffect(() => {
    if (customizationInfo) {
      setTextAbout(customizationInfo.textAbout);
    }
  }, [customizationInfo]);

  //post para atualizar titulo e subtitulo
  useEffect(() => {
    const infos = { textAbout };
    if (Object.values(infos).some((value) => value !== undefined)) {
      debounceText(infos);
    }
  }, [textAbout]);

  return {
    backgroundColor,
    setBackgroundColor,
    textColor,
    setTextColor,
    backgroundButtonColor,
    setBackgroundButtonColor,
    textButtonColor,
    setTextButtonColor,
    show,
    handleShowPicker,
    isLoadingColorInfo,
    colorInfo,
    textAbout,
    setTextAbout
  };
};
