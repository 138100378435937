export const emailValidator = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email || email.length <= 0) return 'Por favor, preencha o email.';
  if (!re.test(email)) return 'Ooops! Nós precisamos de um email que seja válido.';
  return '';
};

export const passwordValidator = (password) => {
  if (!password || password.length <= 0) return 'Por favor, preencha sua senha.';
  return '';
};

export const nameValidator = (name) => {
  if (!name || name.length <= 0) return 'Por favor, preencha seu nome completo.';
  return '';
};

export const cellphoneValidator = (cellphone) => {
  if (cellphone.length <= 10) return 'Por favor, preencha seu celular corretamente com DDD.';
  return '';
}

export const cpfValidator = (cpf) => {
  if (cpf.length <= 13) return 'Por favor, preencha um CPF válido.';
  return '';
}

export const linkValidator = (youtube) => {
  if (youtube.search(/youtube|youtu.be/) < 0 && youtube.length > 0) return 'Válido apenas link do Youtube.';
  return '';
};

export const textValidator = (text) => {
  if (!text || text.length <= 0) return 'Por favor, preencha este campo.';
  return '';
};

export const daysValidator = (days) => {
  if (!days || days.length <= 0) return 'Por favor, preencha este campo.';
  if (Number(days) > 365) return 'A validade do produto não pode ser maior do que 1 ano.';
  return '';
};

export const firestoreAutoId = () => {
  const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let autoId = "";
  for (let k = 0; k < 20; k++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return autoId;
};

export const endDateValidator = (endDate) => {
  if (!endDate || endDate.length <= 0) return 'Por favor, selecione uma data de fim de acesso.';
  return '';
};

export const selectedProductsValidator = (selectedProducts) => {
  if (!selectedProducts || selectedProducts.length === 0) return 'Por favor, selecione pelo menos um produto.';
  return '';
};

export const removeSansSerif = (font) => {
  return font?.split(',')[0].trim();
}
