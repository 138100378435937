import styled from 'styled-components/native'

export const ViewHorizontal = styled.View`
    flex-direction: row;
    align-items: center;
    padding: 1rem 0rem;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "space-between")};
`;

export const Row = styled.View`
    flex-direction: row;
    align-items: center;
    gap: ${({ gap }) => (gap ? gap : 0)};
`;

export const ViewTable = styled.View`
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 10px;
`;

export const ContainerScroll = styled.ScrollView.attrs({
    showsVerticalScrollIndicator: true,
    contentContainerStyle: {
        paddingHorizontal: "1rem",
    },
})` 
  `;