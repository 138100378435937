import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import Toast from "react-native-toast-message";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { firestore, storage } from "../services/firebase";
import { useEffect, useState } from "react";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import AntDesign from "react-native-vector-icons/AntDesign";
import { useSpring, animated } from "react-spring";

import { View } from "react-native";
import { queryClient } from "../queries/queryClient";

export const useUpload = () => {
  const [statusUpload, setStatusUpload] = useState("");
  const [existingImage, setExistingImage] = useState(null);

  const rotateProps = useSpring({
    loop: true,
    from: { rotateZ: 0 },
    to: { rotateZ: 360 },
    config: { duration: 2000 },
  });
  const AnimatedView = animated(View);

  const IconStatus = () => {
    return (
      <View style={{ position: "absolute", zIndex: 1 }}>
        {statusUpload === "completed" ? (
          <FontAwesome name="check-circle" size={35} color="green" />
        ) : statusUpload === "error" ? (
          <FontAwesome name="times-circle" size={35} color="red" />
        ) : statusUpload === "uploading" ? (
          <AnimatedView style={rotateProps}>
            <AntDesign name="loading1" size={35} color="#14181d" />
          </AnimatedView>
        ) : (
          <FontAwesome name="cloud-upload" size={35} color="#14181d" />
        )}
      </View>
    );
  };

  const handleUpload = async (acceptedFiles, fileType, setFiles) => {
    setStatusUpload("uploading");

    Toast.hide();
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    const file = acceptedFiles[0];
    const expectedType = fileType.includes("font") ? "font/ttf" : "image/png";

    if (file.type !== expectedType) {
      alert(
        `Apenas arquivos ${
          fileType.includes("font") ? ".ttf" : ".png"
        } são permitidos`
      );
      setStatusUpload("");
      return;
    }

    const docRef = doc(
      firestore,
      "customization",
      fileType.includes("font") ? "fonts" : "images"
    );
    const docSnap = await getDoc(docRef);

    const imageData = { id: docSnap.id, ...docSnap.data() };

    if (imageData && imageData[fileType]) {
      const oldImageURL = imageData[fileType].url;
      const oldImageRef = ref(storage, oldImageURL);
      try {
        await deleteObject(oldImageRef);
      } catch {
        setStatusUpload("error");
        Toast.show({
          text1: "Erro ao fazer upload da imagem!",
          text2: "Tente novamente mais tarde",
          type: "error",
          position: "top",
          autoHide: true,
        });
      }
    }

    const fileRef = ref(storage, `custom/${fileType}/${file.name}`);

    try {
      await uploadBytes(fileRef, file).then(async (snapshot) => {
        const fileURL = await getDownloadURL(snapshot.ref);
        const categoryRef = doc(
          firestore,
          "customization",
          fileType.includes("font") ? "fonts" : "images"
        );

        await setDoc(
          categoryRef,
          {
            [fileType]: {
              url: fileURL,
              createdAt: serverTimestamp(),
              name: file.name,
            },
          },
          { merge: true }
        );
        setStatusUpload("completed");
        if (fileType === "favIcon") {
          await updateFavicon(fileURL);
        }
        queryClient.invalidateQueries({
          queryKey: ["IMAGE"],
        });
        queryClient.invalidateQueries({
          queryKey: ["fonts"],
        });

        setTimeout(function () {
          setStatusUpload("");
        }, 4000);

        Toast.show({
          text1: "Upload concluído!",
          type: "success",
          position: "top",
          autoHide: true,
        });
      });
    } catch (error) {
      console.error(`Erro ao fazer upload da imagem ${fileType}: `, error);
      setStatusUpload("error");
      Toast.show({
        text1: "Erro ao fazer upload da imagem!",
        text2: "Tente novamente mais tarde",
        type: "error",
        position: "top",
        autoHide: true,
      });
    } finally {
      loadExistingImage(fileType);
    }
  };

  const onDropRejectedMessage = (error) => {
    const errorCode = error[0].errors[0].code;
    setStatusUpload("error");
    if (errorCode === "file-too-large") {
      Toast.show({
        text1: "Limite de tamanho atingido!",
        text2: "O limite de tamanho é de 2mb.",
        type: "error",
        position: "top",
        autoHide: true,
      });
    }
    setFiles([]);
  };

  const updateFavicon = async (url) => {
    const links = document.querySelectorAll(
      "link[rel~='icon'], link[rel='shortcut icon']"
    );
    links.forEach((link) => document.head.removeChild(link));

    // Add new favicons
    const newLink32 = document.createElement("link");
    newLink32.rel = "icon";
    newLink32.type = "image/png";
    newLink32.sizes = "32x32";
    newLink32.href = url;
    document.head.appendChild(newLink32);

    const newLink16 = document.createElement("link");
    newLink16.rel = "icon";
    newLink16.type = "image/png";
    newLink16.sizes = "16x16";
    newLink16.href = url;
    document.head.appendChild(newLink16);

    const newShortcutIcon = document.createElement("link");
    newShortcutIcon.rel = "shortcut icon";
    newShortcutIcon.href = url;
    document.head.appendChild(newShortcutIcon);
    await new Promise((resolve) => setTimeout(resolve, 100));
  };

  const loadExistingImage = async (fileType) => {
    const docRef = doc(
      firestore,
      "customization",
      fileType.includes("font") ? "fonts" : "images"
    );
    const docSnap = await getDoc(docRef);

    const imageData = { id: docSnap.id, ...docSnap.data() };
    if (imageData && imageData[fileType]) {
      fileType.includes("font")
        ? setExistingImage(imageData[fileType].name)
        : setExistingImage(imageData[fileType].url);
    }
  };

  const handleDeleteExistingImage = async (fileType) => {
    setStatusUpload("uploading");
    const docRef = doc(
      firestore,
      "customization",
      fileType.includes("font") ? "fonts" : "images"
    );
    const docSnap = await getDoc(docRef);

    const imageData = { id: docSnap.id, ...docSnap.data() };
    if (imageData && imageData[fileType]) {
      const oldImageURL = imageData[fileType].url;
      const oldImageRef = ref(storage, oldImageURL);
      try {
        await deleteObject(oldImageRef);
        await setDoc(docRef, { [fileType]: null }, { merge: true });
        setExistingImage(null);
        setStatusUpload("uploading");
        if (fileType === "favIcon") {
          const links = document.querySelectorAll(
            "link[rel~='icon'], link[rel='shortcut icon']"
          );
          links.forEach((link) => document.head.removeChild(link));

          // Add new favicons
          const newLink32 = document.createElement("link");
          newLink32.rel = "icon";
          newLink32.type = "image/png";
          newLink32.sizes = "32x32";
          newLink32.href = "/favicon-16.png";
          document.head.appendChild(newLink32);

          const newLink16 = document.createElement("link");
          newLink16.rel = "icon";
          newLink16.type = "image/png";
          newLink16.sizes = "16x16";
          newLink16.href = "/favicon-16.png";
          document.head.appendChild(newLink16);

          const newShortcutIcon = document.createElement("link");
          newShortcutIcon.rel = "shortcut icon";
          newShortcutIcon.href = "/favicon.ico";
          document.head.appendChild(newShortcutIcon);
        }
        Toast.show({
          text1: "Imagem excluída!",
          type: "success",
          position: "top",
          autoHide: true,
        });
        queryClient.invalidateQueries({
          queryKey: ["IMAGE"],
        });
      } catch (error) {
        setStatusUpload("error");
        Toast.show({
          text1: "Erro ao excluir a imagem!",
          text2: "Tente novamente mais tarde",
          type: "error",
          position: "top",
          autoHide: true,
        });
      } finally {
        setStatusUpload("");
      }
    }
  };

  return {
    handleUpload,
    onDropRejectedMessage,
    IconStatus,
    loadExistingImage,
    handleDeleteExistingImage,
    existingImage,
    statusUpload,
    updateFavicon,
  };
};
