import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const FAQ = ({ component, width  }) => {
  const [expandedId, setExpandedId] = useState(null);

  return (
    <View style={{width: width < 1080 ? width : width*0.9}}>
      {component.questions.map((question) => {
        const handlePress = () => {
          if (expandedId === question.id) {
            setExpandedId(null);
          } else {
            setExpandedId(question.id);
          }
        };

        return (
          <View key={question.id} style={component.containerStyle(width)}>
            <TouchableOpacity onPress={handlePress}>
              <View style={styles.header}>
                <Text
                  style={[
                    component.questionStyle(width, expandedId, question),                  ]}
                >
                  {question.question}
                </Text>
                <Ionicons
                  name={expandedId === question.id ? 'ios-remove' : 'ios-add'}
                  size={width < 1080? 20 : 32}
                  color={component.questionStyle(width, expandedId, question).color}
                />
              </View>
            </TouchableOpacity>
            {expandedId === question.id && (
              <View style={styles.answerContainer}>
                <Text style={component.answerStyle(width)}>{question.answer}</Text>
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
  },
  answerContainer: {
    padding: 20,
  },
});

export default FAQ;
