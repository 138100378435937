import 'react-native-gesture-handler';
import { MainRoutes } from './src/routes';
import { useFonts } from 'expo-font';
import text_Light_Font from './assets/fonts/Texto-Light.ttf';
import text_Regular_Font from './assets/fonts/Texto-Regular.ttf';
import text_Title_Font from './assets/fonts/TitleFont.ttf';
import text_Subtitle_Font from './assets/fonts/SubtitleFont.ttf';
import text_Texts_Font from './assets/fonts/TextFont.ttf';
import text_Medium_Font from './assets/fonts/Texto-Medium.ttf';
import text_Bold_Font from './assets/fonts/Texto-Bold.ttf';

import 'setimmediate';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './src/queries/queryClient';



export default function App() {
 
  const [fontsLoaded] = useFonts({
    text_Light: text_Light_Font,
    text_Regular: text_Regular_Font,
    text_Medium: text_Medium_Font,
    text_Bold: text_Bold_Font,
    text_Title: text_Title_Font,
    text_Subtitle: text_Subtitle_Font,
    text_Texts: text_Texts_Font,
  });


  return (
    <QueryClientProvider client={queryClient}>
      <MainRoutes fontsLoaded={fontsLoaded} />
      <ReactQueryDevtools position="left" buttonPosition="bottom-left" />
    </QueryClientProvider>
  );
}
