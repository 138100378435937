import React from "react";
import VideoComments from "../../../components/VideoComments";
import { useRemoteConfigContext } from "../../../contexts/useRemoteConfigContext";

const ViewFlatList = (props) => {
  const { login, comments, videoId, categoryId, cardId } = props;
  const config = useRemoteConfigContext();
  const hasComments = config.hasComments

  return (
    <>
      {hasComments && (
        <VideoComments
          login={login}
          comments={comments}
          videoId={videoId}
          categoryId={categoryId}
          cardId={cardId}
        >
        </VideoComments>
      )}
    </>
  );
};

export default ViewFlatList;