import PhoneInput from "react-native-international-phone-number";
import { Icon } from "react-native-elements";

export const PhoneTextInput = ({
  onBlur,
  value,
  onChange,
  errors,
  selectedCountry,
  handleSelectedCountry,
}) => {

  return (
    <PhoneInput
      onBlur={onBlur}
      value={value}
      onChangePhoneNumber={onChange}
      placeholder="Digite o número de telefone para contato"
      error={errors.telephone}
      selectedCountry={selectedCountry}
      language="pt"
      popularCountries={["BR", "PT", "AU", "US"]}
      onChangeSelectedCountry={handleSelectedCountry}
      customCaret={
        <Icon type="material-community" name="chevron-down" color="#000000" />
      }
      phoneInputStyles={{
        container: {
          borderWidth: 1,
          borderStyle: "solid",
          borderColor:"black",
          borderRadius: 4,
          paddingRight: "1rem",
        },
        flag: { color: "black", height: "2rem" },
        input: {
          border: "none",
          borderWidth: 0,
          paddingLeft: "0.5rem",
          borderColor: "red",
        },

        flagContainer: { paddingRight: "0.5rem", backgroundColor: "none" },
      }}
      modalStyles={{
        modal: {
          backgroundColor: "#333333",
          borderWidth: 1,
        },
        divider: {
          backgroundColor: "transparent",
        },
        searchInput: {
          borderRadius: 6,
          borderWidth: 1,
          borderColor: "#FFFFFF",
          color: "#F3F3F3",
          backgroundColor: "#333333",
          paddingHorizontal: 12,
          height: 46,
        },
        countryButton: {
          borderWidth: 1,
          borderColor: "#F3F3F3",
          backgroundColor: "#666666",
          marginVertical: 4,
          paddingVertical: 0,
        },
        flag: {
          color: "#FFFFFF",
          fontSize: 20,
        },
        callingCode: {
          color: "#F3F3F3",
        },
        countryName: {
          color: "#F3F3F3",
          fontSize: "1rem",
        },
      }}
    />
  );
};
