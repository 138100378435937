import React, { useState, useEffect } from "react";
import { Modal, Pressable, SafeAreaView } from "react-native";
import { Icon } from "react-native-elements";
import {
  ViewText,
  ViewPressable,
  ViewHeading,
  ContentIcon,
  Container,
} from "./style";
import THEME from "../../config/theme";
import { useCustomTheme } from '../../contexts/useCustomTheme';

export function PDFView({ route, navigation: { goBack } }) {
  const { pdf } = route.params;
  const [modalVisible, setModalVisible] = useState(true);
   const { customColor } = useCustomTheme();

  useEffect(() => {
    setModalVisible(true);
  }, []);

  return (
    <SafeAreaView>
      <Modal
        styles={{ flex: 1, flexDirection: 'column' }}
        animationType="fade"
        transparent={false}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
          goBack();
        }}
      >
        <ViewHeading>
          <ViewPressable></ViewPressable>
          <ViewText></ViewText>
          <ViewPressable>
            <Pressable
              onPress={() => {
                setModalVisible(!modalVisible), goBack();
              }}
            >
              <ContentIcon>
                <Icon
                  type="material-icons"
                  name="close"
                  size={THEME.FONTSIZE.BIG}
                  color={
                    customColor && customColor.primary
                      ? customColor.primary
                      : THEME.COLORS.PRIMARY_900
                  }
                />
              </ContentIcon>
            </Pressable>
          </ViewPressable>
        </ViewHeading>

        <Container>
          <iframe src={pdf} height={window.innerHeight} anonymous></iframe>
        </Container>
      </Modal>
    </SafeAreaView>
  );
}
