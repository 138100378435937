import styled from 'styled-components/native';

export const Container = styled.View`
  padding: 1rem 0rem;
`;

export const Content = styled.View`
  flex-direction: row;
`;

export const ViewIcon = styled.View`
  position: absolute;
  z-index: 99;
  height: 11.8rem; //aprox altura do card
  justify-content: center;
  overflow: hidden;
  background-color: rgba(255,255,255,0.1);
  border-radius: 5px;
  cursor: pointer;
  width: 1.5rem;
`;

export const ViewTextInput = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ViewText = styled.View`
  justify-content: center;
`;

export const ViewOrder = styled.View`
  justify-content: center;
  width: 10%;
  margin:0rem 0.5rem;
`;

export const ViewButton = styled.View`
  flex-direction: row;
  margin: 1rem;
  justify-content: flex-start;
`;

export const ContentIcon = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0.4rem;
  border-radius: 30px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '')};
`;