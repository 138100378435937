import { useCustomTheme } from "../../../contexts/useCustomTheme";
import { FlexBox, Select } from "./style";
import { Picker } from "react-native";
import THEME from "../../../config/theme";
import { IconButton } from "react-native-paper";
import { useDeleteFont } from "../../../api/Brand";

export const PickerFonts = ({ selectedValue, setSelectValue, fileType }) => {
  const { customFont } = useCustomTheme();
  const { mutateAsync: deleteFont, isPending } = useDeleteFont(fileType);

  const handleDeleteFont = async () => {
    await deleteFont();
    setSelectValue("");
  };

  return (
    <FlexBox>
      <Select
        selectedValue={selectedValue}
        onValueChange={(itemValue) => setSelectValue(itemValue)}
        style={{
          fontFamily:
            customFont && customFont.fontFamilyText
              ? customFont.fontFamilyText
              : THEME.FONTFAMILY.REGULAR,
        }}
      >
        <Picker.Item label="Escolha sua fonte" value="" />
        <Picker.Item label="Roboto" value="Roboto, sans-serif" />
        <Picker.Item label="Open Sans" value="Open Sans, sans-serif" />

        <Picker.Item label="Lato" value="Lato, sans-serif" />

        <Picker.Item label="Montserrat" value="Montserrat, sans-serif" />

        <Picker.Item label="Inter" value="Inter, sans-serif" />

        <Picker.Item label="Oswald" value="Oswald, sans-serif" />

        <Picker.Item label="Raleway" value="Raleway, sans-serif" />

        <Picker.Item label="Poppins" value="Poppins, sans-serif" />
      </Select>

      {selectedValue &&
        (isPending ? (
          <p>a</p>
        ) : (
          <IconButton
            icon="close"
            iconColor="#252525"
            style={{ cursor: "pointer" }}
            onPress={handleDeleteFont}
          />
        ))}
    </FlexBox>
  );
};
