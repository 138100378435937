import styled from "styled-components/native";

export const TextColorHex = styled.View`
  padding: 0.2rem 0.2rem;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
`;

export const BoxCurrentColor = styled.View`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-top: 9px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

export const ContainerPickerColor = styled.View`
  width: 300px;
  background: #121212;
  border-radius: 8px;
`;
