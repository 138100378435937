import React, { useState } from "react";
import { ViewButton, ViewModal } from "../style";
import THEME from "../../../config/theme";
import { SmallText, SubTitle } from "../../../config/theme/globalStyles";
import Button from "../../../components/Button";
import { Provider } from "react-native-paper";

import {
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import DraggableFlatList, {
  ScaleDecorator,
} from "react-native-draggable-flatlist";

import { AlertBox } from "../../../components/AlertBox";

const styles = StyleSheet.create({
  rowItem: {},
  text: {
    color: THEME.COLORS.TEXT_BUTTON,
    fontSize: 14,
    borderRadius: 10,
    padding: 10,
    margin: 5,
    backgroundColor: THEME.COLORS.PRIMARY_900,
  },
});

const EditOrder = (props) => {
  const { videos, isSaving, onClose, onSave } = props;

  const [showSaveSuccessAlert, setShowSaveSuccessAlert] = useState(false);

  const [updatedVideos, setUpdatedVideos] = useState(videos);

  const handleOnSaveClick = async () => {
    await onSave(updatedVideos);
    setShowSaveSuccessAlert(true);
  };

  const renderItem = ({ item, drag, isActive }) => {
    return (
      <ScaleDecorator>
        <TouchableOpacity
          onLongPress={drag}
          disabled={isActive}
          style={[styles.rowItem]}
        >
          <Text style={styles.text} color="black">
            {item.title}
          </Text>
        </TouchableOpacity>
      </ScaleDecorator>
    );
  };
  return (
    <Provider>
      <ViewModal>
        <ScrollView>
          <SubTitle
            padding="0rem 0rem 1rem 0rem"
            color="#2e2e2e"
          >
            Alterar ordem dos conteúdos:
          </SubTitle>
          <SmallText>
            Pressione por 1 segundo e arraste para alterar a ordem dos vídeos.
          </SmallText>

          <DraggableFlatList
            data={updatedVideos}
            onDragEnd={({ data }) => setUpdatedVideos(data)}
            keyExtractor={(item) => item.docId}
            renderItem={renderItem}
          />

          <AlertBox
            message={'Alteração feita com sucesso! :)'}
            visible={showSaveSuccessAlert}
            leftButtonFunction={onClose}
            onClose={onClose}
            leftButton={'OK'}
          />
          <ViewButton>
            <Button
              title={'Salvar'}
              isLoading={isSaving}
              onPress={handleOnSaveClick}
              margin="0rem 0.5rem"
            ></Button>
            <Button
              title={'Cancelar'}
              onPress={onClose}
              disabled={isSaving}
              margin="0rem 0.5rem"
            ></Button>
          </ViewButton>
        </ScrollView>
      </ViewModal>
    </Provider>
  );
};

export default EditOrder;
