import React from 'react';
import { View, Image } from 'react-native';
import Button from './Button';
import { Feather } from '@expo/vector-icons';
import THEME from '../../../config/theme';
import { DrawerActions } from "@react-navigation/native";
import { auth } from '../../../services/firebase'
import { signOut } from "firebase/auth";

const Header = ({ width, breakpoint, navigation, header, userId, user }) => {

  const handleToggleDrawer = () => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  };

  const logout = () => {
    signOut(auth).then(() => {
      window.location.assign("../About");
    }).catch((error) => {
      console.log(error)
    });
  }

  return (
    <View style={{
      flexDirection: width < breakpoint ? "column" : "row",
      justifyContent: width < breakpoint ? "center" : "space-between",
      alignItems: width < breakpoint ? "center" : "space-between",
      paddingHorizontal: "1rem",
    }}>
      {user?.isAdmin ?
        <View style={{
          paddingVertical: "1.5rem",
        }}>
          <Feather
            name="menu"
            color={THEME.COLORS.TITLE_ABOUT}
            size={34}
            onPress={handleToggleDrawer}
          />
        </View> :
        <Image source={header?.logo?.uri} style={header?.logo?.style(width)}></Image>
      }
      <View style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Button title={userId ? "Acessar conteúdo" : "Visualizar conteúdo"} onPress={() => navigation.navigate("Conteudo")} buttonStyle={header.buttonApp.style(width)} textStyle={header.buttonApp.textStyle(width)}></Button>
        <Button title={userId ? "Sair" : "Login"} onPress={userId ? logout : () => navigation.navigate("Login")} buttonStyle={header.buttonLogin.style(width)} textStyle={header.buttonLogin.textStyle(width)}></Button>
      </View>
    </View>
  )
}

export default Header