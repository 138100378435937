import React, { useState, useEffect } from 'react';
import { View, SafeAreaView, ActivityIndicator } from 'react-native';
import ViewPortProvider from '../../hooks/ViewPortProvider';
import useViewport from '../../hooks/useViewport';
import THEME from '../../config/theme';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import {
  doc,
  getDoc,
  orderBy,
  query,
  collection,
  onSnapshot,
  updateDoc,
  deleteField,
} from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import ListHeader from '../../components/ListHeader';
import { VerticalScroll } from './style';
import ViewVideo from './ViewVideo';
import ViewFlatList from './ViewFlatList';
import { StandardText } from '../../config/theme/globalStyles';
import Header from '../../components/Header';
import { useRemoteConfigContext } from '../../contexts/useRemoteConfigContext';
import { useCustomTheme } from '../../contexts/useCustomTheme';

const auth = getAuth();

export function ClickClass({ route, navigation }) {
  const { cardId, categoryId, selectedVideo } = route.params;
  const config = useRemoteConfigContext();
  const hasComments = config.hasComments;
  const { customColor } = useCustomTheme();

  const [card, setCard] = useState();
  const [video, setVideo] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getUser = async (user) => {
    const docRef = doc(firestore, 'users', user);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) return;
    setUser({ id: docRef.id, ...docSnap.data() });
    setIsLoading(false);
  };

  const toDate = (seconds) => {
    const date = new Date(1970, 0, 1);
    date.setSeconds(seconds);
    return date;
  };

  const fetchCard = async () => {
    const docRef = doc(firestore, 'categories', categoryId, 'cards', cardId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) return;
    const cardData = docSnap.data();
    setCard(cardData);
  };

  const fetchVideo = async () => {
    const docRef = doc(
      firestore,
      'categories',
      categoryId,
      'cards',
      cardId,
      'videos',
      selectedVideo
    );
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) return;
    const videoData = docSnap.data();
    setVideo(videoData);
  };

  const hideCommentNotification = async () => {
    const cardRef = doc(firestore, 'categories', categoryId, 'cards', cardId);
    const videoRef = doc(
      firestore,
      'categories',
      categoryId,
      'cards',
      cardId,
      'videos',
      selectedVideo
    );
    const cardSnap = await getDoc(cardRef);
    const videoSnap = await getDoc(videoRef);
    if (!cardSnap.data().hasNewComment || !videoSnap.data().hasNewComment) {
      return;
    }
    await updateDoc(cardRef, { hasNewComment: deleteField() });
    await updateDoc(videoRef, { hasNewComment: deleteField() });
  };

  useEffect(() => {
    const findAllComments = async () => {
      if (!hasComments) return;

      const commentsRef = query(
        collection(
          firestore,
          'categories',
          categoryId,
          'cards',
          cardId,
          'videos',
          selectedVideo,
          'comments'
        ),
        orderBy('createdAt', 'desc')
      );

      onSnapshot(commentsRef, (querySnapshot) => {
        let comments = [];
        if (querySnapshot.docs.length === 0) {
          setAllComments(null);
        }
        querySnapshot.forEach((comment) => {
          if (comment.exists) {
            const commentData = comment.data();
            const commentId = comment.id;

            const responseReplies = query(
              collection(
                firestore,
                `categories/${categoryId}/cards/${cardId}/videos/${selectedVideo}/comments/${commentId}/replies`
              ),
              orderBy('createdAt', 'desc')
            );

            onSnapshot(responseReplies, (replySnapshot) => {
              let replies = [];
              replySnapshot.forEach((reply) => {
                if (reply.exists) {
                  replies.push({ id: reply.id, ...reply.data() });
                }
              });

              comments = comments.map((cmt) => {
                if (cmt.id === commentId) {
                  return { ...cmt, replies };
                } else {
                  return cmt;
                }
              });

              setAllComments(comments);
            });

            comments.push({ id: commentId, ...commentData, replies: [] });
          }
        });
      });

      hideCommentNotification();
    };

    findAllComments();
  }, []);

  useEffect(() => {
    fetchCard();
    fetchVideo();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        getUser(user.uid);
      } else if (!user) {
        // Nenhum usuário está logado, realizar login anônimo.
        signInAnonymously(auth)
          .then(() => {
            setIsLoading(false);
            // Login anônimo realizado com sucesso.
          })
          .catch((error) => {
            // Houve um erro ao realizar login anônimo.
            console.error(error);
          });
      } else {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [categoryId, cardId, selectedVideo]);

  if (isLoading) {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          backgroundColor:
            customColor && customColor.background?.app
              ? customColor.background.app
              : THEME.COLORS.BACKGROUND_MAIN,
          justifyContent: 'center',
        }}
        color={
          customColor && customColor.primary
            ? customColor.primary
            : THEME.COLORS.PRIMARY_800
        }
      />
    );
  }

  
  const OutsideView = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <>
        <View
          style={{
            alignSelf: 'center',
            width: '100%',
            height: (width - 32) / 1.78,
            marginBottom: '1rem',
            paddingHorizontal: '2%',
          }}
        >
          <ViewVideo user={user} video={video} card={card} />
        </View>
        <VerticalScroll>
          <ListHeader
            cardId={cardId}
            categoryId={categoryId}
            video={video}
            videoId={selectedVideo}
            navigation={navigation}
            user={user}
            login={true}
          ></ListHeader>
          <ViewFlatList
            comments={allComments}
            videoId={selectedVideo}
            categoryId={categoryId}
            cardId={cardId}
            login={user}
          />
        </VerticalScroll>
      </>
    ) : (
      <View style={{ paddingHorizontal: '2%', paddingVertical: '2rem' }}>
        {hasComments ? (
          <>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  alignSelf: 'center',
                  width: '70%',
                  height: (width * 0.7 - 32) / 1.78,
                }}
              >
                <ViewVideo user={user} video={video} card={card} />
              </View>
              <View
                style={{
                  alignSelf: 'center',
                  width: '30%',
                  height: (width * 0.7 - 32) / 1.78,
                  paddingHorizontal: '1rem',
                }}
              >
                <ViewFlatList
                  comments={allComments}
                  videoId={selectedVideo}
                  categoryId={categoryId}
                  cardId={cardId}
                  login={user}
                />
              </View>
            </View>
            <View style={{ marginTop: '2%' }}>
              <ListHeader
                cardId={cardId}
                categoryId={categoryId}
                video={video}
                videoId={selectedVideo}
                navigation={navigation}
                user={user}
                login={true}
              ></ListHeader>
            </View>
          </>
        ) : (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                alignSelf: 'center',
                width: '70%',
                height: (width * 0.7 - 32) / 1.78,
              }}
            >
              <ViewVideo video={video} card={card} user={user} />
            </View>
            <View
              style={{
                alignSelf: 'center',
                width: '30%',
                height: (width * 0.7 - 32) / 1.78,
                paddingHorizontal: '1rem',
              }}
            >
              <ListHeader
                cardId={cardId}
                categoryId={categoryId}
                video={video}
                videoId={selectedVideo}
                navigation={navigation}
                user={user}
                login={true}
              ></ListHeader>
            </View>
          </View>
        )}
      </View>
    );
  };

  return (
    <ViewPortProvider>
      <SafeAreaView
        style={{
          backgroundColor:
            customColor && customColor.background?.app
              ? customColor.background.app
              : THEME.COLORS.BACKGROUND_MAIN,
          flexGrow: 1,
        }}
      >
        <Header
          onPress={() => {
            navigation.navigate('Modulo', {
              cardId: cardId,
              categoryId: categoryId,
            });
          }}
        />
        {(card?.productIds &&
          card?.productIds?.length > 0 &&
          card?.productIds?.filter((cardProductId) => {
            const matchingUserProducts = user?.productIds?.filter(
              (userProduct) => userProduct.productId === cardProductId
            );
            if (!matchingUserProducts || matchingUserProducts.length === 0) {
              return false; // O usuário não possui este produto, então não tem acesso
            }
            // Se não houver data de expiração, ou se alguma data de expiração for maior que a data atual, o usuário tem acesso
            return matchingUserProducts.some(
              (userProduct) =>
                !userProduct.expirationDate ||
                toDate(userProduct.expirationDate.seconds) > new Date()
            );
          }).length > 0) ||
        (card?.plans &&
          card?.plans.length > 0 &&
          card?.plans.filter(
            (plan) => (plan.priceId || plan.productId) == user?.plan
          ).length > 0) ||
        (user?.courses &&
          user?.courses.length > 0 &&
          user?.courses.filter(
            (course) =>
              card?.coursesCard?.some(
                (itemToBeRemoved) =>
                  (itemToBeRemoved.priceId || itemToBeRemoved.productId) ===
                  (course.priceId || course.productId)
              ) && toDate(course.dueDate.seconds) > new Date()
          ).length > 0) ||
        (card?.plans == null &&
          card?.coursesCard == null &&
          card?.productIds == null) ||
        user?.isAdmin == true ? (
          <OutsideView />
        ) : (
          <View
            style={{
              justifyContent: 'center',
              flex: 1,
              alignItems: 'center',
              marginHorizontal: '0.5rem',
            }}
          >
            <StandardText
              style={{
                fontFamily:
                  customFont && customFont.fontFamilySubtitle
                    ? customFont.fontFamilySubtitle
                    : THEME.THEME.FONTFAMILY.MEDIUM,
              }}
              color={
                customColor && customColor.text && customColor.text.auth
                  ? customColor.text.auth
                  : THEME.COLORS.TEXT_ABOUT
              }
            >
              Ops, você não possui acesso a esse conteúdo!
            </StandardText>
          </View>
        )}
      </SafeAreaView>
    </ViewPortProvider>
  );
}
