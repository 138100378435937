import React, { useEffect, useState, useCallback } from 'react';
import { SmallText } from '../../config/theme/globalStyles';
import { Container, Content, Avatar, ContentIcon, ViewIcon } from './style';
import { Feather, Ionicons, AntDesign } from '@expo/vector-icons';
import THEME from '../../config/theme';
import { FooterText } from '../../config/theme/globalStyles';
import { View, TouchableOpacity } from 'react-native';
import { firestore } from '../../services/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { ActivityIndicator } from 'react-native-paper';
import Svg, { Path } from 'react-native-svg';
import { hasSchedule } from '../../utils/hasSchedule';
import { useUserPlan } from '../../queries/userQuery';
import { useCustomTheme } from '../../contexts/useCustomTheme';

const PlayList = ({
  user,
  favorites,
  docId,
  id,
  cardId,
  categoryId,
  title,
  image,
  backgroundImage,
  videoInfo,
  pandaVideoMetadata,
  link,
  url,
  pdf,
  meetingNumber,
  handleRemoveLike,
  hasAccess,
  video,
}) => {
  const { customColor, customFont } = useCustomTheme();
  const pdfUrl = pdf?.url || pdf;
  const src = videoInfo?.url || pandaVideoMetadata?.video_player || link || '';
  const { data: infoPlanUser } = useUserPlan();
  const [isRemoved, setIsRemoved] = useState(false);
  const [watched, setWatched] = useState(null);

  const loadDetail = useCallback(async () => {
    try {
      const videoId = id ? id : docId;
      const userId = user?.uid ? user?.uid : user?.id;

      const watchedDocRef = doc(
        firestore,
        'users',
        userId,
        'contents',
        videoId
      );

      onSnapshot(watchedDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setWatched(data?.isWatched === true ? true : false);
        } else {
          setWatched(false);
        }
      });
    } catch (error) {
      console.log(error);
      setWatched(false);
    }
  }, [user?.id, id]);

  useEffect(() => {
    loadDetail();
  }, []);

  const Icon = () => {
    return (
      <ContentIcon>
        {src ? (
          <>
            {watched === true ? (
              <View style={{ paddingRight: '0.5rem' }}>
                <AntDesign
                  name="checkcircle"
                  size={14}
                  color={
                    customColor && customColor.primary
                      ? customColor.primary
                      : THEME.COLORS.PRIMARY_900
                  }
                />
              </View>
            ) : watched === false ? (
              <ViewIcon
                backgroundColor={
                  customColor && customColor.text && customColor.text.app
                    ? customColor.text.app
                    : THEME.COLORS.TEXT_MAIN
                }
              >
                <Ionicons
                  name="play"
                  size={9}
                  color={
                    customColor &&
                    customColor.background &&
                    customColor.background.app
                      ? customColor.background.app
                      : THEME.COLORS.BACKGROUND_MAIN
                  }
                />
              </ViewIcon>
            ) : (
              <ViewIcon backgroundColor="transparent">
                <ActivityIndicator
                  color={
                    customColor && customColor.text && customColor.text.app
                      ? customColor.text.app
                      : THEME.COLORS.TEXT_MAIN
                  }
                />
              </ViewIcon>
            )}
            <FooterText
              style={{
                fontFamily:
                  customFont && customFont.fontFamilyText
                    ? customFont.fontFamilyText
                    : THEME.FONTFAMILY.LIGHT,
                fontSize:
                  customFont && customFont.fontXS
                    ? customFont.fontXS
                    : THEME.FONTSIZE.EXTRASMALL,
              }}
              color={
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : THEME.COLORS.TEXT_MAIN
              }
            >
              Video
            </FooterText>
          </>
        ) : meetingNumber ? (
          <>
            {watched === true ? (
              <View style={{ paddingRight: '0.5rem' }}>
                <AntDesign
                  name="checkcircle"
                  size={14}
                  color={
                    customColor && customColor.primary
                      ? customColor.primary
                      : THEME.COLORS.PRIMARY_900
                  }
                />
              </View>
            ) : watched === false ? (
              <ViewIcon
                backgroundColor={
                  customColor && customColor.text && customColor.text.app
                    ? customColor.text.app
                    : THEME.COLORS.TEXT_MAIN
                }
              >
                <Feather
                  name="radio"
                  size={9}
                  color={
                    customColor &&
                    customColor.background &&
                    customColor.background.app
                      ? customColor.background.app
                      : THEME.COLORS.BACKGROUND_MAIN
                  }
                />
              </ViewIcon>
            ) : (
              <ViewIcon backgroundColor="transparent">
                <ActivityIndicator
                  color={
                    customColor && customColor.text && customColor.text.app
                      ? customColor.text.app
                      : THEME.COLORS.TEXT_MAIN
                  }
                />
              </ViewIcon>
            )}
            <FooterText
              color={
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : THEME.COLORS.TEXT_MAIN
              }
              style={{
                fontFamily:
                  customFont && customFont.fontFamilyText
                    ? customFont.fontFamilyText
                    : THEME.FONTFAMILY.LIGHT,
                fontSize:
                  customFont && customFont.fontXS
                    ? customFont.fontXS
                    : THEME.FONTSIZE.EXTRASMALL,
              }}
            >
              Ao vivo
            </FooterText>
          </>
        ) : pdfUrl ? (
          <>
            {watched === true ? (
              <View style={{ paddingRight: '0.5rem' }}>
                <AntDesign
                  name="checkcircle"
                  size={14}
                  color={
                    customColor && customColor.primary
                      ? customColor.primary
                      : THEME.COLORS.PRIMARY_900
                  }
                />
              </View>
            ) : watched === false ? (
              <ViewIcon
                backgroundColor={
                  customColor && customColor.text && customColor.text.app
                    ? customColor.text.app
                    : THEME.COLORS.TEXT_MAIN
                }
              >
                <Ionicons
                  name="document-text-outline"
                  size={9}
                  color={
                    customColor &&
                    customColor.background &&
                    customColor.background.app
                      ? customColor.background.app
                      : THEME.COLORS.BACKGROUND_MAIN
                  }
                />
              </ViewIcon>
            ) : (
              <ViewIcon backgroundColor="transparent">
                <ActivityIndicator
                  color={
                    customColor && customColor.text && customColor.text.app
                      ? customColor.text.app
                      : THEME.COLORS.TEXT_MAIN
                  }
                />
              </ViewIcon>
            )}
            <FooterText
              color={
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : THEME.COLORS.TEXT_MAIN
              }
              style={{
                fontFamily:
                  customFont && customFont.fontFamilyText
                    ? customFont.fontFamilyText
                    : THEME.FONTFAMILY.LIGHT,
                fontSize:
                  customFont && customFont.fontXS
                    ? customFont.fontXS
                    : THEME.FONTSIZE.EXTRASMALL,
              }}
            >
              Arquivo
            </FooterText>
          </>
        ) : url ? (
          <>
            <ViewIcon
              backgroundColor={
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : THEME.COLORS.TEXT_MAIN
              }
            >
              <Feather
                name="link"
                size={9}
                color={
                  customColor &&
                  customColor.background &&
                  customColor.background.app
                    ? customColor.background.app
                    : THEME.COLORS.BACKGROUND_MAIN
                }
              />
            </ViewIcon>
            <FooterText
              color={
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : THEME.COLORS.TEXT_MAIN
              }
              style={{
                fontFamily:
                  customFont && customFont.fontFamilyText
                    ? customFont.fontFamilyText
                    : THEME.FONTFAMILY.LIGHT,
                fontSize:
                  customFont && customFont.fontXS
                    ? customFont.fontXS
                    : THEME.FONTSIZE.EXTRASMALL,
              }}
            >
              Link
            </FooterText>
          </>
        ) : (
          <></>
        )}
      </ContentIcon>
    );
  };

  const XIcon = () => {
    if (isRemoved) {
      return null;
    }

    return (
      !isRemoved && (
        <TouchableOpacity
          onPress={() => {
            setIsRemoved(true);
            handleRemoveLike(id, cardId, categoryId);
          }}
        >
          <Feather
            name="x"
            size={15}
            color={
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : THEME.COLORS.TEXT_MAIN
            }
          />
        </TouchableOpacity>
      )
    );
  };

  if (isRemoved) {
    return null;
  }

  return (
    <Container>
      <Avatar
        resizeMode="cover"
        source={{ uri: backgroundImage?.url || image }}
      />
      <View style={{ flex: 1, paddingHorizontal: '0.5rem' }}>
        <Content>
          <View
            style={{
              width: favorites ? '90%' : '100%',
              flexDirection: 'row',
            }}
          >
            <SmallText
              color={
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : THEME.COLORS.TEXT_MAIN
              }
              textAlign="left"
              fontSize={
                customFont && customFont.fontSM
                  ? customFont.fontSM
                  : THEME.FONTSIZE.SMALL
              }
              style={{
                fontFamily:
                  customFont && customFont.fontFamilyText
                    ? customFont.fontFamilyText
                    : THEME.FONTFAMILY.REGULAR,
                width: '90%',
                justifyContent: 'center',
              }}
              numberOfLines={2}
            >
              {title}
            </SmallText>
          </View>
          {favorites && (
            <View style={{ width: '10%', alignItems: 'center' }}>
              <XIcon></XIcon>
            </View>
          )}
        </Content>
        <ContentIcon>
          <Icon />
        </ContentIcon>
      </View>
      {!hasAccess ? (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '8%',
          }}
        >
          <View
            style={{
              backgroundColor:
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : THEME.COLORS.TEXT_MAIN,
              borderRadius: 6,
              padding: 3,
            }}
          >
            <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
              <Path
                d="M4 5.014v-.93c0-1.078.417-2.114 1.165-2.881A3.96 3.96 0 018 0a3.96 3.96 0 012.835 1.203A4.127 4.127 0 0112 4.083v.93a2.25 2.25 0 012 2.237v5.5A2.25 2.25 0 0111.75 15h-7.5A2.25 2.25 0 012 12.75v-5.5a2.25 2.25 0 012-2.236zM6.239 2.25A2.46 2.46 0 018 1.5c.657 0 1.29.267 1.761.75.471.483.739 1.142.739 1.833V5h-5v-.917c0-.69.268-1.35.739-1.833zM8 9.25a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1A.75.75 0 008 9.25z"
                fillRule="evenodd"
                fill={
                  customColor && customColor.background?.app
                    ? customColor.background.app
                    : THEME.COLORS.BACKGROUND_MAIN
                }
              />
            </Svg>
          </View>
        </View>
      ) : (
        hasSchedule(video, infoPlanUser) && (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '8%',
            }}
          >
            <View
              style={{
                backgroundColor:
                  customColor && customColor.text && customColor.text.app
                    ? customColor.text.app
                    : THEME.COLORS.TEXT_MAIN,
                borderRadius: 6,
                padding: 3,
              }}
            >
              <Svg width={16} height={16} viewBox="0 0 24 24" fill="none">
                <Path
                  d="M15.09814,12.63379,13,11.42285V7a1,1,0,0,0-2,0v5a.99985.99985,0,0,0,.5.86621l2.59814,1.5a1.00016,1.00016,0,1,0,1-1.73242ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"
                  fillRule="evenodd"
                  fill={
                    customColor && customColor.background?.app
                      ? customColor.background.app
                      : THEME.COLORS.BACKGROUND_MAIN
                  }
                />
              </Svg>
            </View>
          </View>
        )
      )}
    </Container>
  );
};

export default PlayList;
