export const hasSchedule = (video, infoPlanUser) => {
  const today = new Date();
  const created = infoPlanUser?.created;

  const matchingPlan = video.selectedPlans?.some(
    (plan) => plan?.productId === infoPlanUser?.plan
  );

  if (!infoPlanUser || !matchingPlan || matchingPlan) {
    if (video.isSchedulingNewStudents) {
      const timestampLength = String(created).length;
      let timestampInMilliseconds;

      if (timestampLength === 10) {
        timestampInMilliseconds = created * 1000;
      }
      if (timestampLength === 13) {
        timestampInMilliseconds = created;
      }
      if (typeof created === 'string' && created.includes('T')) {
        timestampInMilliseconds = new Date(created).getTime();
      }

      const createdDate = new Date(timestampInMilliseconds);
      const scheduledDate = new Date(createdDate);
      scheduledDate.setDate(scheduledDate.getDate() + video.schedulingDays);

      const zeroTime = (date) => {
        date.setHours(0, 0, 0, 0);
        return date;
      };

      const todayZeroed = zeroTime(new Date(today));
      const scheduledDateZeroed = zeroTime(new Date(scheduledDate));

      if (scheduledDateZeroed >= todayZeroed) {
        return true;
      } else {
        return false;
      }
    }

    if (video.isScheduling) {
      const selectedDateTimestamp =
        video.selectedDate.seconds * 1000 +
        Math.floor(video.selectedDate.nanoseconds / 1000000);
      const selectedDate = new Date(selectedDateTimestamp);

      const zeroTime = (date) => {
        date.setHours(0, 0, 0, 0);
        return date;
      };

      const todayZeroed = zeroTime(new Date(today));
      const selectedDateZeroed = zeroTime(new Date(selectedDate));

      if (selectedDateZeroed >= todayZeroed) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
};
