import { useCallback, useState } from "react";
import ViewportProvider from "../../hooks/ViewPortProvider";
import useViewport from "../../hooks/useViewport";
import { View } from "react-native";
import FormCustomLogin from "./FormCustomLogin";
import { Description, FormContainer, Heading, Container } from "../Brand/style";
import {
  ActivityIndicator,
  IconButton,
  Provider as ProviderPaper,
} from "react-native-paper";
import { Login } from "../Login";
import { usebrand } from "../Brand/useBrand";
import Header from "../../components/Header";
import { useCustomLogin } from "./useCustomLogin";
import THEME from "../../config/theme";
import Button from "../../components/Button";
import { useCustomTheme } from "../../contexts/useCustomTheme";

export const CustomLogin = () => {
  const { MobileOrDesktopForm } = usebrand();
  const { isLoadingColorInfo, colorInfo: customColor } = useCustomLogin();
  const [isLoginFullWidth, setIsLoginFullWidth] = useState(false);
  const [isSave, setIsSave] = useState(false);
  function handleSave(newValueState) {
    setIsSave(newValueState);

    setTimeout(() => {
      setIsSave(false);
    }, 2000);
  }
  const { customFont } = useCustomTheme();

  const MobileOrDesktopLogin = useCallback(({ children, isFull }) => {
    const { width } = useViewport();
    const breakpoint = 1080;
    return width < breakpoint ? (
      <View
        style={{
          width: isFull ? "100%" : "auto",
          height: isFull ? "90vh" : "600px",
          marginLeft: "0.5rem",
        }}
      >
        {children}
      </View>
    ) : (
      <View
        style={{
          width: isFull ? "100%" : "1020px",
          height: isFull ? "90vh" : "600px",
          marginLeft: "0.5rem",
        }}
      >
        {children}
      </View>
    );
  }, []);

  return (
    <ViewportProvider>
      <ProviderPaper>
        <Container>
          <Header navigation={navigation} isAdminPage />
          {isLoadingColorInfo ? (
            <View style={{ height: "80vh", alignItems: "center" }}>
              <ActivityIndicator
                style={{
                  flex: 1,
                  justifyContent: "center",
                }}
                color={
                  customColor && customColor.primary
                    ? customColor.primary
                    : THEME.COLORS.PRIMARY_800
                }
              />
            </View>
          ) : (
            <MobileOrDesktopForm>
              <FormContainer
                style={{
                  display: isLoginFullWidth ? "none" : "flex",
                  width: "-webkit-fill-available",
                  maxWidth: "35rem",
                  paddingBottom: "2rem",
                }}
              >
                <Heading
                  style={{
                    fontSize:
                      customFont && customFont.fontLG
                        ? customFont.fontLG
                        : THEME.FONTSIZE.BIG,
                    fontFamily:
                      customFont && customFont.fontFamilyTitle
                        ? customFont.fontFamilyTitle
                        : THEME.FONTFAMILY.BOLD,
                  }}
                >
                  Customizar tela de login:
                </Heading>
                <Description
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilyText
                        ? customFont.fontFamilyText
                        : THEME.FONTFAMILY.REGULAR,
                    fontSize:
                      customFont && customFont.fontSM
                        ? customFont.fontSM
                        : THEME.FONTSIZE.SMALL,
                  }}
                >
                  Aqui você pode personalizar as cores do fundo, botões e imagens da tela de login do seu aplicativo web e mobile. A imagem lateral aparecerá somente na versão web acessada pelo desktop.
                </Description>
                <FormCustomLogin />
                <Button
                  title={isSave ? "Salvo!" : "Salvar"}
                  onPress={() => handleSave(true)}
                  style={{
                    cursor: "pointer",
                    alignSelf: "center",
                  }}
                  colorbutton={
                    isSave
                      ? "green"
                      : customColor &&
                        customColor.button &&
                        customColor.button.backgroundApp
                      ? customColor.button.backgroundApp
                      : THEME.COLORS.PRIMARY_900
                  }
                />
              </FormContainer>

              <MobileOrDesktopLogin isFull={isLoginFullWidth}>
                <IconButton
                  icon={isLoginFullWidth ? "fullscreen-exit" : "fullscreen"}
                  size={28}
                  iconColor="black"
                  onPress={() => setIsLoginFullWidth(!isLoginFullWidth)}
                  style={{ alignSelf: "end", margin: "0px" }}
                />

                <Login />
              </MobileOrDesktopLogin>
            </MobileOrDesktopForm>
          )}
        </Container>
      </ProviderPaper>
    </ViewportProvider>
  );
};
