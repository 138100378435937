import { Feather } from '@expo/vector-icons';
import { Container, ItemForm, ViewButton } from './style';
import PickerColorHex from '../../../components/PickerColorHex';
import { UploadImages } from '../../../components/UploadImages';
import { useEditMain } from './useEditMain';
import { ActivityIndicator, TextInput } from 'react-native-paper';
import { View } from 'react-native';
import THEME from '../../../config/theme';
import { useCustomTheme } from '../../../contexts/useCustomTheme';
import { TextAndTooltip } from '../../Brand/style';
import { TooltipCustom } from '../../../components/Tooltip';
import { StandardText, SmallText } from '../../../config/theme/globalStyles';
import { Switch } from 'react-native';
import Button from '../../../components/Button';

export function EditMain({ closeMenu }) {
  const {
    backgroundColor,
    setBackgroundColor,
    textTitleMain,
    setTextTitleMain,
    textApp,
    setTextApp,
    backgroundButtonColor,
    setBackgroundButtonColor,
    textButtonColor,
    setTextButtonColor,
    iconLockColor,
    setIconLockColor,
    iconColor,
    setIconColor,
    tabBackgroundColor,
    setTabBackgroundColor,
    tabIcondColor,
    setTabIcondColor,
    tabHighlightColor,
    setTabHighlightColor,
    splashColor,
    setSplashColor,
    statusBarColor,
    setStatusBarColor,
    show,
    handleShowPicker,
    title,
    setTitle,
    subTitle,
    setSubTitle,
    isLoadingThemeInfo,
    isLoadingCustomizationInfo,
    switchCustomizeApp,
    setSwitchCustomizeApp,
    updateAppColors,
    loadingColorsApp,
    isSavedColorsApp,
    setIsSavedColorsApp
  } = useEditMain();

  const { customColor } = useCustomTheme();

  function handleSave(newValueState) {
    setIsSavedColorsApp(newValueState);

    setTimeout(() => {
      setIsSavedColorsApp(false);
    }, 2000);
  }

    return (
      <Container>
        <Feather
          name="x"
          size={24}
          onPress={closeMenu}
          color="black"
          style={{ alignSelf: 'end' }}
        />
        {isLoadingThemeInfo || isLoadingCustomizationInfo ? (
          <ActivityIndicator
            style={{
              flex: 1,
              backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
              justifyContent: 'center',
            }}
            color={
              customColor && customColor.primary
                ? customColor.primary
                : THEME.COLORS.PRIMARY_800
            }
          />
        ) : (
          <View>
            <ItemForm style={{ height: '70px' }}>
              <TextAndTooltip>
                <StandardText textAlign="left" color="black">
                  Logo (png)
                </StandardText>
                <TooltipCustom text="Logo que aparecerá no canto superior direto desta tela. Tamanho(px): recomendado usar 64x64 e limitado a 2MB. Formato: apenas imagens no formato .png são aceitas." />
              </TextAndTooltip>

              <UploadImages fileType="logoApp" />
            </ItemForm>
            <ItemForm style={{ height: '70px' }}>
              <TextAndTooltip>
                <StandardText textAlign="left" color="black">
                  Imagem de fundo (png)
                </StandardText>
                <TooltipCustom text="Tamanho(px): recomendado usar 1920x1080 e limitado a 2MB. Formato: apenas imagens no formato .png são aceitas." />
              </TextAndTooltip>

              <UploadImages fileType={'imageApp'} />
            </ItemForm>
            <View style={{ marginTop: '8px' }}>
              <SmallText textAlign="left" color="black">
                Título em destaque (opcional):
              </SmallText>
              <TextInput
                placeholder="Escreva o texto em destaque aqui"
                style={{
                  backgroundColor: '#fff',
                  height: 32,
                  zIndex: 0,
                  marginLeft: '0.2rem',
                  borderBottomWidth: '1px',
                  borderBottomColor: '#B1B1B1',
                  textAlignVertical: 'top',
                  borderColor: 'none',
                }}
                contentStyle={{
                  paddingHorizontal: '0px',
                  border: 'none',
                  minHeight: '1.5rem',
                }}
                theme={{
                  colors: {
                    outline: '#fff',
                    primary: '#B1B1B1',
                  },
                }}
                textColor="#000"
                value={title}
                onChangeText={(text) => setTitle(text)}
              />
            </View>
            <View style={{ marginTop: '8px' }}>
              <SmallText textAlign="left" color="black">
                Subtítulo em destaque (opcional):
              </SmallText>
              <TextInput
                placeholder="Escreva o subtítulo em destaque aqui"
                style={{
                  backgroundColor: '#fff',
                  height: 32,
                  zIndex: 0,
                  marginLeft: '0.2rem',
                  borderBottomWidth: '1px',
                  borderBottomColor: '#B1B1B1',
                  textAlignVertical: 'top',
                  borderColor: 'none',
                }}
                contentStyle={{
                  paddingHorizontal: '0px',
                  border: 'none',
                  minHeight: '1.5rem',
                }}
                theme={{
                  colors: {
                    outline: '#fff',
                    primary: '#B1B1B1',
                  },
                }}
                textColor="#000"
                value={subTitle}
                onChangeText={(text) => setSubTitle(text)}
              />
            </View>

            <StandardText textAlign="left" margin="1rem 0rem" color="black">
              Cores:
            </StandardText>
            <ItemForm>
              <SmallText color="black">Cor de fundo</SmallText>
              <PickerColorHex
                colorName={backgroundColor}
                props={{
                  color: backgroundColor,
                  onChange: setBackgroundColor,
                }}
                setShow={() =>
                  handleShowPicker(
                    'showBackgroundColor',
                    !show.showBackgroundColor
                  )
                }
                isShowPicker={show.showBackgroundColor}
                setChange={setBackgroundColor}
              />
            </ItemForm>
            <ItemForm>
              <SmallText color="black">Cor do título e subtítulo em destaque</SmallText>
              <PickerColorHex
                colorName={textTitleMain}
                props={{
                  color: textTitleMain,
                  onChange: setTextTitleMain,
                }}
                setShow={() =>
                  handleShowPicker('showTextTitleMain', !show.showTextTitleMain)
                }
                isShowPicker={show.showTextTitleMain}
                setChange={setTextTitleMain}
              />
            </ItemForm>
            <ItemForm>
              <SmallText color="black">Cor dos textos em geral</SmallText>
              <PickerColorHex
                colorName={textApp}
                props={{
                  color: textApp,
                  onChange: setTextApp,
                }}
                setShow={() => handleShowPicker('showTextApp', !show.showTextApp)}
                isShowPicker={show.showTextApp}
                setChange={setTextApp}
              />
            </ItemForm>

            <ItemForm>
              <SmallText color="black">Cor de fundo do botão</SmallText>
              <PickerColorHex
                colorName={backgroundButtonColor}
                props={{
                  color: backgroundButtonColor,
                  onChange: setBackgroundButtonColor,
                }}
                setShow={() =>
                  handleShowPicker(
                    'showBackgroundButtonColor',
                    !show.showBackgroundButtonColor
                  )
                }
                isShowPicker={show.showBackgroundButtonColor}
                setChange={setBackgroundButtonColor}
              />
            </ItemForm>
            <ItemForm>
              <SmallText color="black">Cor do texto nos botões</SmallText>
              <PickerColorHex
                colorName={textButtonColor}
                props={{
                  color: textButtonColor,
                  onChange: setTextButtonColor,
                }}
                setShow={() =>
                  handleShowPicker(
                    'showTextButtonColor',
                    !show.showTextButtonColor
                  )
                }
                isShowPicker={show.showTextButtonColor}
                setChange={setTextButtonColor}
              />
            </ItemForm>

            <ItemForm>
              <SmallText color="black">Ícone do cadeado</SmallText>
              <PickerColorHex
                colorName={iconLockColor}
                props={{
                  color: iconLockColor,
                  onChange: setIconLockColor,
                }}
                setShow={() =>
                  handleShowPicker('showIconLock', !show.showIconLock)
                }
                isShowPicker={show.showIconLock}
                setChange={setIconLockColor}
              />
            </ItemForm>
            <ItemForm>
              <SmallText color="black">Ícones do header</SmallText>
              <PickerColorHex
                colorName={iconColor}
                props={{
                  color: iconColor,
                  onChange: setIconColor,
                }}
                setShow={() =>
                  handleShowPicker('showIconColor', !show.showIconColor)
                }
                isShowPicker={show.showIconColor}
                setChange={setIconColor}
              />
            </ItemForm>

            <ItemForm>
              <StandardText textAlign="left" margin="1rem 0rem" color="black">
                Personalizar aplicativo Android/iOS:
              </StandardText>

              <Switch
                value={switchCustomizeApp}
                onValueChange={() => setSwitchCustomizeApp(!switchCustomizeApp)}
              />
            </ItemForm>

            {switchCustomizeApp && (
              <>
                <SmallText padding="12px 6px">
                  Atenção: Para que suas mudanças de personalização sejam
                  aplicadas no aplicativo, é necessário clicar em 'Salvar' após
                  concluir as alterações.
                </SmallText>

                <StandardText textAlign="left" margin="1rem 0rem" color="black">
                  Cores exclusivas do app Android/iOS:
                </StandardText>

                <ItemForm>
                  <TextAndTooltip>
                    <SmallText color="black">
                      Cor de fundo da navegação inferior
                    </SmallText>
                    <TooltipCustom text="Barra inferior do aplicativo onde ficam os ícones de navegação entre as telas" />
                  </TextAndTooltip>
                  <PickerColorHex
                    colorName={tabBackgroundColor}
                    props={{
                      color: tabBackgroundColor,
                      onChange: setTabBackgroundColor,
                    }}
                    setShow={() =>
                      handleShowPicker(
                        'showTabBackgroundColor',
                        !show.showTabBackgroundColor
                      )
                    }
                    isShowPicker={show.showTabBackgroundColor}
                    setChange={setTabBackgroundColor}
                  />
                </ItemForm>
                <ItemForm>
                  <TextAndTooltip>
                    <SmallText color="black">Cor dos ícones</SmallText>
                    <TooltipCustom text="Cor dos ícones quando não estão selecionados" />
                  </TextAndTooltip>
                  <PickerColorHex
                    colorName={tabIcondColor}
                    props={{
                      color: tabIcondColor,
                      onChange: setTabIcondColor,
                    }}
                    setShow={() =>
                      handleShowPicker(
                        'showTabIcondColor',
                        !show.showTabIcondColor
                      )
                    }
                    isShowPicker={show.showTabIcondColor}
                    setChange={setTabIcondColor}
                  />
                </ItemForm>
                <ItemForm>
                  <TextAndTooltip>
                    <SmallText color="black">Cor do ícone selecionado</SmallText>
                    <TooltipCustom text="Cor do ícone quando está selecionado" />
                  </TextAndTooltip>
                  <PickerColorHex
                    colorName={tabHighlightColor}
                    props={{
                      color: tabHighlightColor,
                      onChange: setTabHighlightColor,
                    }}
                    setShow={() =>
                      handleShowPicker(
                        'showTabHighlightColor',
                        !show.showTabHighlightColor
                      )
                    }
                    isShowPicker={show.showTabHighlightColor}
                    setChange={setTabHighlightColor}
                  />
                </ItemForm>
                <ItemForm>
                  <TextAndTooltip>
                    <SmallText color="black">Spash Screen</SmallText>
                    <TooltipCustom text="Cor de fundo da tela de abertura do aplicativo. Deve ser a mesma cor de fundo usada no ícone do aplicativo." />
                  </TextAndTooltip>
                  <PickerColorHex
                    colorName={splashColor}
                    props={{
                      color: splashColor,
                      onChange: setSplashColor,
                    }}
                    setShow={() =>
                      handleShowPicker('showSplashColor', !show.showSplashColor)
                    }
                    isShowPicker={show.showSplashColor}
                    setChange={setSplashColor}
                  />
                </ItemForm>
                <ItemForm>
                  <TextAndTooltip>
                    <SmallText color="black">Barra de status superior</SmallText>
                    <TooltipCustom text="Cor de fundo da barra de status na parte superior da tela inicial do celular que contém os ícones (wifi, horário, etc).Deve ser uma cor clara, pois os ícones são pretos." />
                  </TextAndTooltip>
                  <PickerColorHex
                    colorName={statusBarColor}
                    props={{
                      color: statusBarColor,
                      onChange: setStatusBarColor,
                    }}
                    setShow={() =>
                      handleShowPicker(
                        'showStatusBarColor',
                        !show.showStatusBarColor
                      )
                    }
                    isShowPicker={show.showStatusBarColor}
                    setChange={setStatusBarColor}
                  />
                </ItemForm>

                <ViewButton>

                  <Button
                    title={loadingColorsApp ? "Enviando..." : isSavedColorsApp ? "Salvo!" : "Salvar"}
                    onPress={() => {
                      updateAppColors();
                      handleSave(true);
                    }}
                    style={{
                      cursor: "pointer",
                      alignSelf: "center",
                      backgroundColor: isSavedColorsApp
                        ? "#3FC380a0"
                        : customColor &&
                          customColor.button &&
                          customColor.button.backgroundApp
                          ? customColor.button.backgroundApp
                          : THEME.COLORS.PRIMARY_900,
                    }}
                  />
                </ViewButton>
              </>
            )}
          </View>
        )}
      </Container>
    );
  }
