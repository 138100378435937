import {
  Description,
  FormContainer,
  Heading,
  Main,
  ItemForm,
  TextAndTooltip,
  TitleItem,
  Container
} from "./style";
import Header from "../../components/Header";
import { View } from "react-native";
import {
  Provider as ProviderPaper,
  ActivityIndicator,
} from "react-native-paper";
import { usebrand } from "./useBrand";
import "react-color-palette/css";
import PickerColorHex from "../../components/PickerColorHex";
import ViewportProvider from "../../hooks/ViewPortProvider";
import THEME from "../../config/theme";
import { UploadImages } from "../../components/UploadImages";
import { CustomFont } from "./CustomFont";
import { TooltipCustom } from "../../components/Tooltip";
import { InputCustomNumeric } from "./InputCustomNumeric";
import Button from "../../components/Button";
import { useState } from "react";
import { useCustomTheme } from "../../contexts/useCustomTheme";

export function Brand({ navigation }) {
  const {
    primaryColor,
    setPrimaryColor,
    secondaryColor,
    setSecondaryColor,
    show,
    handleShowPicker,
    isLoadingCustomInfo,
    valueBorderRadius,
    setValueBorderRadius,
    isLoadingColorInfo,
  } = usebrand();
  const [isSave, setIsSave] = useState(false);
  function handleSave(newValueState) {
    setIsSave(newValueState);

    setTimeout(() => {
      setIsSave(false);
    }, 2000);
  }
  const { customColor, customFont } = useCustomTheme();
  return (
    <ViewportProvider>
      <ProviderPaper>
        <Container>
          <Header navigation={navigation} isAdminPage />
          {isLoadingCustomInfo || isLoadingColorInfo ? (
            <View style={{ height: "80vh", alignItems: "center" }}>
              <ActivityIndicator
                style={{
                  flex: 1,
                  justifyContent: "center",
                }}
                color={
                  customColor && customColor.primary
                    ? customColor.primary
                    : THEME.COLORS.PRIMARY_800
                }
              />
            </View>
          ) : (
            <Main>
              <View
                style={{
                  width: "-webkit-fill-available",
                  maxWidth: "35rem",
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <FormContainer>
                  <Heading
                    style={{
                      fontFamily:
                        customFont && customFont.fontFamilyTitle
                          ? customFont.fontFamilyTitle
                          : THEME.FONTFAMILY.BOLD,
                      fontSize:
                        customFont && customFont.fontLG
                          ? customFont.fontLG
                          : THEME.FONTSIZE.BIG,
                    }}
                  >
                    Configurações da minha marca:
                  </Heading>
                  <Description
                    style={{
                      fontFamily:
                        customFont && customFont.fontFamilySubtitle
                          ? customFont.fontFamilySubtitle
                          : THEME.FONTFAMILY.MEDIUM,
                      fontSize:
                        customFont && customFont.fontSM
                          ? customFont.fontSM
                          : THEME.FONTSIZE.SMALL,
                    }}
                  >
                    Personalize a identidade visual da sua marca com as opções
                    abaixo. Faça upload de fontes personalizadas e ícones para
                    refletir a personalidade da sua marca. Além disso, escolha
                    entre uma variedade de cores principais e secundárias.
                  </Description>

                  <ItemForm>
                    <TextAndTooltip>
                      <TitleItem
                        style={{
                          fontFamily:
                            customFont && customFont.fontFamilyText
                              ? customFont.fontFamilyText
                              : THEME.FONTFAMILY.REGULAR,
                          fontSize:
                            customFont && customFont.fontMD
                              ? customFont.fontMD
                              : THEME.FONTFAMILY.SMALL,
                        }}
                      >
                        Favicon (png)
                      </TitleItem>
                      <TooltipCustom
                        text="Favicon é a pequena imagem que aparece na aba do navegador ao lado do título da página. Tamanho(px): recomendado usar 48x48 e limitado a 2MB. Formato: apenas imagens no formato .png são aceitas."
                      />
                    </TextAndTooltip>
                    <UploadImages fileType="favIcon" />
                  </ItemForm>

                  <ItemForm>
                    <TextAndTooltip>
                      <TitleItem
                        style={{
                          fontFamily:
                            customFont && customFont.fontFamilyText
                              ? customFont.fontFamilyText
                              : THEME.FONTFAMILY.REGULAR,
                          fontSize:
                            customFont && customFont.fontMD
                              ? customFont.fontMD
                              : THEME.FONTFAMILY.SMALL,
                        }}
                      >
                        Cor principal
                      </TitleItem>
                      <TooltipCustom
                        text="A cor principal é a cor predominante que representa sua marca e é utilizada de forma consistente em alguns elementos visuais, como ícones de destaque e botões."
                      />
                    </TextAndTooltip>
                    <PickerColorHex
                      colorName={primaryColor}
                      props={{ color: primaryColor, onChange: setPrimaryColor }}
                      setShow={() =>
                        handleShowPicker(
                          "showPrimaryColor",
                          !show.showPrimaryColor
                        )
                      }
                      isShowPicker={show.showPrimaryColor}
                      setChange={setPrimaryColor}
                    />
                  </ItemForm>

                  <ItemForm>
                    <TextAndTooltip>
                      <TitleItem
                        style={{
                          fontFamily:
                            customFont && customFont.fontFamilyText
                              ? customFont.fontFamilyText
                              : THEME.FONTFAMILY.REGULAR,
                          fontSize:
                            customFont && customFont.fontMD
                              ? customFont.fontMD
                              : THEME.FONTFAMILY.SMALL,
                        }}
                      >
                        Cor secundária
                      </TitleItem>
                      <TooltipCustom
                        text="A cor secundária complementa a cor principal e é usada para destacar elementos específicos, criar contraste e adicionar variedade ao design da sua marca."
                      />
                    </TextAndTooltip>
                    <PickerColorHex
                      colorName={secondaryColor}
                      props={{
                        color: secondaryColor,
                        onChange: setSecondaryColor,
                      }}
                      setShow={() =>
                        handleShowPicker(
                          "showSecondaryColor",
                          !show.showSecondaryColor
                        )
                      }
                      isShowPicker={show.showSecondaryColor}
                      setChange={setSecondaryColor}
                    />
                  </ItemForm>
                  <ItemForm
                    style={{
                      justifyContent: "space-between",
                      fontFamily:
                        customFont && customFont.fontFamilyText
                          ? customFont.fontFamilyText
                          : THEME.FONTFAMILY.REGULAR,
                    }}
                  >
                    <TextAndTooltip>
                      <TitleItem
                        style={{
                          fontFamily:
                            customFont && customFont.fontFamilyText
                              ? customFont.fontFamilyText
                              : THEME.FONTFAMILY.REGULAR,
                          fontSize:
                            customFont && customFont.fontMD
                              ? customFont.fontMD
                              : THEME.FONTFAMILY.SMALL,
                        }}
                      >
                        Arredondamento dos botões(px)
                      </TitleItem>
                      <TooltipCustom
                        text="O arredondamento dos botões define o quão arredondados serão os cantos dos botões, medido em pixels (px)."
                      />
                    </TextAndTooltip>

                    <InputCustomNumeric
                      value={valueBorderRadius}
                      onChangeText={(text) =>
                        setValueBorderRadius(
                          Number(text.replace(/[^0-9]/g, ""))
                        )
                      }
                    />
                  </ItemForm>
                  <CustomFont />
                  <Button
                    title={isSave ? "Salvo!" : "Salvar"}
                    onPress={() => handleSave(true)}
                    style={{
                      cursor: "pointer",
                      alignSelf: "center",
                      backgroundColor: isSave
                        ? "green"
                        : customColor &&
                          customColor.button &&
                          customColor.button.backgroundApp
                          ? customColor.button.backgroundApp
                          : THEME.COLORS.PRIMARY_900,
                    }}
                  />
                </FormContainer>
              </View>
            </Main>
          )}
        </Container>
      </ProviderPaper>
    </ViewportProvider>
  );
}
