import { nomeFornecedor } from '../data';

export const privacyData = [
  {
    subtitle: "Termos de Uso",
    paragraphs: [
      {
        text: `Ao acessar ou utilizar o aplicativo ou site ${nomeFornecedor}, você concorda em cumprir estes termos de uso, bem como todas as leis e regulamentos aplicáveis e, se você não concordar com qualquer parte destes termos, está proibido de acessar esse aplicativo ou site. Todo o conteúdo disponibilizado neste aplicativo ou site está protegido por leis de direitos autorais e outras leis de propriedade intelectual.`
      }
    ]
  },
  {
    subtitle: "Licença de Uso",
    paragraphs: [
      {
        text: `É concedida a você uma licença limitada para acessar e visualizar temporariamente o conteúdo disponível no aplicativo ou site ${nomeFornecedor} apenas para fins pessoais e não comerciais. Esta licença não constitui uma transferência de propriedade e está sujeita às seguintes restrições:`
      },
      {
        text: `- Você não pode modificar ou copiar o conteúdo;`
      },
      {
        text: `- Não é permitido utilizar o conteúdo para fins comerciais ou exibi-lo publicamente;`
      },
      {
        text: `- Você não pode tentar descompilar ou fazer engenharia reversa do aplicativo ou site;`
      },
      {
        text: `- Todos os avisos de direitos autorais e outras notações de propriedade devem ser mantidos intactos.`
      },
      {
        text: `Esta licença será automaticamente rescindida se você violar qualquer uma dessas restrições e pode ser rescindida pelo ${nomeFornecedor} a qualquer momento. Após o término da licença, você deve apagar todo o conteúdo baixado ou impresso do aplicativo.`
      }
    ]
  },
  {
    subtitle: "Isenção de Responsabilidade",
    paragraphs: [
      {
        text: `Todo o conteúdo disponibilizado no aplicativo ou site ${nomeFornecedor} é fornecido "no estado em que se encontra". O ${nomeFornecedor} não oferece garantias de qualquer tipo, expressas ou implícitas. Além disso, o ${nomeFornecedor} não garante a precisão, confiabilidade ou adequação do conteúdo para qualquer finalidade específica. O uso do conteúdo é por sua conta e risco.`
      }
    ]
  },
  {
    subtitle: "Limitação de Responsabilidade",
    paragraphs: [
      {
        text: `O ${nomeFornecedor} e seus fornecedores não serão responsáveis por quaisquer danos decorrentes do uso ou da incapacidade de usar o conteúdo do aplicativo, incluindo perda de dados, lucros ou interrupção dos negócios, mesmo que tenham sido avisados sobre a possibilidade de tais danos. Em jurisdições que não permitem a exclusão ou limitação de danos incidentais ou consequenciais, a responsabilidade do ${nomeFornecedor} será limitada até o limite máximo permitido por lei.`
      }
    ]
  },
  {
    subtitle: "Precisão do Conteúdo",
    paragraphs: [
      {
        text: `O ${nomeFornecedor} não garante a precisão, integridade ou atualidade do conteúdo disponibilizado no aplicativo. O conteúdo pode conter erros técnicos, tipográficos ou fotográficos. O ${nomeFornecedor} reserva-se o direito de fazer alterações ou atualizações no conteúdo sem aviso prévio.  No entanto, ${nomeFornecedor} não se compromete a atualizar os materiais.`
      }
    ]
  },
  {
    subtitle: "Links para Outros Sites",
    paragraphs: [
      {
        text: `O aplicativo ou site ${nomeFornecedor} pode conter links para sites de terceiros. O ${nomeFornecedor} não tem controle sobre o conteúdo desses sites e não será responsável por qualquer conteúdo ou danos decorrentes do uso desses sites. A inclusão de qualquer link no aplicativo não implica em endosso ou recomendação do ${nomeFornecedor}.`
      }
    ]
  },
  {
    subtitle: "Modificações nos Termos de Uso",
    paragraphs: [
      {
        text: `O ${nomeFornecedor} reserva-se o direito de revisar estes termos de uso a qualquer momento sem aviso prévio. Ao continuar a acessar ou usar o aplicativo após tais alterações, você estará concordando com os termos revisados.`
      }
    ]
  },
  {
    subtitle: "Lei Aplicável e Jurisdição",
    paragraphs: [
      {
        text: `Estes termos de uso são regidos e interpretados de acordo com as leis do ${nomeFornecedor}. Você concorda irrevogavelmente com a jurisdição exclusiva dos tribunais naquele estado ou localidade para resolver qualquer disputa relacionada a estes termos.`
      }
    ]
  }
];


